import {
  HeaderMain,
  LabelMini,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import OPLogo from "../../../assets/images/reports/OP_logo.png";
import WaspLogo from "../../../assets/images/reports/wasp_logo_title.png";
import CyberBg from "../../../assets/images/reports/report_back.png";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";
import { light } from "../../../shared/theme";

type Props = {
  children: React.ReactNode;
  isMultiTenantReport?: Boolean;
};
export const PageLayout = ({ children, isMultiTenantReport }: Props) => {
  return (
    <Flex
      column
      w100
      justify="center"
      align="center"
      style={{
        position: "relative",
        background: "white",
        boxSizing: "border-box",
        height: "auto",
      }}
    >
      <header>
        <Flex w100 justify="between" align="center">
          <img
            alt="op-logo"
            src={OPLogo}
            style={{
              position: "fixed",
              top: "10px",
              left: "10px",
              width: "35px",
            }}
          />
          <LabelMini color={light.black800}>
            *** Confidential | Restricted for authorized eyes only ! ***
          </LabelMini>
          <img
            alt="cyber-background"
            src={CyberBg}
            style={{
              position: "fixed",
              top: "-6px",
              right: "-12px",
              width: "300px",
              zIndex: -1,
            }}
          />
          <img
            alt="wasp-logo"
            src={WaspLogo}
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              width: "35px",
            }}
          />
        </Flex>
      </header>
      {IS_DEMO_ENV && (
        <Flex
          column
          w100
          h100
          align="center"
          justify="center"
          style={{
            opacity: 0.2,
            position: "fixed",
            zIndex: 5000,
          }}
        >
          <img src={WaspLogo} alt="" width="400px" />
          <HeaderMain style={{ fontSize: "62px" }}>DEMO</HeaderMain>
        </Flex>
      )}
      <div
        style={{
          width: "695px", // A4 width
          height: "auto",
          marginTop: "50px",
          zIndex: 1,
          padding: "16px",
          background: "transparent",
        }}
      >
        {children}
      </div>
      <footer>
        {!isMultiTenantReport && (
          <Flex column justify="center" align="center" w100>
            <LabelRegular color={light.black800}>
              OP Innovate Ltd. © {new Date().getFullYear()} | US +1-9143392562
              | UK +44-2038075379 | IL
            </LabelRegular>
            <Flex justify="center" gap="4px">
              <LabelRegular
                color={light.black800}
                style={{ textTransform: "none" }}
              >
                +972-546536698 <a href="www.op-c.net">www.op-c.net</a> {" | "}
                <a href="mailto:info@op-c.net">info@op-c.net</a>
              </LabelRegular>
            </Flex>
          </Flex>
        )}
      </footer>
    </Flex>
  );
};
