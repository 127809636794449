import { useEffect, useMemo, useState } from "react";

import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { useApiAssetsWithViewPaging } from "../../../hooks/queries/assetsContext";
import { components, SingleValue } from "react-select";
import { useDebounceCallback } from "../../../hooks/utilsHooks";
import { Filter } from "../../../types/AssetsView";
import { AddAssetModal } from "../../assets/addAssets/AddAssetModal";
import { Customer } from "../../../types/Customer";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Asset } from "../../../types/Asset";
import { BodyRegular } from "../../../components/elements/typography/Typography";

type Props = {
  customer?: Customer;
  selected: number[];
  onChange: (opt: SingleValue<Option>, asset: Asset | undefined) => void;
  size?: "medium" | "large" | "small";
  disabled?: boolean;
  isError?: boolean;
  productId?: number;
  menuPlacement?: "top" | "bottom";
  width?: string;
  creatable?: boolean;
  clearable?: boolean;
};

export const AssetsDropdown = (props: Props) => {
  const {
    customer,
    disabled,
    selected,
    onChange,
    size,
    isError,
    productId,
    menuPlacement,
    width,
    clearable,
    creatable = true,
  } = props;
  const [assetsFilters, setAssetsFilters] = useState<Filter[]>([]);
  const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false);
  const [newAssetName, setNewAssetName] = useState<string>("");
  const { data: me } = useApiMe();

  const {
    data: assetPages,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useApiAssetsWithViewPaging(
    assetsFilters,
    "-created_at",
    true,
    !!customer
  );

  const onCreateOption = (option: SingleValue<Option>) => {
    setNewAssetName?.(option?.label || "");
    setShowAddAssetModal?.(true);
  };

  useEffect(() => {
    const oldFilters = [...assetsFilters].filter(
      (f) => f.column !== "customer" && f.column !== "product"
    );
    let newFilters: Filter[] = [...oldFilters];

    // Filters the dropdown by the users's products_allowed and customer attributes
    if (!!customer) {
      if (!!me?.products_allowed && me.products_allowed.length > 0) {
        for (const prod of me?.products_allowed) {
          newFilters.push({
            column: "customer",
            condition: "is",
            next_condition: "and",
            order: 0,
            value: String(customer.id),
          });
          newFilters.push({
            column: "product",
            condition: "is",
            next_condition: "or",
            order: 0,
            value: String(prod),
          });
        }
      } else {
        newFilters.push({
          column: "customer",
          condition: "is",
          next_condition: "and",
          order: 0,
          value: String(customer.id),
        });
      }
    }
    if (productId)
      newFilters.push({
        column: "product",
        condition: "is",
        next_condition: "and",
        order: 0,
        value: String(productId),
      });
    setAssetsFilters(newFilters);
  }, [customer, productId]); // eslint-disable-line

  const assets = useMemo(
    () => assetPages?.pages.map((page) => page.results || []).flat(),
    [assetPages]
  );

  const options = [
    ...(assets
      ?.map((a) => ({ value: a.id, label: a.name }))
      ?.filter((o) => !selected.includes(o.value)) || []),
  ];

  const handleSearch = useDebounceCallback((newValue: string) => {
    const oldFilters = [...assetsFilters].filter((f) => f.column !== "name");
    const newFilters = !!newValue
      ? [
          ...oldFilters,
          {
            column: "name",
            value: newValue,
            condition: "contains",
            order: 0,
            next_condition: "and",
          },
        ]
      : oldFilters;
    setAssetsFilters([...newFilters]);
    refetch();
  }, 500);

  const AssetOption = (props: any) => {
    const { Option } = components;

    return (
      <Option
        {...props}
        className="text-truncate"
        style={{
          width: width ? `calc(${width} - 30px)` : "",
        }}
      >
        <BodyRegular title={props.data.label}>{props.data.label}</BodyRegular>
      </Option>
    );
  };

  return (
    <>
      {!showAddAssetModal && (
        <Dropdown
          value={
            selected.length === 1
              ? {
                  label:
                    assets?.find((a) => a.id === selected?.at(0))?.name ||
                    "Select assets",
                  value: selected?.at(0) || 0,
                }
              : { label: "Select assets", value: 0 }
          }
          disabled={disabled}
          isError={isError}
          size={size}
          width={width}
          variant="border"
          options={options || []}
          clearable={clearable}
          onChange={(opt) =>
            onChange(
              opt,
              assets?.find((a) => a.id === opt?.value)
            )
          }
          onCreateOption={onCreateOption}
          onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
          closeMenuOnSelect={false}
          dataTestId="assets-dropdown"
          isMenuPositionFixed
          menuPlacement={menuPlacement || "top"}
          searchable
          creatable={creatable}
          onInputChange={handleSearch}
          customComponents={{
            Option: AssetOption,
          }}
          queryStatus={isLoading ? "loading" : undefined}
        />
      )}
      {showAddAssetModal && (
        <AddAssetModal
          newAssetName={newAssetName}
          customer={customer}
          onClose={() => {
            refetch();
            setShowAddAssetModal(false);
          }}
        />
      )}
    </>
  );
};
