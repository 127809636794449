import { IconProps } from "./WaspIcon";

export function AssetsMenuIcon(props: IconProps) {
  const size = props.size || 28;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.74838 9.00398C3.7218 9.00398 4.51092 8.21113 4.51092 7.2331C4.51092 6.25507 3.7218 5.46222 2.74838 5.46222C1.77495 5.46222 0.98584 6.25507 0.98584 7.2331C0.98584 8.21113 1.77495 9.00398 2.74838 9.00398Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.74838 18.5295C3.7218 18.5295 4.51092 17.7366 4.51092 16.7586C4.51092 15.7806 3.7218 14.9877 2.74838 14.9877C1.77495 14.9877 0.98584 15.7806 0.98584 16.7586C0.98584 17.7366 1.77495 18.5295 2.74838 18.5295Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.054 4.24507C13.0275 4.24507 13.8166 3.45222 13.8166 2.47419C13.8166 1.49616 13.0275 0.703308 12.054 0.703308C11.0806 0.703308 10.2915 1.49616 10.2915 2.47419C10.2915 3.45222 11.0806 4.24507 12.054 4.24507Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2518 8.32435C22.2252 8.32435 23.0143 7.5315 23.0143 6.55347C23.0143 5.57544 22.2252 4.78259 21.2518 4.78259C20.2784 4.78259 19.4893 5.57544 19.4893 6.55347C19.4893 7.5315 20.2784 8.32435 21.2518 8.32435Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.2518 17.8248C22.2252 17.8248 23.0143 17.032 23.0143 16.054C23.0143 15.0759 22.2252 14.2831 21.2518 14.2831C20.2784 14.2831 19.4893 15.0759 19.4893 16.054C19.4893 17.032 20.2784 17.8248 21.2518 17.8248Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0545 23.2966C13.028 23.2966 13.8171 22.5038 13.8171 21.5258C13.8171 20.5477 13.028 19.7549 12.0545 19.7549C11.0811 19.7549 10.292 20.5477 10.292 21.5258C10.292 22.5038 11.0811 23.2966 12.0545 23.2966Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0541 13.2657C13.0321 13.2657 13.825 12.4766 13.825 11.5032C13.825 10.5298 13.0321 9.74066 12.0541 9.74066C11.0761 9.74066 10.2832 10.5298 10.2832 11.5032C10.2832 12.4766 11.0761 13.2657 12.0541 13.2657Z"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.6465 5.82306L13.6401 3.24719"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.252 14.2837V8.3255"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5698 20.6223L19.7187 16.9288"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.32373 17.553L10.4636 20.7638"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.74854 9.00482V14.9886"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4249 3.15021L4.22949 6.27375"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0405 13.2668V19.7558"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.7859 7.53741L13.6416 10.723"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4426 10.7742L4.37354 7.9198"
        stroke={props.color || "currentColor"}
        stroke-width="1.40609"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
