import { IconProps } from "./WaspIcon";

export function InsightsMenuIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13015_32438)">
        <path
          d="M13.0642 4.74805C13.0554 4.74805 13.0458 4.74805 13.038 4.74805C10.9257 4.74805 8.93731 5.57167 7.43839 7.06798C5.93335 8.5704 5.10449 10.5684 5.10449 12.6929C5.10449 14.7362 5.881 16.674 7.29093 18.1494C7.99765 18.8893 8.38677 19.8036 8.38677 20.7241V21.3942C8.38677 22.3085 9.131 23.0519 10.0445 23.0519H16.055C16.9694 23.0519 17.7127 22.3085 17.7127 21.3942V20.7241C17.7127 19.8027 18.1019 18.8884 18.8086 18.1485C20.2185 16.6731 20.995 14.7354 20.995 12.6929C20.995 10.5684 20.1662 8.5704 18.6611 7.06798C17.1622 5.57167 15.1747 4.74805 13.0642 4.74805ZM17.6735 17.0631C16.6858 18.097 16.1414 19.3962 16.1414 20.7232V21.3933C16.1414 21.4413 16.1021 21.4805 16.0542 21.4805H10.0436C9.99563 21.4805 9.95637 21.4413 9.95637 21.3933V20.7232C9.95637 19.3962 9.41194 18.0962 8.42429 17.0631C7.2953 15.8818 6.67409 14.3288 6.67409 12.692C6.67409 10.9872 7.33892 9.38442 8.54644 8.17865C9.74872 6.97899 11.3427 6.31764 13.0389 6.31764C13.0476 6.31852 13.0554 6.31764 13.0598 6.31764C14.7533 6.31764 16.3482 6.97811 17.5496 8.17865C18.7571 9.38442 19.4219 10.9872 19.4219 12.692C19.4237 14.3297 18.8025 15.8818 17.6735 17.0631Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M11.9676 8.36801C10.3605 8.7676 9.11723 10.0179 8.72287 11.6293C8.61991 12.0499 8.87729 12.4757 9.2987 12.5786C9.36065 12.5943 9.42434 12.6013 9.48629 12.6013C9.83877 12.6013 10.1598 12.3614 10.248 12.0019C10.4992 10.975 11.3229 10.1461 12.3472 9.89223C12.7668 9.78754 13.0233 9.36177 12.9186 8.94036C12.8148 8.51982 12.3882 8.26593 11.9676 8.36801Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M15.1912 24.4296H10.9073C10.4737 24.4296 10.1221 24.7812 10.1221 25.2148C10.1221 25.6484 10.4737 26 10.9073 26H15.1912C15.6248 26 15.9764 25.6484 15.9764 25.2148C15.9764 24.7812 15.6248 24.4296 15.1912 24.4296Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M13.0494 3.10779C13.483 3.10779 13.8346 2.75617 13.8346 2.32255V0.785235C13.8346 0.351611 13.483 0 13.0494 0C12.6158 0 12.2642 0.351611 12.2642 0.785235V2.32255C12.2642 2.75617 12.6158 3.10779 13.0494 3.10779Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M24.2303 10.3032H22.6929C22.2593 10.3032 21.9077 10.6548 21.9077 11.0884C21.9077 11.522 22.2593 11.8736 22.6929 11.8736H24.2303C24.6639 11.8736 25.0155 11.522 25.0155 11.0884C25.0155 10.6548 24.6648 10.3032 24.2303 10.3032Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M3.40517 10.3032H1.86873C1.43511 10.3032 1.0835 10.6548 1.0835 11.0884C1.0835 11.522 1.43511 11.8736 1.86873 11.8736H3.40605C3.83967 11.8736 4.19128 11.522 4.19128 11.0884C4.19128 10.6548 3.8388 10.3032 3.40517 10.3032Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M6.29548 5.44515C6.44903 5.59871 6.64971 5.67549 6.85125 5.67549C7.05279 5.67549 7.25346 5.59871 7.40702 5.44515C7.71413 5.13891 7.71413 4.64159 7.40702 4.33535L6.31903 3.24736C6.01192 2.94025 5.5146 2.94025 5.20836 3.24736C4.90125 3.55361 4.90125 4.05092 5.20836 4.35716L6.29548 5.44515Z"
          fill={props.color || "currentColor"}
        />
        <path
          d="M19.7794 3.24738L18.6922 4.3345C18.3851 4.64074 18.3851 5.13805 18.6922 5.44429C18.8458 5.59785 19.0465 5.67463 19.248 5.67463C19.4496 5.67463 19.6502 5.59785 19.8038 5.44429L20.8909 4.35718C21.198 4.05094 21.198 3.55362 20.8909 3.24738C20.5838 2.94114 20.0856 2.94114 19.7794 3.24738Z"
          fill={props.color || "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_13015_32438">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
