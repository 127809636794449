import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import {
  BodyRegular,
  LabelMedium,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { Icon } from "../../../../components/elements/icon/Icon";
import { cvssTooltip } from "../../../../shared/tooltipTexts";
import { light } from "../../../../shared/theme";
import { Dispatch, SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { CopyToClipboard } from "../../../../components/composed/copyToClipboard/CopyToClipboard";
import { Badge } from "../../../../components/elements/badge/Badge";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import { useParams } from "react-router";
import { useUpdateFindingInPlace } from "../../../../shared/formUtils";
import { TextButton } from "../../../../components/elements/button/text/TextButton";

type Props = {
  finding: Finding | undefined | null;
  isEditable?: boolean;
  noSeparator?: boolean;
  width?: string;
  noTooltip?: boolean;
  withVector?: boolean;
  isReport?: boolean;
  editableUpdateFindingData?: AdminFindingEdit | null;
  setUpdateFinding?: Dispatch<SetStateAction<Finding | undefined>>;
  setEditableUpdateFinding?: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  cachedFindingData?: AdminFindingEdit;
  setCachedFindingData?: (data: AdminFindingEdit) => void;
};

export const CVSSVectorItem = (props: Props) => {
  const {
    finding,
    isEditable,
    noSeparator = false,
    width,
    noTooltip,
    isReport = false,
    editableUpdateFindingData,
    setUpdateFinding,
    setEditableUpdateFinding,
    setCachedFindingData,
  } = props;

  const theme = useContext(ThemeContext);

  const { id: updateFindingId } = useParams();
  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  const CVSSVectorTitle = () => (
    <Flex w100 justify="between" align="start">
      <Flex align="center" gap="8px">
        CVSS Score & Vector
        {!noTooltip && (
          <Tooltip content={cvssTooltip}>
            <Icon
              name="info"
              color={isReport ? light.black700 : theme.black700}
              size={16}
            />
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {!isEditable ? (
          <Badge backgroundColor={theme.red50}>
            <LabelMedium color={theme.red800}>
              {finding?.cvss_score}
            </LabelMedium>
          </Badge>
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <Section
      title={!isEditable && <CVSSVectorTitle />}
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      {!isEditable ? (
        <Flex align="center" w100 style={{ overflowX: "hidden" }}>
          <Flex align="center" style={{ maxWidth: "85%", overflowX: "auto" }}>
            <Tooltip
              isTextTruncate
              placement="bottom"
              content={finding?.cvss_vector?.replace("CVSS:3.0/", "") || ""}
            >
              <BodyRegular style={{ color: isReport ? light.black800 : "" }}>
                {finding?.cvss_vector?.replace("CVSS:3.0/", "")}
              </BodyRegular>
            </Tooltip>
          </Flex>
          <Flex align="center" style={{ width: "12%" }}>
            {finding?.cvss_vector && (
              <CopyToClipboard
                value={finding?.cvss_vector?.replace("CVSS:3.0/", "")}
              />
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex column w100>
          <Flex w100>
            <LabelRegular
              className="text-truncate w-100"
              style={{ color: isReport ? light.black700 : theme.black700 }}
            >
              CVSS Score & Vector
            </LabelRegular>
            <Flex>
              <InputText
                placeholder="Insert Score"
                type="number"
                width="130px"
                saveChangesMode
                style={{ borderRadius: "2rem" }}
                onChange={(e) => {
                  setEditableUpdateFinding &&
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_score: Number(e.target.value),
                    }));
                  setCachedFindingData &&
                    setCachedFindingData({
                      cvss_score: Number(e.target.value),
                    });
                }}
                onSave={() => {
                  updateInPlace({
                    cvss_score: Number(editableUpdateFindingData?.cvss_score),
                  });
                }}
                onCancel={() => {
                  setEditableUpdateFinding &&
                    setEditableUpdateFinding((prev) => ({
                      ...prev,
                      cvss_score: finding?.cvss_score,
                    }));
                  setCachedFindingData &&
                    setCachedFindingData({ cvss_score: finding?.cvss_score });
                }}
                queryStatus={
                  changedField === "cvss_score" ? queryStatus : undefined
                }
                disabled={queryStatus !== "idle"}
                initValue={`${finding?.cvss_score || 0}`}
                value={`${editableUpdateFindingData?.cvss_score || 0}`}
              />
            </Flex>
          </Flex>
          <InputText
            placeholder="Insert Vector..."
            width="100%"
            saveChangesMode
            value={editableUpdateFindingData?.cvss_vector || ""}
            initValue={finding?.cvss_vector || ""}
            queryStatus={
              changedField === "cvss_vector" ? queryStatus : undefined
            }
            disabled={queryStatus !== "idle"}
            onSave={() => {
              updateInPlace({
                cvss_vector: editableUpdateFindingData?.cvss_vector,
              });
            }}
            onCancel={() => {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  cvss_vector: finding?.cvss_vector,
                }));
              setCachedFindingData &&
                setCachedFindingData({ cvss_vector: finding?.cvss_vector });
            }}
            onChange={(e) => {
              setEditableUpdateFinding &&
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  cvss_vector: e.target.value,
                }));
              setCachedFindingData &&
                setCachedFindingData({ cvss_vector: e.target.value });
            }}
          />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nvd.nist.gov/vuln-metrics/cvss/v3-calculator"
            style={{ textDecoration: "none", alignSelf: "end" }}
          >
            <TextButton label="Calculate CVSS" />
          </a>
        </Flex>
      )}
    </Section>
  );
};
