import React, { useContext } from "react";
import { Box } from "../../components/elements/box/Box";
import { Flex } from "../../components/layouts/flex/Flex";
import {
  BodyMedium,
  BodyRegular,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import moment from "moment";
import { ThemeContext } from "styled-components";
import { Scanner } from "../../types/Scanner";
import { ScanSummary } from "../../types/ScanSummary";
import { Badge } from "../../components/elements/badge/Badge";
import { Finding } from "../../types/Finding";
import { Tooltip } from "../../components/elements/tooltip/Tooltip";
import { Icon } from "../../components/elements/icon/Icon";
import { WEEK_IN_MS } from "../../shared/consts";
import { useNavigate } from "react-router";
import { objectToBase64 } from "../../shared/helper";

type Props = {
  scanSummary: ScanSummary;
  scanners: Scanner[];
  findings: Finding[];
  inProgressFindings: number;
  hideDate?: boolean;
};

export const ASMUpdatesTimelineItem = ({
  scanSummary,
  scanners,
  findings,
  inProgressFindings,
  hideDate,
}: Props) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  const realFindings = findings.filter(
    (finding) =>
      !finding.is_pending &&
      !finding.is_false_positive &&
      finding.status !== "dismissed"
  );

  const dismissedFindings =
    scanSummary.total_reported_findings -
    realFindings.length -
    inProgressFindings;

  const detectedFindings = scanSummary.total_reported_findings;

  const iSInTheLastWeek = (date: Date) =>
    new Date().getTime() - date.getTime() < WEEK_IN_MS;

  const navigateToFindingsWithRisk = (risk: 1 | 2 | 3 | 4) =>
    navigate(
      `/findings?filters=${objectToBase64({
        scan_id: scanSummary.scan_id,
        overall_risk: risk.toString(),
      })}`
    );

  return (
    <Flex column gap="8px">
      {!hideDate && (
        <LabelRegular color={theme.textSub}>
          {iSInTheLastWeek(new Date(scanSummary.scan_end_time))
            ? moment(scanSummary.scan_end_time).fromNow()
            : new Date(scanSummary.scan_end_time).toLocaleDateString()}
        </LabelRegular>
      )}
      <Box>
        <Flex justify="between" align="center" gap="4px">
          <Flex column gap="8px" w100>
            <Flex align="center" w100 justify="between" flexWrap>
              {detectedFindings === 0 ? (
                <Flex gap="4px">
                  <BodyMedium className="text-truncate">
                    No new findings
                  </BodyMedium>
                  <BodyMedium className="text-truncate" color={theme.textSub}>
                    were detected
                  </BodyMedium>
                </Flex>
              ) : (
                <Flex
                  gap="4px"
                  className="pointer"
                  onClick={() =>
                    navigate(
                      `/findings?filters=${objectToBase64({
                        scan_id: scanSummary.scan_id,
                      })}`
                    )
                  }
                >
                  <BodyMedium className="text-truncate">{`${
                    detectedFindings === 1
                      ? "One finding"
                      : `${detectedFindings} findings`
                  }`}</BodyMedium>
                  <BodyMedium className="text-truncate" color={theme.textSub}>
                    {detectedFindings === 1 ? "was" : "were"} detected
                  </BodyMedium>
                </Flex>
              )}
              {inProgressFindings > 0 && (
                <LabelRegular className="text-truncate">{`${inProgressFindings} findings in triage`}</LabelRegular>
              )}
            </Flex>

            <Flex gap="4px" w100>
              <BodyRegular color={theme.textSub}>By</BodyRegular>
              <BodyRegular className="text-truncate">
                {scanners.find((s) => s.name === scanSummary.scanner)
                  ?.display_name || scanSummary.scanner}
              </BodyRegular>
            </Flex>
            <Flex align="center" flexWrap gap="4px">
              {realFindings.filter((f) => f.overall_risk === 1).length > 0 && (
                <Badge
                  color={theme.lightBlue}
                  backgroundColor={theme.lightBlue50}
                  className="pointer"
                  onClick={() => navigateToFindingsWithRisk(1)}
                >{`${
                  realFindings.filter((f) => f.overall_risk === 1).length
                } Low`}</Badge>
              )}
              {realFindings.filter((f) => f.overall_risk === 2).length > 0 && (
                <Badge
                  color={theme.yellowPrimary}
                  backgroundColor={theme.yellow50}
                  className="pointer"
                  onClick={() => navigateToFindingsWithRisk(2)}
                >{`${
                  realFindings.filter((f) => f.overall_risk === 2).length
                } Medium`}</Badge>
              )}
              {realFindings.filter((f) => f.overall_risk === 3).length > 0 && (
                <Badge
                  color={theme.orangePrimary}
                  backgroundColor={theme.orange50}
                  className="pointer"
                  onClick={() => navigateToFindingsWithRisk(3)}
                >{`${
                  realFindings.filter((f) => f.overall_risk === 3).length
                } High`}</Badge>
              )}
              {realFindings.filter((f) => f.overall_risk === 4).length > 0 && (
                <Badge
                  color={theme.redPrimary}
                  backgroundColor={theme.red50}
                  className="pointer"
                  onClick={() => navigateToFindingsWithRisk(4)}
                >{`${
                  realFindings.filter((f) => f.overall_risk === 4).length
                } Critical`}</Badge>
              )}
              {dismissedFindings > 0 && (
                <Badge
                  color={theme.grayBlue800}
                  backgroundColor={theme.grayBlue50}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  className="pointer"
                  onClick={() =>
                    navigate(
                      `/findings?filters=${objectToBase64({
                        scan_id: scanSummary.scan_id,
                        is_false_positive: true,
                      })}`
                    )
                  }
                >
                  <LabelRegular>{`${dismissedFindings} Dismissed`}</LabelRegular>
                  <Tooltip
                    placement="top"
                    content={
                      <BodyRegular>{`${
                        dismissedFindings === 1
                          ? "Finding was"
                          : `${dismissedFindings} Findings were`
                      } dismissed after manual triage`}</BodyRegular>
                    }
                  >
                    <Icon name="info" color={theme.grayBluePrimary} size={16} />
                  </Tooltip>
                </Badge>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
