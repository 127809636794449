import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { FindingsCounts } from "../../../types/Finding";
import { useNavigate } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { objectToBase64 } from "../../../shared/helper";
import { Filters, findingsDefaultFilters } from "../Findings";
import { DoughnutChart } from "../../../components/composed/charts/DoughnutChart";
import { Mixpanel } from "../../../shared/mixpanel";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";

type Props = {
  findingsCounts: FindingsCounts;
  includeInfo?: boolean;
  hideLegendText?: boolean;
  isLoading?: boolean;
  selectedProduct?: number | "all";
  showOpenStatus?: boolean;
  filters?: Filters;
};

export const FindingDoughnutChart = (props: Props) => {
  const {
    findingsCounts,
    includeInfo = false,
    hideLegendText,
    isLoading,
    selectedProduct,
    showOpenStatus,
    filters,
  } = props;
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);

  const currentFilter = filters || findingsDefaultFilters;

  // Get counts for the legend
  // Ignore the risk filter to show all the counts if not selected
  let riskBreakdownFilter = { ...currentFilter };
  delete riskBreakdownFilter.overall_risk;
  const { data: findingsRiskCounts } =
    useApiFindingsCounts(riskBreakdownFilter);

  const getFindingsLink = (overall_risk: string) =>
    selectedProduct
      ? `/findings?filters=${objectToBase64({
          ...currentFilter,
          overall_risk,
          product: selectedProduct,
        })}`
      : `/findings?filters=${objectToBase64({
          ...currentFilter,
          overall_risk,
        })}`;

  const getLegendLabelColor = (overallRisk: number) =>
    currentFilter.overall_risk === `${overallRisk}`
      ? theme.black800
      : theme.textSub;

  var labels = ["Critical", "High", "Medium", "Low"];
  var datasets = findingsRiskCounts
    ? [
        {
          data: [
            findingsRiskCounts.open_criticals,
            findingsRiskCounts.open_highs,
            findingsRiskCounts.open_mediums,
            findingsRiskCounts.open_lows,
          ],
          backgroundColor: [
            theme.blue,
            theme.blue500,
            theme.blue300,
            theme.blue100,
          ],
        },
      ]
    : [{ data: [], backgroundColor: [] }];

  if (includeInfo) {
    labels.push("Info");
    datasets[0].data.push(findingsCounts?.open_infos || 0);
    datasets[0].backgroundColor.push(theme.blue50);
  }

  return (
    <Flex justify="center" align="center" gap="8px">
      <DoughnutChart
        labelNumber={findingsCounts?.total || 0}
        labelText={showOpenStatus ? "Open Issues" : "Total"}
        isLoadingData={isLoading}
        labels={labels}
        datasets={datasets}
        size={100}
        labelNumberLineHeight={showOpenStatus ? "35px" : "45px"}
      />
      <Flex column data-testid="legend-container">
        <LegendItem
          color={theme.blue}
          labelColor={getLegendLabelColor(4)}
          labelSize={12}
          label="Critical"
          value={findingsRiskCounts?.open_criticals}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Critical" });
            navigate(getFindingsLink("4"));
          }}
        />
        <LegendItem
          color={theme.blue500}
          labelSize={12}
          label="High"
          labelColor={getLegendLabelColor(3)}
          value={findingsRiskCounts?.open_highs}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "High" });
            navigate(getFindingsLink("3"));
          }}
        />
        <LegendItem
          color={theme.blue300}
          labelColor={getLegendLabelColor(2)}
          labelSize={12}
          label="Medium"
          value={findingsRiskCounts?.open_mediums}
          hideLegendText={hideLegendText}
          onClick={() => {
            navigate(getFindingsLink("2"));
          }}
        />
        <LegendItem
          color={theme.blue100}
          labelColor={getLegendLabelColor(1)}
          labelSize={12}
          label="Low"
          value={findingsRiskCounts?.open_lows}
          hideLegendText={hideLegendText}
          onClick={() => {
            Mixpanel.track("Findings Overview", { severity: "Low" });
            navigate(getFindingsLink("1"));
          }}
        />
        {includeInfo && (
          <LegendItem
            color={theme.blue50}
            labelColor={getLegendLabelColor(0)}
            labelSize={12}
            label="Info"
            value={findingsRiskCounts?.open_infos || 0}
            hideLegendText={hideLegendText}
            onClick={() => {
              Mixpanel.track("Findings Overview", { severity: "Info" });
              navigate(getFindingsLink("0"));
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
