import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyMedium,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Project } from "../../../types/Project";
import { getActivityType } from "../../../shared/helper";
import { light } from "../../../shared/theme";
import { useApiProducts } from "../../../hooks/queries/productsContext";

type Props = {
  project?: Project;
  reportType:
    | "Full Report"
    | "Retest Report"
    | "Executive Summary Report"
    | "Attestation Letter"
    | "Attack Surface management (ASM)";
};

const today = new Date();

export const ProjectFirstPage = ({ project, reportType }: Props) => {
  const { data: products } = useApiProducts({ id: project?.products });

  const dateStringWithoutDay = (date: Date) => {
    return date.toDateString().replace(/^\w+ /, "");
  };

  const getProjectDates = () =>
    `${dateStringWithoutDay(
      new Date(project?.start || "")
    )} - ${dateStringWithoutDay(new Date(project?.end || ""))}`;

  return (
    <>
      <Flex
        column
        gap="32px"
        justify="center"
        align="center"
        w100
        className="fully-justify"
        style={{ paddingTop: "48px" }}
      >
        <HeaderMain color={light.textHeader} className="text-center">
          {project?.name || "Project name"}
        </HeaderMain>
        <Flex gap="8px">
          {products?.map((p, idx) => (
            <Flex align="center" gap="8px" flexWrap>
              {idx > 0 ? "|" : ""}
              <HeaderSecondary color={light.textHeader}>
                {p.name}
              </HeaderSecondary>
            </Flex>
          ))}
        </Flex>
        <HeaderSecondary color={light.blue800} className="text-center">
          {getActivityType(project?.type)}
        </HeaderSecondary>
      </Flex>
      <Flex
        column
        gap="8px"
        justify="start"
        align="start"
        w100
        className="mt-5"
        style={{ pageBreakAfter: "always" }}
      >
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Period:`}</BodyMedium>
          <BodyBold color={light.textBody}>{getProjectDates()}</BodyBold>
        </Flex>
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Date of report:`}</BodyMedium>
          <BodyBold color={light.textBody}>
            {/* https://stackoverflow.com/questions/48384163/javascript-remove-day-name-from-date */}
            {dateStringWithoutDay(today)}
          </BodyBold>
        </Flex>
      </Flex>
    </>
  );
};
