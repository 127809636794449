import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { light } from "../../../shared/theme";
import { useLocation } from "react-router";
import { Project } from "../../../types/Project";

type Props = {
  projects?: Project[];
  findings: Finding[];
  isMultiTenantReport?: Boolean;
};

export const TableOfContents = ({
  findings,
  projects,
  isMultiTenantReport,
}: Props) => {
  const location = useLocation();
  const reportType = location?.pathname.split("/")[2];
  let pagesCount = 9; // the first finding is fixed to be on page 9

  return (
    <Flex
      column
      w100
      gap="24px"
      style={{ pageBreakAfter: "always", paddingTop: "48px" }}
    >
      <HeaderSecondary className="text-center w-100" color={light.blue800}>
        Table of Contents
      </HeaderSecondary>
      <Flex column w100 style={{ color: light.blue800 }} gap="8px">
        <Flex justify="between" align="center" w100>
          <HeaderSubBold color={light.textHeader}>
            1. Executive Summary
          </HeaderSubBold>
          <BodyBold color={light.textBody}>5</BodyBold>
        </Flex>
        {!isMultiTenantReport && (
          <>
            <Flex justify="between" align="center" w100 className="ps-3">
              <BodyRegular color={light.textBody}>Methodology</BodyRegular>
              <BodyBold color={light.textBody}>5</BodyBold>
            </Flex>
            <Flex justify="between" align="center" w100 className="ps-3">
              <BodyRegular color={light.textBody}>
                Project Objectives
              </BodyRegular>
              <BodyBold color={light.textBody}>5</BodyBold>
            </Flex>
            <Flex justify="between" align="center" w100 className="ps-3">
              <BodyRegular color={light.textBody}>Using the Wasp</BodyRegular>
              <BodyBold color={light.textBody}>6</BodyBold>
            </Flex>
          </>
        )}
        {reportType === "asm-report" && (
          <Flex justify="between" align="center" w100 className="ps-3">
            <BodyRegular color={light.textBody}>
              Summary of Scanned assets
            </BodyRegular>
            <BodyBold color={light.textBody}>6</BodyBold>
          </Flex>
        )}
        <Flex justify="between" align="center" w100 className="ps-3">
          <BodyRegular color={light.textBody}>Summary of results</BodyRegular>
          <BodyBold color={light.textBody}>7</BodyBold>
        </Flex>
        <Flex justify="between" align="center" w100 className="ps-3">
          <BodyRegular color={light.textBody}>Results at a glance</BodyRegular>
          <BodyBold color={light.textBody}>8</BodyBold>
        </Flex>
        {!!findings.length && (
          <Flex justify="between" align="center" w100>
            <HeaderSubBold color={light.textHeader}>
              2. Findings in details
            </HeaderSubBold>
            <BodyBold color={light.textBody}>9</BodyBold>
          </Flex>
        )}
        {projects?.map((project, projectIdx) => {
          const projectFindings = findings?.filter(
            (f) => f.project === project.id
          );

          pagesCount =
            projectIdx > 0
              ? pagesCount +
                (findings?.filter((f) => f.project === project.id).length - 1 ||
                  0)
              : pagesCount;

          return (
            <Flex column gap="4px" key={project.id} className="ps-3">
              <BodyRegular color={light.textBody} className="text-truncate">
                {project.name}
              </BodyRegular>
              {projectFindings.map((finding, index) => (
                <Flex
                  justify="between"
                  align="center"
                  w100
                  className="ps-3"
                  key={`table-content-finding-${finding.id}`}
                >
                  <BodyRegular
                    color={light.textBody}
                    className="text-truncate"
                  >{`Finding #${index + 1}: ${finding.title}`}</BodyRegular>
                  <BodyBold color={light.textBody}>
                    {pagesCount + index}
                  </BodyBold>
                </Flex>
              ))}
            </Flex>
          );
        })}

        {!isMultiTenantReport && (
          <Flex justify="between" align="center" w100>
            <HeaderSubBold color={light.textHeader}>
              3. Appendix A: Testing methodology
            </HeaderSubBold>
            <BodyBold color={light.textBody}>
              {10 + (findings.length || -1)}
            </BodyBold>
          </Flex>
        )}
        <Flex justify="between" align="center" w100>
          <HeaderSubBold color={light.textHeader}>
            4. Appendix B: Findings classification
          </HeaderSubBold>
          <BodyBold color={light.textBody}>
            {12 + (findings.length || -1)}
          </BodyBold>
        </Flex>
      </Flex>
    </Flex>
  );
};
