import { FirstPage } from "../generator/FirstPage";
import {
  DisclaimerPage,
  MultiTenantDisclaimerPage,
} from "../generator/DisclaimerPage";
import { AboutPage } from "../generator/AboutPage";
import { PageLayout } from "../generator/PageLayout";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";
import { SummaryOfResults } from "../generator/SummaryOfResults";
import { TableOfContents } from "../generator/TableOfContents";
import { ExecutiveSummary } from "../generator/ExecutiveSummary";
import { getActivityType } from "../../../shared/helper";
import { Methodology } from "../generator/Methodology";
import { AppendixA } from "../generator/AppendixA";
import { AppendixB } from "../generator/AppendixB";
import { TeamMembers } from "../generator/TeamMembers";
import { ResultsAtGlance } from "../generator/ResultsAtGlance";
import { ProjectObjectives } from "../generator/ProjectObjectives";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { useReportData } from "../useReportData";
import { useState } from "react";
import { ReportsLoader } from "../ReportsLoader";
import { ProjectFirstPage } from "../generator/ProjectFirstPage";

type Props = {};
const WAITING_TIME = 3; //seconds
export const RetestReport = (props: Props) => {
  const {
    projects,
    isFetchingProject,
    findings,
    isFetchingFindings,
    isMultiTenantReport,
    isPageLoaded,
    isFetching,
    projectsNames,
  } = useReportData();

  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);

  const isReadyToPrint = !!(
    !loadingData &&
    !!projects?.length &&
    !!findings?.length
  );

  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  usePrint(isReadyToPrint, `Retest-Report-${projectsNames}`);

  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <ReportsLoader
          setLoadingData={(state) => setLoadingData(state)}
          countdown={countdown}
        />
      )}
      <FirstPage projects={projects} reportType="Retest Report" />
      {isMultiTenantReport ? <MultiTenantDisclaimerPage /> : <DisclaimerPage />}
      {!isMultiTenantReport && <AboutPage />}
      <TableOfContents
        findings={[]}
        isMultiTenantReport={isMultiTenantReport}
      />
      {!isMultiTenantReport && (
        <>
          <ExecutiveSummary
            findings={findings || []}
            activityType={getActivityType(projects?.[0].type)}
            isRetest
          />
          <Methodology />
          <ProjectObjectives />
          <TeamMembers findings={findings || []} />
        </>
      )}
      {projects?.map((project, idx) => {
        const projectFindings = findings?.filter(
          (f) => f.project === project.id
        );
        return (
          <>
            {idx > 0 && (
              <div style={{ pageBreakBefore: "always", marginTop: "48px" }} />
            )}

            {/* Show project header only on multi projects reports */}
            {projects.length > 1 && (
              <ProjectFirstPage project={project} reportType="Retest Report" />
            )}

            <SummaryOfResults findings={projectFindings || []} includeStatus />
            <ResultsAtGlance findings={projectFindings || []} />
          </>
        );
      })}
      {!isMultiTenantReport && <AppendixA />}
      <AppendixB />
    </PageLayout>
  );
};
