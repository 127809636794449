import styled, { keyframes } from "styled-components";

// Define the keyframes for the progress bar animation
const progressAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

interface ProgressBarProps {
  height: string;
  width: string;
  duration: number; // Duration in seconds
}

export const LoadingProgressBar = styled.div<ProgressBarProps>`
  background-color: ${(props) => props.theme.blue300};
  border-radius: 8px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    height: 100%;
    background-color: ${(props) => props.theme.primary};
    position: absolute;
    animation: ${progressAnimation} ${(props) => props.duration}s linear;
  }
`;

// Example Usage
// <ProgressBar height="10px" width="100%" duration={60} />
