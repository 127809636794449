import { getDomain } from "tldts";
import { Option } from "../../components/elements/dropdowns/Dropdown";
import {
  Asset,
  ASSET_TYPES_NAMES,
  AssetCoverageStatus,
  AssetDomainProperties,
  AssetEdit,
  AssetGrade,
  AssetStatus,
} from "../../types/Asset";
import { Filter } from "../../types/AssetsView";
import { Product } from "../../types/Product";
import { Project, ProjectRequest } from "../../types/Project";

export const convertAssetToEdit = (asset: Asset | undefined): AssetEdit => {
  return {
    environment: asset?.environment,
    is_asm_enabled: asset?.is_asm_enabled,
    priority: asset?.priority,
    product: asset?.product,
    tags: asset?.tags,
    cloud_credentials: asset?.cloud_credentials,
    source: asset?.source,
    status: asset?.status,
    parent_asset: asset?.parent_asset,
  };
};

export const margeEditAndAsset = (
  asset: Asset,
  editAsset: AssetEdit,
  products: Product[]
): Asset => {
  if (editAsset.tags) {
    asset.tags = editAsset.tags;
  }
  asset.environment = editAsset?.environment || asset.environment;
  asset.is_asm_enabled = editAsset?.is_asm_enabled || asset.is_asm_enabled;
  asset.priority = editAsset?.priority || asset.priority;
  asset.product =
    products.find((product) => product.id === editAsset.product)?.id ||
    asset.product;
  return asset;
};

export const calculateAssetGrade = (risk_score: number): AssetGrade => {
  if (risk_score < 5) return "A";
  if (risk_score < 10) return "B";
  if (risk_score < 20) return "C";
  if (risk_score < 30) return "D";
  if (risk_score < 40) return "E";
  return "F";
};

export const gradeToRiskLevelMap: { [key: string]: string } = {
  a: "No Risk",
  b: "Minor Risk",
  c: "Low Risk",
  d: "Medium Risk",
  e: "High Risk",
  f: "Critical",
};

export const getGradeColor = (grade: AssetGrade): string => {
  if (grade === "A") return "green800";
  if (grade === "B") return "lightBlue800";
  if (grade === "C") return "grayBlue800";
  if (grade === "D") return "yellow800";
  if (grade === "E") return "orange800";
  return "red800";
};

export const getGradeBackground = (grade: AssetGrade): string => {
  if (grade === "A") return "green50";
  if (grade === "B") return "lightBlue50";
  if (grade === "C") return "grayBlue50";
  if (grade === "D") return "yellow50";
  if (grade === "E") return "orange50";
  return "red50";
};

export const getGradeFilters = (
  assetGrade: AssetGrade,
  maxAssetGrade?: AssetGrade
): Filter[] => {
  let filters = [];
  const grade = assetGrade.toLowerCase();
  const maxGrade = maxAssetGrade ? maxAssetGrade.toLowerCase() : "";

  const gradesMap: { [key: string]: { lt?: string; gte?: string } } = {
    a: { lt: "5" },
    b: { lt: "10", gte: "5" },
    c: { lt: "20", gte: "10" },
    d: { lt: "30", gte: "20" },
    e: { lt: "40", gte: "30" },
    f: { gte: "40" },
  };

  if (gradesMap[grade].lt)
    filters.push({
      column: "risk_score",
      value: maxGrade ? gradesMap[maxGrade].lt : gradesMap[grade].lt,
      condition: "lt",
      order: 1,
      next_condition: "and",
    } as Filter);

  if (gradesMap[grade].gte) {
    filters.push({
      column: "risk_score",
      value: gradesMap[grade].gte,
      condition: "gt",
      order: 2,
      next_condition: "and",
    } as Filter);

    filters.push({
      column: "risk_score",
      value: gradesMap[grade].gte,
      condition: "is",
      order: 3,
      next_condition: "or",
    } as Filter);
  }

  return filters;
};

export const getAssetCoverageStatus = (
  asset: Asset,
  projects: Project[],
  projectRequests: ProjectRequest[]
): AssetCoverageStatus => {
  const assetProjects = asset.projects?.map((id) =>
    projects.find((p) => p.id === id)
  );

  const now = new Date();

  const onGoingPT = assetProjects?.some(
    (project) =>
      project &&
      project.start &&
      project.end &&
      new Date(project.start) < now &&
      new Date(project.end) > now
  );

  const ptInLastSixMonths = assetProjects?.some(
    (project) =>
      project &&
      project.end &&
      new Date(project.end) > new Date(now.setMonth(now.getMonth() - 6))
  );

  const pendingPTRequestCount = projectRequests.some(
    (pr) => pr.included_assets?.includes(asset.id) && pr.status !== "done"
  );

  const hasScheduledPT = assetProjects?.some(
    (project) => project && project.start && new Date(project.start) > now
  );

  if (onGoingPT) {
    return "ongoing_pt"; // white border
  } else if (hasScheduledPT) {
    return "pt_scheduled"; // calender
  } else if (pendingPTRequestCount) {
    return "pending_pt_request"; // clock
  } else if (ptInLastSixMonths) {
    return "pt_in_past_6_months"; // nothing
  } else {
    return "no_scheduled_or_requested_pt"; // cross-line
  }
};

export const getAssetNextPt = (
  asset: Asset,
  projects: Project[]
): Date | undefined => {
  const nextProjects = asset.projects
    ?.map((id) => projects.find((p) => p.id === id))
    ?.filter(
      (project) =>
        project && project.start && new Date(project.start) > new Date()
    )
    .sort(
      (a, b) =>
        new Date(a?.start || 0).getTime() - new Date(b?.start || 0).getTime()
    );

  if (nextProjects?.length > 0) {
    return new Date(nextProjects[0]?.start || 0);
  }
  return undefined;
};

export const getAssetLastPt = (
  asset: Asset,
  projects: Project[]
): Date | undefined => {
  const nextProjects = asset.projects
    ?.map((id) => projects.find((p) => p.id === id))
    ?.filter(
      (project) => project && project.end && new Date(project?.end) < new Date()
    )
    .sort(
      (a, b) =>
        new Date(a?.end || 0).getTime() - new Date(b?.end || 0).getTime()
    );

  if (nextProjects?.length > 0) {
    return new Date(nextProjects[0]?.end || 0);
  }
  return undefined;
};

export const priorityOptions: Option[] = [
  { label: "Very High", value: 5 },
  { label: "High", value: 4 },
  { label: "Medium", value: 3 },
  { label: "Low", value: 2 },
  { label: "Very Low", value: 1 },
];

export const getPriorityLabel = (priority: number): string => {
  const option = priorityOptions.find((option) => option.value === priority);
  return option?.label || "Very Low";
};

export function getUpdateBulkModalWarning(assets: Asset[]): string {
  const hasDiffProducts = new Set(assets.map((a) => a.product)).size > 1;
  const hasDiffEnvironments =
    new Set(assets.map((a) => a.environment)).size > 1;
  const hasDiffPriorities = new Set(assets.map((a) => a.priority)).size > 1;
  const hasStatusDiff = new Set(assets.map((a) => a.status)).size > 1;
  // count how many boolean values are true
  const count = [
    hasDiffProducts,
    hasDiffEnvironments,
    hasDiffPriorities,
  ].filter((x) => x).length;
  var message = "";
  if (hasDiffProducts) {
    message += "Product";
  }
  if (hasDiffEnvironments) {
    if (hasDiffProducts) {
      message += count > 2 ? ", " : " and ";
    }
    message += "Environment";
  }
  if (hasStatusDiff) {
    if (hasDiffProducts || hasDiffEnvironments) {
      message += " and ";
    }
    message += "Status";
  }
  if (hasDiffPriorities) {
    if (hasDiffProducts || hasDiffEnvironments || hasStatusDiff) {
      message += " and ";
    }
    message += "Priority";
  }
  return message;
}

export function getAssetGradeTooltipContent(grade: AssetGrade): string {
  if (grade === "A")
    return `
    This is the highest security grade,
    indicating that there are very few open issues and no SLA violations.
    This means that the system is secure and operating smoothly. Keep up the good work!`;
  if (grade === "B")
    return `
    This grade indicates that there are some minor issues that need attention,
    such as low-severity issues or a few SLA violations.
    These issues should be addressed promptly to ensure that the system remains secure.`;
  if (grade === "C")
    return `
    This grade indicates that there are several open issues and/or SLA violations that need attention.
    These issues are of medium severity and could pose a risk to the security of the system if left unaddressed.
    Action should be taken as soon as possible to resolve these issues.`;
  if (grade === "D")
    return `
    This grade indicates that there are many open issues and/or SLA violations that need attention.
    These issues are of moderate to high severity and could pose a significant risk to the security of the system if left unaddressed.
    Immediate action should be taken to address these issues.`;
  if (grade === "E")
    return `This is a low security grade and indicates that there are many open issues and/or SLA violations that have not been addressed.
    These issues are of high severity and pose a significant risk to the security of the system.
    Urgent action should be taken to resolve these issues.`;
  // grade === "F"
  return `
  This is the lowest security grade and indicates that there are numerous open issues and/or SLA violations that have not been addressed.
  These issues are of critical severity and pose an immediate and significant risk to the security of the system.
  Immediate action must be taken to address these issues to prevent potential security breaches.`;
}

export const getAssetType = (asset: Asset) => {
  if (asset.type === "domain") {
    return getDomain(
      (asset?.properties as AssetDomainProperties)?.domain_name || ""
    ) === (asset?.properties as AssetDomainProperties)?.domain_name
      ? "Domain"
      : "Subdomain";
  } else {
    return ASSET_TYPES_NAMES[asset.type];
  }
};

export const getAssetStatusIcon = (assetStatus: AssetStatus) => {
  switch (assetStatus) {
    case "live":
      return "liveStatusIcon";
    case "dead":
      return "deadStatusIcon";
    case "archived":
      return "in_progress";
    case "false_positive":
      return "falsePositiveStatusIcon";
    default:
      return "liveStatusIcon";
  }
};
