import { useContext, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { useApiUpdateFindingsBulk } from "../../../hooks/queries/findingContext";
import useToastContext from "../../../hooks/toastHook";
import { Icon } from "../../../components/elements/icon/Icon";
import { Menu } from "../../../components/elements/menu/Menu";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { useOutsideTrigger } from "../../../hooks/utilsHooks";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Backdrop } from "../../../components/elements/rightPane/RightPane";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Finding } from "../../../types/Finding";
import { Checkbox } from "../../../components/elements/checkbox/Checkbox";

type Props = {
  close: () => void;
  findings: Finding[];
};

export const PublishBulkFindingsMenu = (props: Props) => {
  const { close, findings } = props;
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const wrapperRef = useRef(null);
  useOutsideTrigger(wrapperRef, close);
  const [isPending, setIsPending] = useState(false);

  const { mutate: publishFindings, isLoading } = useApiUpdateFindingsBulk({
    is_pending: true,
  });
  const findingIds = findings.map((f) => f.id);

  const handlePublish = () => {
    addToast({
      message: `Publishing findings...`,
      type: "info",
    });

    publishFindings({
      findingsData: {
        findings: findingIds,
        all: false,
        updates: {
          is_pending: isPending,
        },
      },
      onSuccessCallback: () => {
        addToast({
          message: `Findings have been successfully ${isPending ? "unpublished" : "published"}!`,
          type: "success",
          durationMs: 10000,
        });
        close();
      },
      onErrorCallback: (error: Error) => {
        addToast({ message: error.message, type: "error" });
        close();
      },
    });
  };

  return (
    <>
      <Backdrop
        transparent
        blurEnabled={false}
        onClick={close}
        style={{
          position: "absolute",
          left: "-100vw",
          top: "-100vh",
          bottom: "-100vh",
          right: "-100vw",
        }}
      />
      <Menu
        data-testid="merge-findings-menu"
        ref={wrapperRef}
        style={{
          width: "320px",
          zIndex: 200,
        }}
      >
        <Flex column w100 gap="8px">
          <LabelRegular
            className="d-flex w-100"
            style={{ color: theme.black900 }}
          >
            Publish Findings
            <span className="ms-auto pointer" onClick={close}>
              <Icon name="cancel" size={16} color={theme.primary} />
            </span>
          </LabelRegular>
          <SeparatorHorizontal style={{ marginBlock: "8px" }} />

          <Flex column gap="16px">
            <Checkbox
              state={!isPending ? "checked" : "unchecked"}
              onChange={(checked) => setIsPending(checked !== "checked")}
              label="Publish (not pending)"
            />
          </Flex>

          <MainButton
            label="Publish"
            dataTestId="submit-btn"
            size="small"
            style={{ width: "83px", alignSelf: "flex-end" }}
            inProgress={isLoading}
            onClick={handlePublish}
          />
        </Flex>
      </Menu>
    </>
  );
};
