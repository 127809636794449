import styled from "styled-components";
import { TypographyLabelMedium } from "../typography/Typography";

type Props = {
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
};
export const Badge = styled.div<Props>`
  ${TypographyLabelMedium}
  width: fit-content;
  display: flex;
  align-items: center;
  height: 26px;
  padding: 4px 12px;
  border-radius: 24px;
  color: ${(props) => props.color || props.theme.primary};
  background-color: ${(props) => props.backgroundColor || props.theme.blue100};
`;
