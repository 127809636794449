import { Menu, MenuBackground } from "../../../components/elements/menu/Menu";
import { MenuItem } from "../../../components/elements/menu/MenuItem";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { BASE_API_URL } from "../../../hooks/queries/utils";
import { getCustomerNameUrlFormat } from "../../../shared/helper";
import { Project } from "../../../types/Project";
import { reportTypesOptions } from "../../reports/ReportsGenV2";

type Props = {
  project: Project | null | undefined;
  onClose: () => void;
};

export const ReportsMenu = (props: Props) => {
  const { project, onClose } = props;
  const { data: me } = useApiMe();

  const reportsOptions = reportTypesOptions.filter(
    (r) => r.value !== "asm-report"
  ); // Remove ASM report from the list of reports

  const handleMenuClick = (reportType: string) => {
    if (!me) return;
    const paramsObj: { [key: string]: any } = {
      projectsIds: project?.id,
      productsIds: project?.products,
    };
    const urlCustomerName = getCustomerNameUrlFormat(me.customer.name);
    const params = new URLSearchParams(paramsObj).toString();
    window.open(
      `/${urlCustomerName}/reports/${reportType}?${params}`,
      "_blank"
    );
    onClose();
  };

  return (
    <>
      <MenuBackground onClick={onClose} />
      <Menu style={{ top: "40px" }}>
        <Flex column gap="12px">
          {reportsOptions?.map((reportType) => (
            <MenuItem
              key={reportType.value}
              label={reportType.label}
              onClick={() => handleMenuClick(reportType.value as string)}
            />
          ))}

          {project?.reports?.map((report, i) => (
            <MenuItem
              key={`report-${i}`}
              label={report.title}
              onClick={() => {
                window.open(
                  `${BASE_API_URL}/projects/${project.id}/report/${report.filename}`,
                  "_blank"
                );
                onClose();
              }}
            />
          )) || <></>}
        </Flex>
      </Menu>
    </>
  );
};
