import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Project } from "../../../types/Project";
import { addClassToWaspHtmlImages } from "../../../shared/parser";
import { light } from "../../../shared/theme";

type Props = {
  project: Project;
};

export const ProjectScope = ({ project }: Props) => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{ pageBreakAfter: "always", paddingTop: "48px" }}
    >
      <HeaderSecondary
        color={light.blue800}
        style={{
          textTransform: "none",
        }}
      >
        Project Scope
      </HeaderSecondary>
      <BodyRegular
        className="fully-justify"
        color={light.black800}
        style={{
          overflowWrap: "anywhere",
        }}
        dangerouslySetInnerHTML={{
          __html: addClassToWaspHtmlImages(project?.project_scope || ""),
        }}
      ></BodyRegular>
    </Flex>
  );
};
