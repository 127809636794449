import { useNavigate } from "react-router";
import { Icon } from "../../../components/elements/icon/Icon";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { FindingChartTooltipContent } from "./FindingChartTooltipContent";
import { objectToBase64 } from "../../../shared/helper";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  relatedFindings: number[];
  color?: string;
  assetId: number;
};

export const FindingsItem = (props: Props) => {
  const { relatedFindings, color, assetId } = props;
  const navigate = useNavigate();

  const filters = objectToBase64({
    affected_assets: [assetId],
    status: "presented,in_progress,re_test",
  });

  const { data: assetOpenFindings } = useApiFindingsCounts({
    id: relatedFindings.slice(0, 100),
    status: "presented,in_progress,re_test",
  });

  const openFindingsCount = assetOpenFindings?.total || 0;

  return (
    <div
      onClick={() =>
        openFindingsCount > 0 && navigate(`/findings?filters=${filters}`)
      }
      className="d-flex gap-6 align-items-center"
      style={{
        cursor: openFindingsCount > 0 ? "pointer" : "default",
        transition: "color 0.2s",
      }}
    >
      {relatedFindings.length > 0 && (
        <Tooltip
          content={
            <Flex column w100 justify="center" align="center" gap="16px">
              <FindingChartTooltipContent relatedFindings={relatedFindings} />
              <Flex w100 gap="4px" column justify="center" align="center">
                <Flex gap="4px">
                  <BodyBold>{openFindingsCount}</BodyBold>
                  <BodyRegular>Open Findings</BodyRegular>
                </Flex>
                {openFindingsCount > 0 ? (
                  <BodyRegular>Click to view on findings page</BodyRegular>
                ) : null}
              </Flex>
            </Flex>
          }
        >
          <BodyBold color={color}>{relatedFindings.length || 0}</BodyBold>
          <Icon name="info" size={16} color={color} />
        </Tooltip>
      )}
    </div>
  );
};
