import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyMedium,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Project } from "../../../types/Project";
import { useApiMe } from "../../../hooks/queries/meContext";
import { getActivityType } from "../../../shared/helper";
import { light } from "../../../shared/theme";
import { useSearchParams } from "react-router-dom";
import { useApiProducts } from "../../../hooks/queries/productsContext";

type Props = {
  projects?: Project[];
  reportType:
    | "Full Report"
    | "Retest Report"
    | "Executive Summary Report"
    | "Attestation Letter"
    | "Attack Surface management (ASM)";
};

const today = new Date();

export const FirstPage = ({ projects, reportType }: Props) => {
  const { data: me } = useApiMe();
  const [searchParams] = useSearchParams();
  const productsIdsStr = searchParams.get("productsIds");
  const after = searchParams.get("after");
  const before = searchParams.get("before");

  const { data: products } = useApiProducts(
    { id: productsIdsStr },
    !!productsIdsStr
  );

  const dateStringWithoutDay = (date: Date) => {
    return date.toDateString().replace(/^\w+ /, "");
  };

  const getProjectDates = () => {
    const sortedProjectsStart = projects?.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });
    const sortedProjectsEnd = projects?.sort((a, b) => {
      return new Date(b.end).getTime() - new Date(a.end).getTime();
    });
    let start = sortedProjectsStart?.[0].start;
    let end = sortedProjectsEnd?.[0].end;

    if (reportType.includes("ASM")) {
      start = after || start;
      end = before || today.toISOString();
    }

    return `${dateStringWithoutDay(
      new Date(start || "")
    )} - ${dateStringWithoutDay(new Date(end || ""))}`;
  };

  return (
    <>
      <Flex
        column
        gap="32px"
        justify="center"
        align="center"
        w100
        className="fully-justify"
      >
        <HeaderSecondary color={light.blue800} className="text-center">
          {getActivityType(projects?.[0].type)}
        </HeaderSecondary>
        <HeaderSecondary color={light.grayBlue800} className="text-center">
          {reportType}
        </HeaderSecondary>
        {me?.customer.logo_file && (
          <img
            alt="customer-brand-logo"
            src={`/api/v1/customers/${me?.customer.id}/logo`}
            style={{
              height: "200px",
            }}
          />
        )}
        <Flex column gap="12px">
          {projects?.map((p) => (
            <Flex column key={p.id}>
              <HeaderMain color={light.textHeader}>
                {p.name || "Project name"}
              </HeaderMain>
              {p.products
                .map((id) => products?.find((prod) => prod.id === id))
                .map(
                  (prod) =>
                    !!prod && (
                      <HeaderSecondary color={light.textHeader}>
                        {prod.name}
                      </HeaderSecondary>
                    )
                )}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex
        column
        gap="8px"
        justify="start"
        align="start"
        w100
        className="mt-5"
        style={{ pageBreakAfter: "always" }}
      >
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Period:`}</BodyMedium>
          <BodyBold color={light.textBody}>{getProjectDates()}</BodyBold>
        </Flex>
        <Flex align="center" gap="8px">
          <BodyMedium color={light.textBody}>{`Date of report:`}</BodyMedium>
          <BodyBold color={light.textBody}>
            {/* https://stackoverflow.com/questions/48384163/javascript-remove-day-name-from-date */}
            {dateStringWithoutDay(today)}
          </BodyBold>
        </Flex>
      </Flex>
    </>
  );
};
