import { Dispatch, SetStateAction, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { DefaultFindingsCounts, FindingsCounts } from "../../../types/Finding";
import { FindingDoughnutChart } from "../findingsCharts/FindingDoughnutChart";
import { TopBox, TopBoxTitle } from "../FindingsStyles";
import { Flex } from "../../../components/layouts/flex/Flex";
import { FixedInTheLast7Days } from "./FixedInLast7Days";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../../shared/consts";
import { Filters } from "../Findings";

const FindingsOverview = ({
  findingsCounts,
  isFetching,
  setFilters,
  filters,
}: {
  findingsCounts: FindingsCounts | undefined;
  isFetching?: boolean;
  setFilters: Dispatch<SetStateAction<{ [key: string]: any }>>;
  filters?: Filters;
}) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  return (
    <TopBox>
      <TopBoxTitle>
        <Icon name="findings" size={32} color={theme.primary} />
        <HeaderSecondary>Findings Overview</HeaderSecondary>
      </TopBoxTitle>

      <Flex w100 gap="32px">
        <Flex>
          <FindingDoughnutChart
            findingsCounts={findingsCounts || DefaultFindingsCounts}
            isLoading={isFetching}
            hideLegendText={isSmallScreen}
            filters={filters}
          />
        </Flex>
        <SeparatorVertical />
        <FixedInTheLast7Days setFilters={setFilters} isLoading={!!isFetching} />
      </Flex>
    </TopBox>
  );
};

export default FindingsOverview;
