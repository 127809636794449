import {
  LineChart,
  LineChartDataset,
} from "../../../components/composed/charts/LineChart";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../components/layouts/flex/Flex";
import { DAY_MS, SCREEN_MEDIUM_LAPTOP_WIDTH } from "../../../shared/consts";
import { Option } from "../../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { secBenchmarkMetrics } from "../../../hooks/queries/findingContext";
import {
  BodyRegularHover,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { OPEN_STATUSES } from "../../../types/Finding";
import { objectToBase64, toBase64AssetsView } from "../../../shared/helper";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { useNavigate } from "react-router";
import { OverTimeMetric, titleMap } from "./RiskScoreBenchmark";
import { Bar } from "react-chartjs-2";
import {
  BarControllerChartOptions,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  Legend,
  LinearScale,
  PluginChartOptions,
  ScaleChartOptions,
  Title,
  Tooltip,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { useRiskColors } from "../../../shared/findingsHelper";
import { Mixpanel } from "../../../shared/mixpanel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.font.family = "poppins";
ChartJS.defaults.font.size = 11;
ChartJS.defaults.font.lineHeight = "21px";
type BarChartOptions = _DeepPartialObject<
  CoreChartOptions<"bar"> &
    ElementChartOptions<"bar"> &
    PluginChartOptions<"bar"> &
    DatasetChartOptions<"bar"> &
    ScaleChartOptions<"bar"> &
    BarControllerChartOptions
>;

const actionsMap = {
  risk_score: [
    {
      title: "Resolve critical findings",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), overall_risk: "4" })}`,
    },
    {
      title: "Resolve findings affecting multiple assets",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), widespread: true })}`,
    },
  ],
  coverage_score: [
    {
      title: "Activate ASM scans for disabled assets",
      url: `/assets?view=${toBase64AssetsView({
        name: "",
        filters: [
          {
            column: "is_asm_enabled",
            value: "Disabled",
            condition: "is",
            order: 0,
            next_condition: "and",
          },
          {
            column: "status",
            value: "live",
            condition: "is",
            order: 0,
            next_condition: "and",
          },
        ],
      })}`,
    },
    {
      title: "Add untested assets to PT project",
      url: `/assets?view=${toBase64AssetsView({
        name: "",
        filters: [
          {
            column: "pt_status",
            value: "None",
            condition: "is",
            order: 1,
            next_condition: "and",
          },
          {
            column: "status",
            value: "live",
            condition: "is",
            order: 0,
            next_condition: "and",
          },
        ],
      })}`,
    },
    {
      title: "Install WAF on unprotected assets",
      url: `/assets?view=${toBase64AssetsView({
        name: "",
        filters: [
          {
            column: "waf_exists",
            value: "False",
            condition: "is",
            order: 0,
            next_condition: "and",
          },
          {
            column: "status",
            value: "live",
            condition: "is",
            order: 0,
            next_condition: "and",
          },
        ],
      })}`,
    },
  ],
  open_avg_age: [
    {
      title: "Fix findings unresolved for the longest time",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), ordering: "created_at" })}`,
    },
  ],
  mttr: [
    {
      title: "Fix findings unresolved for the longest time",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), ordering: "created_at" })}`,
    },
  ],
  open_findings_percent: [
    {
      title: "Resolve critical findings",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), overall_risk: "4" })}`,
    },
    {
      title: "Resolve findings affecting multiple assets",
      url: `/findings?filters=${objectToBase64({ status: OPEN_STATUSES.join(","), widespread: true })}`,
    },
  ],
};

export function getTooltip(
  context: any,
  theme: any,
  chartType: "doughnut" | "bar" | "radar",
  selectedMetric?: string
) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip" + chartType);

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip" + chartType;
    tooltipEl.innerHTML = "<table></table>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);
    let innerHtml = "<thead>";

    titleLines.forEach(function (title: string, i: number) {
      const colors = tooltipModel.labelColors[i];
      let style = "background:" + colors.backgroundColor;
      style += "; border-color:" + colors.borderColor;
      style += "; color:" + colors.backgroundColor;
      style += "; margin-right:8px; border-radius: 50%";
      const circle =
        chartType !== "radar"
          ? '<div><span class="circle" style="' + style + '">00</span>'
          : "";
      innerHtml +=
        `<div style="color:${theme.textSecondary}">` +
        circle +
        `${selectedMetric} for ${title} Severity` +
        "</div>";
    });
    innerHtml += "</thead><hr><tbody>";
    bodyLines.forEach((body: string, i: number) => {
      const colors = tooltipModel.labelColors[i];
      let style = "background:" + colors.borderColor;
      style += "; border:1px solid " + colors.borderColor;
      style += "; color:" + colors.borderColor;
      style += "; margin-right:8px; border-radius: 50%; font-size: 12px";

      const circle =
        chartType === "radar" ? '<span style="' + style + '">00</span>' : "";
      const percentSign = chartType === "radar" ? "%" : "";

      innerHtml +=
        `<tr><td style="color:${theme.textSecondary}">` +
        circle +
        body +
        " Days" +
        percentSign;
      innerHtml += `</td></tr>`;
    });
    innerHtml += "</tbody>";

    let tableRoot = tooltipEl.querySelector("table");
    //@ts-ignore
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  //@ts-ignore
  const bodyFont = "poppins";

  // Display, position, and set styles for font
  tooltipEl.style.opacity = "1";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.font = bodyFont;
  tooltipEl.style.padding = "8px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.background = theme.bg2;
  // tooltipEl.style.color = theme.textSub;
  tooltipEl.style.border = `1px solid ${theme.separation}`;
  tooltipEl.style.borderRadius = "0 16px 16px 16px";
  tooltipEl.style.fontSize = "14px";
  tooltipEl.style.transition = "0.4s ease-in-out";
}

export const isScoreMetric = (selectedMetric: string): boolean =>
  ["risk_score", "coverage_score"].includes(selectedMetric);

type Props = {
  timeframe: SingleValue<Option>;
  selectedMetric: OverTimeMetric;
  overTimeData?: any[];
  industryAvgData: any;
  benchmarkData?: secBenchmarkMetrics;
  isFetching: boolean;
};

export const BenchmarkOverTime = ({
  timeframe,
  selectedMetric,
  benchmarkData,
  overTimeData,
  industryAvgData,
  isFetching,
}: Props) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { riskColors } = useRiskColors({});
  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;

  // LINE CHART DATA
  const industryAvg =
    benchmarkData?.[selectedMetric as keyof secBenchmarkMetrics].avg;

  let datasets: LineChartDataset[] = !!overTimeData?.length
    ? isScoreMetric(selectedMetric)
      ? [
          {
            borderColor: theme.primary,
            data: overTimeData?.map((d) => d.value),
            label: selectedMetric,
          },
        ]
      : [
          {
            borderColor: riskColors.low,
            data: overTimeData?.map((d) => d.totals?.[1]),
            label: "Low",
          },
          {
            borderColor: riskColors.medium,
            data: overTimeData?.map((d) => d.totals[2]),
            label: "Medium",
          },
          {
            borderColor: riskColors.high,
            data: overTimeData?.map((d) => d.totals[3]),
            label: "High",
          },
          {
            borderColor: riskColors.critical,
            data: overTimeData?.map((d) => d.totals[4]),
            label: "Critical",
          },
        ]
    : [];

  !!overTimeData?.length &&
    datasets.push({
      borderColor: theme.black500,
      data: overTimeData.map((d) => industryAvg || 0),
      label: "Industry Avg",
    });

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false, // Hides the grid for the x-axis
        },
      },
      x: {
        grid: {
          display: false, // Hides the grid for the x-axis
        },
      },
    },
    elements: {
      point: {
        radius: 0, // Hides the points for all datasets
      },
    },
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        backgroundColor: theme.chartsTooltip,
        bodyColor: theme.textBody,
        titleColor: theme.textHeader,
        usePointStyle: true,
        cornerRadius: 0,
        boxHeight: 7,
        boxPadding: 12,
      },
    },
  };

  // BAR CHART DATA
  const lastOverTimeItem = overTimeData?.[overTimeData.length - 1]?.totals;
  const barChartData = {
    labels: lastOverTimeItem
      ? Object.keys(riskColors)
          .reverse()
          .map((k) => k.charAt(0).toUpperCase() + k.slice(1))
      : [],
    datasets: lastOverTimeItem
      ? [
          {
            label: titleMap[selectedMetric],
            data: [
              lastOverTimeItem[4],
              lastOverTimeItem[3],
              lastOverTimeItem[2],
              lastOverTimeItem[1],
            ],
            backgroundColor: Object.values(riskColors).reverse(),
          },
          {
            label: "Industry Average",
            data: [
              industryAvgData[selectedMetric][4],
              industryAvgData[selectedMetric][3],
              industryAvgData[selectedMetric][2],
              industryAvgData[selectedMetric][1],
            ],
            backgroundColor: theme.black500,
          },
        ]
      : [],
  };

  const barChartOptions: BarChartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        mode: "point",
        external: (context: any) =>
          getTooltip(context, theme, "bar", titleMap[selectedMetric]),
        usePointStyle: true,
      },
    },
    indexAxis: "x",
    elements: {
      bar: {
        borderRadius: 25,
        borderSkipped: false,
        borderWidth: 1,
        inflateAmount: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.textSecondary,
        },
        grid: { display: false },
      },
      y: {
        grid: { display: false },
        ticks: { stepSize: 1 },
      },
    },
  };

  return (
    <Flex gap="32px" flexWrap={isSmallScreen && !isScoreMetric}>
      <Flex align="center" w100 gap="32px">
        {!isScoreMetric(selectedMetric) && !!overTimeData && (
          <Flex column>
            <HeaderSub>{titleMap[selectedMetric]} by Severity</HeaderSub>
            <Flex align="center" style={{ height: "256px" }} gap="12px">
              <Flex style={{ width: "400px" }}>
                <Bar
                  height={190}
                  data={barChartData}
                  options={barChartOptions}
                />
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex
          column
          gap="24px"
          style={{ width: isScoreMetric(selectedMetric) ? "100%" : "70%" }}
        >
          <HeaderSub>{titleMap[selectedMetric]} Over Time</HeaderSub>
          {isFetching ? (
            <SkeletonLoading width="100%" height="236" />
          ) : (
            <LineChart
              datasets={datasets}
              labels={
                overTimeData?.map((item) =>
                  parseInt(timeframe?.value as string) > 170 * DAY_MS // 6 months
                    ? new Date(item.date).toLocaleDateString(undefined, {
                        day: "numeric",
                        month: "long",
                      })
                    : new Date(item.date).toLocaleDateString()
                ) || []
              }
              height={236}
              dataTestId="over-time-risk-score-chart"
              options={lineChartOptions}
            />
          )}
        </Flex>
      </Flex>
      <Flex column gap="24px" style={{ width: "540px" }}>
        <HeaderSub>Actions</HeaderSub>
        {actionsMap[selectedMetric as keyof typeof actionsMap]?.map(
          (action, idx) => {
            const onClick = () => {
              Mixpanel.track(`Security Benchmark - click on action`);
              navigate(action.url);
            };
            return (
              <Flex column key={idx}>
                <Flex align="center" justify="between">
                  <BodyRegularHover onClick={onClick}>
                    {action.title}
                  </BodyRegularHover>
                  <IconButton iconName="chevronRight" onClick={onClick} />
                </Flex>
                <SeparatorHorizontal />
              </Flex>
            );
          }
        )}
      </Flex>
    </Flex>
  );
};
