import { Finding } from "../../../../types/Finding";
import { Section } from "../../../../components/elements/section/Section";
import {
  BodyBold,
  BodyRegular,
} from "../../../../components/elements/typography/Typography";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import { Icon } from "../../../../components/elements/icon/Icon";
import { cvssTooltip } from "../../../../shared/tooltipTexts";
import {
  getCvssScoreName,
  getCvssScoreNormalized,
} from "../../../../shared/helper";
import { getRiskColor } from "../../../../shared/findingsHelper";
import { light } from "../../../../shared/theme";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type Props = {
  finding: Finding | undefined | null;
  noSeparator?: boolean;
  width?: string;
  noTooltip?: boolean;
  withVector?: boolean;
  isReport?: boolean;
};

export const CVSSItem = (props: Props) => {
  const {
    finding,
    noSeparator = false,
    width,
    noTooltip,
    withVector,
    isReport = false,
  } = props;

  const theme = useContext(ThemeContext);

  const CVSSTitle = () => (
    <span className="d-flex align-items-center gap-8">
      CVSS Score
      {!noTooltip && (
        <Tooltip content={cvssTooltip}>
          <Icon
            name="info"
            color={isReport ? light.black700 : theme.black700}
            size={16}
          />
        </Tooltip>
      )}
    </span>
  );

  return (
    <Section
      title={<CVSSTitle />}
      noSeparator={noSeparator}
      width={width}
      isReport={isReport}
    >
      <BodyRegular style={{ color: isReport ? light.black800 : "" }}>
        {withVector ? (
          <BodyRegular className="me-1">
            <a
              href={`https://www.first.org/cvss/calculator/3.1#${finding?.cvss_vector}`}
              target="_blank"
              rel="noreferrer"
            >
              {finding?.cvss_vector?.split("/")[0]}
            </a>

            <BodyBold
              color={
                isReport
                  ? // @ts-ignore
                    light[
                      getRiskColor(
                        getCvssScoreNormalized(finding?.cvss_score || 0)
                      )
                    ]
                  : theme[
                      getRiskColor(
                        getCvssScoreNormalized(finding?.cvss_score || 0)
                      )
                    ]
              }
            >
              {getCvssScoreName(finding?.cvss_score || 0)}
            </BodyBold>
            <BodyRegular> ({finding?.cvss_score?.toFixed(1) || 0})</BodyRegular>
          </BodyRegular>
        ) : (
          finding?.cvss_score
        )}
      </BodyRegular>
    </Section>
  );
};
