import React, { useContext } from "react";
import { assetStatusOptions } from "../../../types/Asset";
import { ThemeContext } from "styled-components";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { components, SingleValue } from "react-select";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import { getAssetStatusIcon } from "../AssetUtils";
import { BodyRegular } from "../../../components/elements/typography/Typography";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";

type Props = {
  width: string;
  value?: SingleValue<Option>;
  onChange: (option: SingleValue<Option>) => void;
};

export const AssetStatusDropdown = ({ width, value, onChange }: Props) => {
  const isSuperuser = useIsSuperuser();
  const theme = useContext(ThemeContext);

  const SeverityOption = (props: any) => {
    const { Option } = components;

    return (
      <Option {...props}>
        <Flex align="center" gap="6px">
          <Icon
            name={getAssetStatusIcon(props.data.value)}
            color={
              props.data.value === "live" ? theme.greenPrimary : theme.black600
            }
            size={24}
          />
          <BodyRegular title={props.data.label} className="text-truncate">
            {props.data.label}
          </BodyRegular>
        </Flex>
      </Option>
    );
  };

  const CustomControl = (props: any) => {
    const { Control } = components;
    const initValue = props.getValue();
    const singleOption = initValue ? initValue[0] : null;
    return (
      <Control {...props} style={{ cursor: "pointer" }}>
        {singleOption && singleOption.value !== "Select status" ? (
          <Flex align="center" gap="2px" padding="0 0 0 12px">
            <Icon
              name={getAssetStatusIcon(singleOption.value)}
              color={
                singleOption.value === "live"
                  ? theme.greenPrimary
                  : theme.black600
              }
              size={24}
            />
            {props.children}
          </Flex>
        ) : (
          <>{props.children}</>
        )}
      </Control>
    );
  };

  return (
    <Dropdown
      size="medium"
      variant="border"
      dataTestId="bulk-status-dropdown"
      isMenuPositionFixed={true}
      width={width}
      options={assetStatusOptions.filter(
        (o) => isSuperuser || o.value !== "dead"
      )}
      placeholder="Select status"
      value={value}
      onChange={onChange}
      backgroundColor={theme.bg2}
      customComponents={{
        Option: SeverityOption,
        Control: CustomControl,
      }}
    />
  );
};
