export function downloadCSV(
  data: { [key: string]: any }[],
  filename: string = "data.csv"
): void {
  if (!data || data.length === 0) {
    console.error("No data to export.");
    return;
  }

  // Extract keys from the first object as CSV headers
  const headers = Object.keys(data[0]);

  // Generate CSV rows
  const rows = data.map((row) =>
    headers
      .map((header) => {
        const value = row[header];
        return typeof value === "string"
          ? `"${value.replace(/"/g, '""')}"`
          : value;
      })
      .join(",")
  );

  // Combine headers and rows
  const csvContent = [headers.join(","), ...rows].join("\n");

  // Create a Blob and trigger download
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
