// Tabs.tsx
import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { Flex } from "../../layouts/flex/Flex";

interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 0 0 8px 0;
  border: none;
  border-bottom: ${(props) =>
    props.active ? `4px solid ${props.theme.primary}` : "none"};
  color: ${(props) =>
    props.active ? props.theme.primary : props.theme.black700};
  font-weight: 600;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.1s;
  background-color: transparent;

  &:hover {
    color: ${(props) => props.theme.blue800};
    border-bottom: 4px solid ${(props) => props.theme.blue800};
  }
`;

// Props for Tabs Component
interface TabsProps {
  children: ReactNode;
  selectedInitialTab?: number;
}

// Tabs Component
export const Tabs: React.FC<TabsProps> = ({ children, selectedInitialTab }) => {
  const [activeIndex, setActiveIndex] = useState(selectedInitialTab || 0);

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <Flex column gap="16px">
      <Flex gap="24px" align="center">
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <TabButton
                key={index}
                active={index === activeIndex}
                onClick={() => handleTabClick(index)}
              >
                {child.props.title}
              </TabButton>
            );
          }
          return null;
        })}
      </Flex>

      {React.Children.map(children, (child, index) =>
        index === activeIndex && React.isValidElement(child) ? (
          <>{child.props.children}</>
        ) : null
      )}
    </Flex>
  );
};

// Props for Tab Component
interface TabProps {
  title: string;
}

// Tab Component (Child)
export const Tab = styled.div<TabProps>``;
