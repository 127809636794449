import { useSearchParams } from "react-router-dom";
import { useApiFindings } from "../../hooks/queries/findingContext";
import { STATUSES } from "../../types/Finding";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import { useApiMe } from "../../hooks/queries/meContext";
import { useQueryClient } from "@tanstack/react-query";

export const useReportData = () => {
  const { data: me } = useApiMe();
  const [searchParams] = useSearchParams();
  const projectsIdsStr = searchParams.get("projectsIds");
  const productsIdsStr = searchParams.get("productsIds");
  const projectsIds = projectsIdsStr?.split(",").map((id) => parseInt(id));
  const productsIds = productsIdsStr?.split(",").map((id) => parseInt(id));

  const { data: projects, isFetching: isFetchingProject } = useApiProjects({
    id: projectsIds,
  });

  const { data: findings, isFetching: isFetchingFindings } = useApiFindings(
    {
      page_size: 1000,
      project: projectsIds,
      products: productsIds,
      all_data: true,
      no_prefetch_assets: true,
      status: STATUSES.filter((s) => s !== "dismissed").join(","), // Exclude dismissed findings
      is_false_positive: false, // Exclude false positive findings
      is_pending: false,
      ordering: "-overall_risk",
    },
    !!projectsIds?.length && !!productsIds?.length
  );

  const isMultiTenantReport =
    me?.customer.is_multi_tenant &&
    projects?.filter((p) => !p.is_self_managed).length === 0;

  // Check if there are any background fetching requests
  const queryClient = useQueryClient();
  const isFetching = queryClient.isFetching();

  const isPageLoaded = !!(
    !!projects?.length &&
    !!findings?.length &&
    !isFetching &&
    !isFetchingFindings
  );

  const projectsNames = projects?.map((p) => p.name).join("-");

  return {
    projects,
    isFetchingProject,
    isPageLoaded,
    findings,
    isFetching,
    isFetchingFindings,
    isMultiTenantReport,
    projectsNames,
  };
};
