import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { light } from "../../../shared/theme";

export const MultiTenantDisclaimerPage = () => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{ pageBreakAfter: "always", paddingTop: "48px" }}
    >
      <HeaderSecondary color={light.blue800}>
        Disclaimer | Limitations on Disclosure and Use of This Report
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        While this report has been generated using OP Innovate Ltd's WASP's
        platform, the findings within have been entered by the client and do not
        necessarily reflect the views or opinions of OP Innovate Ltd. We strive
        to provide a reliable and user-friendly platform for generating reports,
        but it is important to note that OP Innovate Ltd is not responsible for
        the accuracy, completeness, or validity of the findings presented. The
        use of this report and the information contained within is at the
        client's sole risk.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        By using this report, the client acknowledges and agrees that OP
        Innovate Ltd shall not be held liable for any claims, losses, damages,
        or expenses arising from or related to the use of the findings in this
        report.
      </BodyRegular>
    </Flex>
  );
};

export const DisclaimerPage = () => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{ pageBreakAfter: "always", paddingTop: "48px" }}
    >
      <HeaderSecondary color={light.blue800}>
        Disclaimer | Limitations on Disclosure and Use of This Report
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        This report contains information concerning our client request. It is
        recommended that special precautions be taken to protect the
        confidentiality of both this document and the information contained
        herein.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        Compromise assessment is a process based on past experiences, current
        available information, and known threats. It should be understood that
        all information security systems, which by their nature are designed by
        and therefore dependent on human beings, are vulnerable to some degree.
        Therefore, while the author of this report will do his utmost in order
        to assess and consider the major security events of the environment or
        systems and analyze it, there can be no assurance that any exercise of
        this nature will identify all possible events or propose exhaustive and
        operationally viable recommendations to mitigate these exposures.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        In addition, the analysis set forth herein is based on the technologies
        and known threats as of the date of this report. As technologies and
        risks change over time, the events associated with the operation of
        methods, tactics, techniques and procedures described in this report, as
        well as the actions necessary to reduce the exposure to such
        vulnerabilities will also change. The author makes no undertaking to
        supplement or update this report on the basis of changed circumstances
        or facts of which will become aware after the date hereof, absent a
        specific written agreement to perform supplemental or updated analysis.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        COPYRIGHT © {new Date().getFullYear()} by OP Innovate Ltd. Any and all
        content included on this report is protected by copyright laws.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        Without derogating from the above you are authorized to copy, print,
        transmit and distribute the content of this report, or parts thereof,
        provided that:
      </BodyRegular>
      <BodyRegular color={light.black800}>
        • The content is used for internal and non-commercial purposes only and
        you may not copy, distribute, transmit, reuse, repost or otherwise
        display the content for public or commercial purposes; and
      </BodyRegular>
      <BodyRegular color={light.black800}>
        • You may not use the content for any purpose that is unlawful or
        prohibited, or conduct any other activity that creates a liability for
        OP Innovate Ltd. or otherwise may harm the goodwill of OP Innovate Ltd.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        Any reproduction of material from this report or portion thereof must
        include this notice in its entirety. This report shall only be held
        valid if presented in whole.
      </BodyRegular>
    </Flex>
  );
};
