import { useContext, useState } from "react";
import { RightPane } from "../../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";
import { HeaderSecondary } from "../../../../components/elements/typography/Typography";
import {
  ITimeframeOption,
  TimeframeDropdown,
} from "../../../findings/findingsCharts/TimeframeDropdown";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { RiskScoreOverview } from "./RiskScoreOverview";
import { RiskScoreOvertime } from "./RiskScoreOvertime";
import { TopRiskyIssues } from "./TopRiskyIssues";
import { DAY_MS } from "../../../../shared/consts";

type Props = {
  onClose: () => void;
};

export const RiskScorePane = ({ onClose }: Props) => {
  const theme = useContext(ThemeContext);
  const [timeframe, setTimeframe] = useState<ITimeframeOption>({
    label: "Last Year",
    value: 365 * DAY_MS,
  });
  return (
    <RightPane width={"800px"} onClose={onClose}>
      <Flex column gap="24px" padding="16px">
        <Flex align="center" justify="between" gap="16px" padding="0 16px">
          <Flex align="center" gap="8px">
            <Icon name="securityStatus" size={32} color={theme.redPrimary} />
            <HeaderSecondary>Risk Score</HeaderSecondary>
          </Flex>
          <TimeframeDropdown
            timeframe={timeframe}
            setTimeframe={setTimeframe}
            useDefaultOptions
          />
        </Flex>
        <SeparatorHorizontal />
        <Flex
          column
          gap="24px"
          style={{
            height: "calc(100vh - 130px)",
            overflowY: "auto",
          }}
          padding="0 16px"
        >
          <RiskScoreOverview timeframe={timeframe} />
          <RiskScoreOvertime timeframe={timeframe} />
          <SeparatorHorizontal />
          <TopRiskyIssues />
        </Flex>
      </Flex>
    </RightPane>
  );
};
