import { useContext } from "react";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import { Icon } from "../../../components/elements/icon/Icon";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Section } from "../../../components/elements/section/Section";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiAssetsTags } from "../../../hooks/queries/assetsContext";
import {
  Asset,
  AssetCloudProperties,
  AssetDomainProperties,
} from "../../../types/Asset";
import { AssetVerificationIndicator } from "../assetVerification/AssetVerificationIndicator";
import { AssetCoverageIndicator } from "./AssetCoverageScoreProgressBar";
import { ThemeContext } from "styled-components";
import {
  getAssetCoverageStatus,
  getAssetLastPt,
  getAssetNextPt,
  getPriorityLabel,
} from "../AssetUtils";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiProjectRequests } from "../../../hooks/queries/projectRequestsContext";
import { getDate, getDateTime } from "../../../shared/helper";
import { AssetGradeBadge } from "../assetsTable/AssetGradeBadge";
import { FindingsItem } from "../assetsTable/FindingsItem";
import { Box } from "../../../components/elements/box/Box";
import { Badge } from "reactour";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { AssetTech } from "./AssetTech";
import RelatedVulnerabilitiesBox from "./RelatedVulnerabilitiesBox";
import { IconButton } from "../../../components/elements/button/icon/IconButton";

type Props = {
  asset: Asset;
  onClose: () => void;
};

const AssetPaneMobile = (props: Props) => {
  const { asset, onClose } = props;
  const theme = useContext(ThemeContext);

  const { data: allTags } = useApiAssetsTags();
  const { data: projects } = useApiProjects();
  const { data: products } = useApiProducts();
  const { data: projectRequests } = useApiProjectRequests();

  const lastPT = asset ? getAssetLastPt(asset, projects || []) : undefined;
  const product = products?.find((p) => p.id === asset?.product);

  return (
    <RightPane onClose={onClose} width="100vw">
      <Flex
        column
        align="center"
        gap="24px"
        padding="24px"
        style={{
          height: "100vh",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <IconButton
          iconName="cancel"
          onClick={onClose}
          style={{ position: "absolute", left: "12px", top: "12px" }}
        />
        <HeaderSecondary>{asset.name}</HeaderSecondary>
        <Flex align="center" gap="16px">
          {asset?.coverage_score && (
            <>
              <AssetCoverageIndicator asset={asset} placement="bottom" />
              <SeparatorVertical height="16px" />
            </>
          )}

          <AssetVerificationIndicator asset={asset} onOpenModal={onClose} />
          <SeparatorVertical height="16px" />

          <TagsLine
            isEditable={false}
            selectedTags={asset?.tags || []}
            allTags={allTags || []}
            onTagRemove={(tag: string) => {}}
            onTagAdd={(tag: string) => {}}
          />
        </Flex>
        <Box style={{ width: "96vw" }}>
          <Flex column gap="24px">
            <Flex align="start" justify="between" w100>
              <Section title="ASM">
                <Flex align="center" gap="8px">
                  <Icon name="asm" size={24} color={theme.primary} />
                  <BodyBold>
                    {asset.is_asm_enabled ? "Enabled" : "Disabled"}
                  </BodyBold>
                </Flex>
              </Section>

              {asset.type === "cloud" ? (
                <Section title="Region" noSeparator>
                  <Flex align="center" gap="8px">
                    <Icon name="map" size={24} color={theme.primary} />
                    <BodyBold>
                      {(asset?.properties as AssetCloudProperties)?.region}
                    </BodyBold>
                  </Flex>
                </Section>
              ) : (
                <Section title="WAF" noSeparator>
                  <Flex align="center" gap="8px">
                    <Icon name="waf" size={24} color={theme.primary} />
                    <BodyBold>
                      {(asset?.properties as AssetDomainProperties)?.waf?.length
                        ? (asset?.properties as AssetDomainProperties)?.waf?.at(
                            0
                          )
                        : "None"}
                    </BodyBold>
                  </Flex>
                </Section>
              )}
            </Flex>

            <Flex align="start" justify="between" w100>
              <Section title="Next Scheduled PT">
                <Flex align="center" gap="8px">
                  <Icon name="calender" size={24} color={theme.primary} />

                  {getAssetCoverageStatus(
                    asset,
                    projects || [],
                    projectRequests || []
                  ) === "pt_scheduled" ? (
                    <BodyBold>
                      {getDate(
                        getAssetNextPt(asset, projects || [])?.toISOString() ||
                          ""
                      )}
                    </BodyBold>
                  ) : (
                    <BodyBold>Not scheduled</BodyBold>
                  )}
                </Flex>
              </Section>

              <Section title="Last PT" noSeparator>
                <Flex align="center" gap="8px">
                  <Icon name="calender" size={24} color={theme.primary} />
                  {asset && lastPT ? (
                    <BodyBold>{getDate(lastPT.toISOString() || "")}</BodyBold>
                  ) : (
                    <BodyBold>None</BodyBold>
                  )}
                </Flex>
              </Section>
            </Flex>

            <Flex align="start" justify="between" w100>
              <Section title="Product">
                <Flex align="center" gap="8px">
                  <BodyBold>{product?.name || "N/A"}</BodyBold>
                </Flex>
              </Section>

              <Section title="Environment" noSeparator>
                <Flex align="center" gap="8px">
                  <BodyBold>{asset.environment}</BodyBold>
                </Flex>
              </Section>
            </Flex>

            <Flex align="start" justify="between" w100>
              <Section title="Priority">
                <Flex align="center" gap="8px">
                  <BodyBold>{getPriorityLabel(asset.priority)}</BodyBold>
                </Flex>
              </Section>

              <Section title="Security Grade" noSeparator>
                <Flex align="center" gap="8px">
                  <AssetGradeBadge riskScore={asset.risk_score || 0} />
                </Flex>
              </Section>
            </Flex>

            <Flex align="start" justify="between" w100>
              <Section title="Discoverd On">
                <BodyRegular>{getDate(asset?.created_at) || "N/A"}</BodyRegular>
              </Section>

              <Section title="Findings" noSeparator>
                <FindingsItem
                  relatedFindings={asset.related_findings || []}
                  assetId={asset.id}
                />
              </Section>
            </Flex>

            <Flex align="start" justify="between" w100>
              <Section title="Type">
                <BodyRegular className="text-capitalize">
                  {asset?.type || "Domain"}
                </BodyRegular>
              </Section>

              {asset?.type === "cloud" ? (
                <Section title="Resource Name" noSeparator>
                  <BodyRegular
                    className="text-truncate w-100"
                    title={
                      (asset?.properties as AssetCloudProperties)
                        .resource_name || ""
                    }
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    {(asset?.properties as AssetCloudProperties).resource_name}
                  </BodyRegular>
                </Section>
              ) : (
                <Section title="Domain Name" noSeparator>
                  <BodyRegular
                    className="text-truncate w-100"
                    title={
                      (asset?.properties as AssetDomainProperties)
                        ?.domain_name || ""
                    }
                  >
                    {(asset?.properties as AssetDomainProperties)?.domain_name}
                  </BodyRegular>
                </Section>
              )}
            </Flex>
          </Flex>
        </Box>

        <Box style={{ width: "96vw" }}>
          <Flex column gap="24px">
            <Flex align="start" justify="between" w100>
              {asset?.type === "cloud" ? (
                <Section title="Resource Group">
                  <BodyRegular>
                    {(asset?.properties as AssetCloudProperties)
                      ?.resource_group || "N/A"}
                  </BodyRegular>
                </Section>
              ) : (
                <Section title="Webpage Title">
                  <BodyRegular>
                    {(asset?.properties as AssetDomainProperties)
                      ?.webpage_title || "N/A"}
                  </BodyRegular>
                </Section>
              )}

              {asset?.type === "cloud" ? (
                <Section title="Service">
                  <BodyRegular>
                    {(asset?.properties as AssetCloudProperties)?.service ||
                      "N/A"}
                  </BodyRegular>
                </Section>
              ) : (
                <Section title="Webserver" noSeparator>
                  <BodyRegular>
                    {(asset?.properties as AssetDomainProperties)?.webserver ||
                      "N/A"}
                  </BodyRegular>
                </Section>
              )}
            </Flex>

            <Flex align="start" justify="between" w100>
              {asset?.type === "cloud" ? (
                <Section title="Cloud Provider">
                  <BodyRegular>
                    {(asset?.properties as AssetCloudProperties)
                      ?.cloud_provider || "N/A"}
                  </BodyRegular>
                </Section>
              ) : (
                <Section title="Status Code">
                  <BodyRegular>
                    {(asset?.properties as AssetDomainProperties)
                      ?.status_code || "N/A"}
                  </BodyRegular>
                </Section>
              )}

              {asset?.type === "cloud" ? (
                <Section title="Resource ID">
                  <BodyRegular
                    className="text-truncate"
                    title={
                      (asset?.properties as AssetCloudProperties)
                        ?.resource_id || ""
                    }
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    {(asset?.properties as AssetCloudProperties)?.resource_id ||
                      "N/A"}
                  </BodyRegular>
                </Section>
              ) : (
                <Section title="Cname records" noSeparator>
                  <div className="d-flex flex-wrap gap-6 align-items-center">
                    {(asset?.properties as AssetDomainProperties)?.cnames?.map(
                      (cn) => (
                        <Badge
                          className="d-flex"
                          key={`cn-${cn}`}
                          style={{
                            maxWidth: "250px",
                            color: theme.blue700,
                            backgroundColor: theme.blue100,
                          }}
                        >
                          <LabelMedium className="text-truncate" title={cn}>
                            {cn}
                          </LabelMedium>
                        </Badge>
                      )
                    ) || <BodyRegular>N/A</BodyRegular>}
                  </div>
                </Section>
              )}
            </Flex>
            {asset?.type === "domain" && (
              <Flex align="start" justify="between" w100>
                <Section title="Open Ports">
                  <div className="d-flex flex-wrap gap-6 align-items-center">
                    {asset?.ports_data?.map((portData) => (
                      <Badge
                        key={`port-${portData.port_number}`}
                        style={{
                          backgroundColor: theme.blue100,
                          color: theme.blue700,
                        }}
                      >
                        {portData.port_number}
                      </Badge>
                    )) || <BodyRegular>N/A</BodyRegular>}
                  </div>
                </Section>
                <Section title="Resolved IP’s" noSeparator>
                  <div className="d-flex flex-wrap gap-6 align-items-center">
                    <BadgesLine
                      badges={
                        (asset?.properties as AssetDomainProperties)?.ip || []
                      }
                      variant="primary"
                      noWrap={false}
                      gap={4}
                    />
                  </div>
                </Section>
              </Flex>
            )}
            <Flex align="start" justify="between" w100>
              <Section title="registration date">
                <BodyRegular>
                  {asset?.registered_at
                    ? getDateTime(asset.registered_at)
                    : "N/A"}
                </BodyRegular>
              </Section>

              <Section title="last seen" noSeparator>
                <BodyRegular>
                  {asset?.last_seen ? getDateTime(asset.last_seen) : "N/A"}
                </BodyRegular>
              </Section>
            </Flex>
          </Flex>
        </Box>

        <AssetTech asset={asset} width="96vw" />
        <RelatedVulnerabilitiesBox asset={asset} />
      </Flex>
    </RightPane>
  );
};

export default AssetPaneMobile;
