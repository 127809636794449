import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { Meter } from "../../../components/elements/meter/Meter";
import { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";

type Props = {
  label: string;
  value?: number;
  optimum?: number;
  isDays?: boolean;
  isPercent?: boolean;
  isHigherBetter: boolean;
  isPositiveTrend?: boolean; // fix the trend to reflect high score is good
  onClick: () => void;
};

const BenchmarkMeterContainer = styled.div`
  border: 3px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding: 8px;
  height: 80px;
  &:hover {
    border: 3px solid ${(props) => props.theme.blue100};
    background: ${(props) => props.theme.blue100};
  }
`;

export const BenchmarkMeter = (props: Props) => {
  const {
    label,
    value,
    optimum,
    isDays,
    isPercent,
    isHigherBetter,
    isPositiveTrend,
    onClick,
  } = props;

  const theme = useContext(ThemeContext);

  const [val, setVal] = useState<number>(0);
  const [opt, setOpt] = useState<number>(0);
  const [max, setMax] = useState<number>(0);

  useEffect(() => {
    if (
      // Allow value to be 0
      optimum === null ||
      optimum === undefined ||
      value === null ||
      value === undefined
    )
      return;
    setVal(value);
    setOpt(optimum);
    // if percents - max is 100% else take higher value
    setMax(isPercent ? 100 : optimum > value ? optimum + 1 : value);
  }, [optimum, value, isPercent]);

  return (
    <BenchmarkMeterContainer onClick={onClick}>
      <Flex column style={{ width: "300px", paddingRight: "24px" }}>
        <HeaderSub color={theme.textSub}>{label}</HeaderSub>
        <Flex align="center" justify="between">
          <HeaderSecondary>
            {value}
            {isDays && " Days"}
            {isPercent && "%"}
          </HeaderSecondary>
          <BodyRegular color={theme.textSub}>
            {isHigherBetter ? "Higher " : "Lower "}
            is better
          </BodyRegular>
        </Flex>
      </Flex>
      <Meter
        value={val}
        optimum={opt}
        max={max}
        isDays={isDays}
        isPercent={isPercent}
        isPositiveTrend={isPositiveTrend}
      />
    </BenchmarkMeterContainer>
  );
};
