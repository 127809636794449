import React, { Dispatch, SetStateAction } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { RequiredField } from "../../../components/elements/requiredField/RequiredField";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import {
  AssetCloudProperties,
  AssetEdit,
  AssetSource,
  cloudProviderOptions,
} from "../../../types/Asset";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { useApiTestAssetConnection } from "../../../hooks/queries/assetsContext";
import useToastContext from "../../../hooks/toastHook";

type Props = {
  formValues: AssetEdit;
  setFormValues: Dispatch<SetStateAction<AssetEdit>>;
  disabled?: boolean;
};
const awsLabelWidth = "150px";
const azureLabelWidth = "200px";

export const isValidCloudIntegration = (formValues: AssetEdit) => {
  if (formValues.type !== "cloud") return true;
  if (!formValues.source) return false;
  if (
    formValues.source === "aws" &&
    (!formValues.cloud_credentials?.access_key_id ||
      !formValues.cloud_credentials?.secret_access_key)
  )
    return false;

  if (
    formValues.source === "azure" &&
    (!formValues.cloud_credentials?.client_id ||
      !formValues.cloud_credentials?.client_secret ||
      !formValues.cloud_credentials?.tenant_id)
  )
    return false;
  return true;
};

export const AssetCloudForm = ({
  formValues,
  setFormValues,
  disabled,
}: Props) => {
  const addToast = useToastContext();

  const { mutate: testConnection, isLoading: isTestingConnection } =
    useApiTestAssetConnection();

  const handleTestCloudConnection = () => {
    testConnection({
      cloud_credentials: formValues.cloud_credentials || {},
      source: formValues.source || "aws",
      onSuccessCallback(testResponse) {
        setFormValues((prev) => ({
          ...prev,
          name: testResponse.resource_id,
          properties: {
            ...prev?.properties,
            cloud_provider: formValues.source,
            service: testResponse.service,
            resource_id: testResponse.resource_id,
            resource_name: testResponse.resource_name,
          },
        }));
        addToast({
          type: "success",
          message: `Connection test successful, resource ID: ${testResponse.resource_id}`,
        });
      },
      onErrorCallback(error) {
        addToast({
          type: "error",
          message: `Connection test failed - Error: ${error.message}`,
        });
      },
    });
  };

  return (
    <Flex w100 column gap="8px">
      <Flex gap="8px" align="center">
        <LabelRegular>Cloud Provider</LabelRegular>
        <RequiredField />
      </Flex>
      <Dropdown
        dataTestId="cloud-provider-options"
        placeholder="Select cloud provider"
        options={cloudProviderOptions}
        onChange={(opt) => {
          if (opt)
            setFormValues((prev) => ({
              ...prev,
              source: opt.value as AssetSource,
              properties: {
                ...prev?.properties,
                cloud_provider: opt.value as string,
                service: opt.value === "aws" ? "AwsAccount" : "account",
              },
            }));
        }}
        value={cloudProviderOptions?.find(
          (opt) => formValues.source === opt.value
        )}
        variant="border"
      />
      {formValues.source === "aws" && (
        <>
          <Flex align="center" gap="8px" w100>
            <LabelRegular
              style={{
                width: awsLabelWidth,
              }}
            >
              Access Key ID
            </LabelRegular>
            <InputText
              isPassword
              width="100%"
              value={formValues.cloud_credentials?.access_key_id}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  cloud_credentials: {
                    ...(prev?.cloud_credentials || {}),
                    access_key_id: e.target.value,
                  },
                }))
              }
            />
          </Flex>

          <Flex align="center" gap="8px" w100>
            <LabelRegular
              style={{
                width: awsLabelWidth,
              }}
            >
              Secret Access Key
            </LabelRegular>
            <InputText
              isPassword
              width="100%"
              value={formValues.cloud_credentials?.secret_access_key}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  cloud_credentials: {
                    ...(prev?.cloud_credentials || {}),
                    secret_access_key: e.target.value,
                  },
                }))
              }
            />
          </Flex>
          <SecondaryButton
            label="Test Connection"
            onClick={handleTestCloudConnection}
            size="small"
            inProgress={isTestingConnection}
            disabled={
              isTestingConnection ||
              !isValidCloudIntegration(formValues) ||
              !formValues.source ||
              disabled
            }
          />
        </>
      )}
      {formValues.source === "azure" && (
        <>
          <Flex align="center" gap="8px" w100>
            <LabelRegular
              style={{
                width: azureLabelWidth,
              }}
            >
              Client (Application) ID
            </LabelRegular>
            <InputText
              isPassword
              width="100%"
              value={formValues.cloud_credentials?.client_id}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  cloud_credentials: {
                    ...(prev?.cloud_credentials || {}),
                    client_id: e.target.value,
                  },
                }))
              }
            />
          </Flex>

          <Flex align="center" gap="8px" w100>
            <LabelRegular
              style={{
                width: azureLabelWidth,
              }}
            >
              Tenant (Directory) ID
            </LabelRegular>
            <InputText
              isPassword
              width="100%"
              value={formValues.cloud_credentials?.tenant_id}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  cloud_credentials: {
                    ...(prev?.cloud_credentials || {}),
                    tenant_id: e.target.value,
                  },
                }))
              }
            />
          </Flex>

          <Flex align="center" gap="8px" w100>
            <LabelRegular
              style={{
                width: azureLabelWidth,
              }}
            >
              Client Secret
            </LabelRegular>
            <InputText
              isPassword
              width="100%"
              value={formValues.cloud_credentials?.client_secret}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  cloud_credentials: {
                    ...(prev?.cloud_credentials || {}),
                    client_secret: e.target.value,
                  },
                }))
              }
            />
          </Flex>
          <SecondaryButton
            label="Test Connection"
            onClick={handleTestCloudConnection}
            size="small"
            inProgress={isTestingConnection}
            disabled={
              isTestingConnection ||
              !isValidCloudIntegration(formValues) ||
              !formValues.source ||
              disabled
            }
          />
        </>
      )}
      {formValues.name && (
        <Flex w100 column gap="8px">
          <Flex align="center" gap="4px">
            <LabelRegular style={{ width: awsLabelWidth }}>
              Resource ID:
            </LabelRegular>
            <BodyRegular>
              {(formValues.properties as AssetCloudProperties)?.resource_id ||
                "N/A"}
            </BodyRegular>
          </Flex>
          <Flex align="center" gap="4px">
            <LabelRegular style={{ width: awsLabelWidth }}>
              Resource Name:
            </LabelRegular>
            <BodyRegular>
              {(formValues.properties as AssetCloudProperties)?.resource_name ||
                "N/A"}
            </BodyRegular>
          </Flex>
          <Flex align="center" gap="4px">
            <LabelRegular style={{ width: awsLabelWidth }}>
              Service:
            </LabelRegular>
            <BodyRegular>
              {(formValues.properties as AssetCloudProperties)?.service ||
                "N/A"}
            </BodyRegular>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
