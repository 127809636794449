import { IconProps } from "./WaspIcon";

export function DashboardMenuIcon(props: IconProps) {
  const size = props.size || 32;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_12853_87481"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="24"
        height="25"
      >
        <path d="M0 -0.00332451H24V23.9967H0V-0.00332451Z" fill="white" />
      </mask>
      <g mask="url(#mask0_12853_87481)">
        <path
          d="M10.3125 10.3085C10.3125 11.344 9.47301 12.1835 8.4375 12.1835H2.8125C1.77698 12.1835 0.9375 11.344 0.9375 10.3085V2.80853C0.9375 1.77302 1.77698 0.933533 2.8125 0.933533H8.4375C9.47301 0.933533 10.3125 1.77302 10.3125 2.80853V10.3085Z"
          stroke={props.color || "currentColor"}
          stroke-width="1.875"
          stroke-miterlimit="10"
        />
        <path
          d="M23.0625 21.1836C23.0625 22.2191 22.223 23.0586 21.1875 23.0586H15.5625C14.527 23.0586 13.6875 22.2191 13.6875 21.1836V13.6836C13.6875 12.648 14.527 11.8086 15.5625 11.8086H21.1875C22.223 11.8086 23.0625 12.648 23.0625 13.6836V21.1836Z"
          stroke={props.color || "currentColor"}
          stroke-width="1.875"
          stroke-miterlimit="10"
        />
        <path
          d="M10.3125 21.1836C10.3125 22.2191 9.47301 23.0586 8.4375 23.0586H2.8125C1.77698 23.0586 0.9375 22.2191 0.9375 21.1836V17.4336C0.9375 16.398 1.77698 15.5586 2.8125 15.5586H8.4375C9.47301 15.5586 10.3125 16.398 10.3125 17.4336V21.1836Z"
          stroke={props.color || "currentColor"}
          stroke-width="1.875"
          stroke-miterlimit="10"
        />
        <path
          d="M23.0625 6.55856C23.0625 7.59408 22.223 8.43356 21.1875 8.43356H15.5625C14.527 8.43356 13.6875 7.59408 13.6875 6.55856V2.80856C13.6875 1.77305 14.527 0.933564 15.5625 0.933564H21.1875C22.223 0.933564 23.0625 1.77305 23.0625 2.80856V6.55856Z"
          stroke={props.color || "currentColor"}
          stroke-width="1.875"
          stroke-miterlimit="10"
        />
      </g>
    </svg>
  );
}
