import { useApiProjectRequests } from "../../../hooks/queries/projectRequestsContext";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_LAPTOP_WIDTH } from "../../../shared/consts";
import {
  Asset,
  AssetCoverageStatus,
  AssetDomainProperties,
} from "../../../types/Asset";
import { getAssetCoverageStatus } from "../AssetUtils";
import { CoverageItem } from "./CoverageItem";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  asset: Asset;
  isDead?: boolean;
};

export const AssetCoverage = (props: Props) => {
  const { asset, isDead } = props;

  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_LAPTOP_WIDTH;

  const { data: projects } = useApiProjects();
  const { data: projectsRequest } = useApiProjectRequests();

  const hasWaf =
    asset.type === "domain"
      ? ((asset.properties as AssetDomainProperties)?.waf?.length || 0) > 0
      : true;

  const assetStatus = getAssetCoverageStatus(
    asset,
    projects || [],
    projectsRequest || []
  );

  const getPTStatusTooltip = (assetStatus: AssetCoverageStatus) => {
    if (assetStatus === "pt_scheduled")
      return '"Scheduled Penetration Test Inclusion" - This asset is scheduled to be tested as part of an upcoming penetration test.';
    if (assetStatus === "pending_pt_request")
      return '"Pending Penetration Test Request" - A request has been made to include this asset in a future penetration test, but the test has not yet been scheduled.';
    if (assetStatus === "ongoing_pt")
      return '"Ongoing Penetration Testing" - This asset is currently being tested as part of an ongoing penetration test.';
    if (assetStatus === "pt_in_past_6_months")
      return '"Recently Completed Penetration Test" - This asset was successfully tested as part of a penetration test that was completed within the past 6 months.';
    return '"No Upcoming Penetration Tests" - No future penetration tests are currently scheduled or requested for this asset.';
  };

  return (
    <Flex align="center" gap={isLaptop ? "" : "8px"}>
      <CoverageItem
        dataTestId={`coverage-item-calender-${asset.id}`}
        iconName="calender"
        tooltip={getPTStatusTooltip(assetStatus)}
        isOn={assetStatus !== "no_scheduled_or_requested_pt"}
        isDead={isDead}
      />

      <CoverageItem
        dataTestId={`coverage-item-asm-${asset.id}`}
        iconName="asm"
        tooltip={`ASM (Attack surface mapping) ${
          asset.is_asm_enabled ? "Enabled" : "Disabled"
        }`}
        isOn={asset.is_asm_enabled}
        isDead={isDead}
      />

      <CoverageItem
        dataTestId={`coverage-item-waf-${asset.id}`}
        iconName="waf"
        tooltip={
          hasWaf
            ? "WAF (Web application firewall) detected"
            : "No WAF (Web application firewall) Detected"
        }
        isOn={hasWaf}
        isDead={isDead}
      />
    </Flex>
  );
};
