import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { useApiFindingsCounts } from "../../hooks/queries/findingContext";
import { Box } from "../../components/elements/box/Box";
import { SeparatorVertical } from "../../components/elements/separators/SeparatorVertical";
import { HeaderMain } from "../../components/elements/typography/Typography";
import ActionItems from "./topBlocks/ActionItems";
import { FindingsFilterLine } from "./filters/filterLine/FindingsFilterLine";
import FindingsOverview from "./topBlocks/FindingsOverview";
import { FindingsTable } from "./findingsTable/FindingsTable";
import { Option } from "../../components/elements/dropdowns/Dropdown";
import { SingleValue } from "react-select";
import { useSearchParams } from "react-router-dom";
import { Finding } from "../../types/Finding";
import { FindingPane } from "./findingPane/FindingPane";
import { ProjectPane } from "../projects/projectPane/ProjectPane";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import { AffectedAssetsPane } from "./findingDetails/AffectedAssetsPane";
import { SecondaryButton } from "../../components/elements/button/secondary/SecondaryButton";
import { Flex } from "../../components/layouts/flex/Flex";
import { InputText } from "../../components/elements/input/textInput/InputText";
import { useDebounceCallback, useScreenWidth } from "../../hooks/utilsHooks";
import { FindingsBulkActions } from "./findingsTable/FindingsBulkActions";
import { useTrackPage } from "../../hooks/trackingHooks";
import { base64ToObject, objectToBase64 } from "../../shared/helper";
import { FindingsFiltersPane } from "./filters/FindingsFiltersPane";
import { Tours } from "../../tours/Tours";
import { FindingsSteps } from "../../tours/FindingsTourSteps";
import { ExportList } from "./ExportList";
import { AlertsBanners } from "../alertsBanners/AlertsBanners";
import { Mixpanel } from "../../shared/mixpanel";
import { useIsSuperuser } from "../../hooks/useIsSuperuser";
import { FindingsBulkCounters } from "./findingsTable/FindingsBulkCounters";
import { Timeframe, findingsTimeOptions } from "./filters/Timeframe";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { useApiMe } from "../../hooks/queries/meContext";
import { useNavigate } from "react-router-dom";
import { SCREEN_MOBILE_WIDTH } from "../../shared/consts";
import { FindingsQuickFilter } from "./FindingsQuickFilter";
import { ReportsGenV2 } from "../reports/ReportsGenV2";

export type Filters = { [key: string]: any };

export const findingsDefaultFilters: Filters = {
  ordering: "-overall_risk",
  timeframe: 0,
};

export const findingsStartingFilters: Filters = {
  ordering: "-overall_risk",
  timeframe: 0,
  status: "presented,in_progress,re_test",
};

export const tabsFiltersMap: { [key: string]: any } = {
  open: { status: "presented,in_progress,re_test", is_pending: false },
  closed: { status: "fixed,dismissed,acceptable_risk", is_pending: false },
  dismissed: { status: "dismissed", is_pending: false },
  all: { status: "", is_pending: false },
  pending: { is_pending: true },
};

const Findings = () => {
  useTrackPage("Findings");
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const navigate = useNavigate();
  const { data: me } = useApiMe();
  const isSuperuser = useIsSuperuser();

  const { data: projects } = useApiProjects();
  const [searchParams, setSearchParams] = useSearchParams();

  // Decode filters from url
  const urlFiltersBase64 = searchParams.get("filters");
  const urlFilters: Filters =
    urlFiltersBase64 && base64ToObject(urlFiltersBase64);

  // Set timeframe if given in url
  const currentTimeframe = urlFilters?.timeframe
    ? `${urlFilters.timeframe}`
    : "";
  const initialTimeframe = currentTimeframe
    ? findingsTimeOptions.map((opt) => opt.value).includes(currentTimeframe)
      ? // get timeframe option if exists
        findingsTimeOptions.find(
          (opt) => opt.value === `${urlFilters.timeframe}`
        )
      : //  if timeframe value not on options set it to custom
        { label: "Custom", value: `${urlFilters.timeframe}` }
    : // keep it null if no timeframe filter
      null;

  const [timeframe, setTimeframe] = useState<SingleValue<Option>>(
    initialTimeframe || findingsTimeOptions[5]
  );

  const [filters, setFilters] = useState<Filters>(
    !!urlFilters ? urlFilters : findingsStartingFilters
  );

  const setFiltersUrl = (filters: Filters) => {
    searchParams.set("filters", objectToBase64(filters));
    setSearchParams(searchParams);
    if (filters) setFilters(filters);
  };

  const [selectedTab, setSelectedTab] = useState("open");

  const handleStatusTabClick = (tab: string) => {
    setSelectedTab(tab);
    const newFilters64 = searchParams.get("filters");
    let newFilters = newFilters64 ? base64ToObject(newFilters64) : {};
    setFilters({ ...newFilters, ...tabsFiltersMap[tab] });
  };

  useEffect(() => {
    // Decode filters from url
    const urlFiltersBase64 = searchParams.get("filters");
    const urlFilters: Filters =
      urlFiltersBase64 && base64ToObject(urlFiltersBase64);
    if (urlFilters) setFilters(urlFilters);
  }, [searchParams]);

  // Get Project from url
  const projectId = parseInt(searchParams.get("projectId") || "0");
  const initialProject =
    !!projectId && projects
      ? projects.find((p) => p.id === projectId)
      : undefined;

  const [showFiltersPane, setShowFiltersPane] = useState(false);
  const [filterSearch, setFilterSearch] = useState("");
  const [showProjectPane, setShowProjectPane] = useState(false);
  const [showFiltersLine, setShowFiltersLine] = useState(false);
  const [hideFiltersLine, setHideFiltersLine] = useState(true);
  const [showReportsGen, setShowReportsGen] = useState(false);
  const [showAffectedAssetsPane, setShowAffectedAssetsPane] = useState(false);
  // finding shown on finding or project pane
  const [selectedFinding, setSelectedFinding] = useState<Finding | null>(null);
  const [showFindingPane, setShowFindingPane] = useState(false);
  // findings selected to be edited on bulk
  const [selectedFindings, setSelectedFindings] = useState<Finding[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { data: findingsCounts, isFetching: isFetchingFindingsCounts } =
    useApiFindingsCounts(filters || findingsDefaultFilters);

  const onSelectedProject = (finding: Finding) => {
    Mixpanel.track("Findings Table - Open Project Pane");
    setSelectedFinding(finding);
    setShowProjectPane(true);
    setSearchParams({ ...searchParams, projectId: `${finding.project}` });
  };

  const onSelectedFinding = (finding: Finding) => {
    Mixpanel.track("Findings Table - Open Finding Pane");
    setSelectedFinding(finding);
    setShowFindingPane(true);
  };

  const onCloseFindingPane = () => {
    setSelectedFinding(null);
    setShowFindingPane(false);
  };

  const handleTimeframeChange = (option: SingleValue<Option>) => {
    Mixpanel.track("Change time frame", { selectedTime: option?.label });
    setTimeframe(option);
    searchParams.set(
      "filters",
      objectToBase64({ ...filters, timeframe: option?.value })
    );
    setSearchParams(searchParams);
  };

  const handleSearch = useDebounceCallback((e) => {
    Mixpanel.track("Findings Table - Search");
    if (!e.target.value.length) {
      const newFilters = { ...filters };
      delete newFilters.title;
      searchParams.set("filters", objectToBase64(newFilters));
    } else {
      searchParams.set(
        "filters",
        objectToBase64({ ...filters, title: e.target.value })
      );
    }
    setSearchParams(searchParams);
  });

  return (
    <>
      <div
        className="d-flex align-items-start flex-row gap-16 justify-content-between"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <Tours steps={FindingsSteps(theme)} />
        <div
          className="d-flex flex-column w-100 gap-24"
          data-tut="findings-page"
        >
          <AlertsBanners />
          <div className="d-flex justify-content-between ">
            <div className="d-flex align-items-center">
              <HeaderMain
                style={{ paddingRight: "24px" }}
                data-testid="findings-title"
              >
                Findings
              </HeaderMain>
              <SeparatorVertical height="24px" />
              <Timeframe
                filters={filters}
                handleCustomDate={() => {
                  setShowFiltersPane(true);
                  setFilterSearch("created");
                }}
                handleTimeframeChange={handleTimeframeChange}
                timeframe={timeframe}
              />
            </div>
            <Flex align="center" gap="8px">
              <SecondaryButton
                label="Export Reports"
                iconName="export"
                size="medium"
                onClick={() => {
                  Mixpanel.track("Export Reports - Open");
                  setShowReportsGen(true);
                }}
              />
              {(isSuperuser || me?.customer.is_multi_tenant) && (
                <MainButton
                  label={"Create Finding"}
                  size="medium"
                  iconName="findings"
                  onClick={() => {
                    navigate("/research/finding/create");
                  }}
                />
              )}
            </Flex>
          </div>

          <div data-tut="findings-filters">
            <FindingsFilterLine
              filters={filters}
              setFiltersUrl={setFiltersUrl}
              setShowFiltersPane={setShowFiltersPane}
              showFiltersLine={showFiltersLine}
              hideFiltersLine={hideFiltersLine}
              setHideFiltersLine={setHideFiltersLine}
              onClearAll={() => setSelectedTab("all")}
            />
          </div>

          <Flex gap="24px" w100>
            <Box
              style={{ borderTopRightRadius: "0px", minWidth: "300px" }}
              data-tut="findings-overview"
            >
              <FindingsOverview
                findingsCounts={findingsCounts}
                isFetching={!findingsCounts && isFetchingFindingsCounts}
                setFilters={setFilters}
                filters={filters}
              />
            </Box>

            <Box
              data-tut="findings-action"
              style={{
                overflow: "auto",
                flexGrow: 1,
                padding: isMobile ? "8px" : "24px",
              }}
            >
              <ActionItems
                findingsCounts={findingsCounts}
                isFetching={!findingsCounts && isFetchingFindingsCounts}
                onClickCallback={() => setSelectedTab("open")}
              />
            </Box>
          </Flex>
          <div data-tut="findings-table">
            <Flex column style={{ marginBottom: "24px" }}>
              <Flex align="center" flexWrap gap="16px">
                <Flex align="center" gap="8px">
                  <FindingsQuickFilter
                    label="All"
                    onClick={handleStatusTabClick}
                    tabKey="all"
                    selectedTab={selectedTab}
                    filters={filters}
                  />
                  <FindingsQuickFilter
                    label="Open"
                    onClick={handleStatusTabClick}
                    tabKey="open"
                    selectedTab={selectedTab}
                    filters={filters}
                  />
                  <FindingsQuickFilter
                    label="Closed"
                    onClick={handleStatusTabClick}
                    tabKey="closed"
                    selectedTab={selectedTab}
                    filters={filters}
                  />
                  <FindingsQuickFilter
                    label="Dismissed"
                    onClick={handleStatusTabClick}
                    tabKey="dismissed"
                    selectedTab={selectedTab}
                    filters={filters}
                  />

                  {isSuperuser && (
                    <FindingsQuickFilter
                      label="Pending"
                      onClick={handleStatusTabClick}
                      tabKey="pending"
                      selectedTab={selectedTab}
                      filters={filters}
                    />
                  )}
                </Flex>

                <Flex align="center" style={{ marginLeft: "auto" }}>
                  <ExportList
                    filters={filters}
                    isSelectAll={isSelectAll}
                    selectedFindings={selectedFindings}
                  />
                  <SeparatorVertical height="24px" />
                  <div style={{ width: "265px", marginLeft: "24px" }}>
                    <InputText
                      dataTestId="findings-search-bar"
                      onChange={handleSearch}
                      placeholder="Search"
                      iconName="search"
                      style={{ backgroundColor: theme.bg2 }}
                      width={"265px"}
                      isClearable
                      onClear={() => handleSearch({ target: { value: "" } })}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex w100 gap="16px" align="center">
                <FindingsBulkCounters
                  selectedFindings={selectedFindings}
                  findingsCounts={findingsCounts}
                  isSelectAll={isSelectAll}
                  setIsSelectAll={(state) => setIsSelectAll(state)}
                  setSelectedFindings={(selected) =>
                    setSelectedFindings(selected)
                  }
                />
                <FindingsBulkActions
                  findings={selectedFindings}
                  isSelectAll={isSelectAll}
                  filters={filters}
                  resetSelected={() => setSelectedFindings([])}
                />
              </Flex>
            </Flex>
            <Box>
              <FindingsTable
                setIsFiltersPaneOpen={setShowFiltersPane}
                filters={filters}
                setFilters={setFilters}
                onSelectedProject={onSelectedProject}
                onSelectedFinding={onSelectedFinding}
                showColumnsEditorButton
                hasBackToTopButton
                onShowBackToTopButton={(status) => setShowFiltersLine(!!status)}
                isSelectable
                selectedFindings={selectedFindings}
                setSelectedFindings={setSelectedFindings}
                isSelectAll={isSelectAll}
                setIsSelectAll={setIsSelectAll}
              />
            </Box>
          </div>
        </div>
      </div>
      {showFindingPane && !showAffectedAssetsPane && (
        <FindingPane
          onClose={onCloseFindingPane}
          findingId={selectedFinding?.id}
          setShowAffectedAssetsPane={setShowAffectedAssetsPane}
        />
      )}
      {showFiltersPane && (
        <FindingsFiltersPane
          onClose={() => {
            setShowFiltersPane(false);
            setFilterSearch("");
          }}
          filters={filters}
          setFiltersUrl={setFiltersUrl}
          searchWord={filterSearch}
        />
      )}

      {(showProjectPane || initialProject) && (
        <ProjectPane
          onClose={() => setShowProjectPane(false)}
          project={
            initialProject ||
            projects?.find((p) => p.id === selectedFinding?.project) ||
            null
          }
        />
      )}

      {showReportsGen && (
        <ReportsGenV2 onClose={() => setShowReportsGen(false)} />
      )}

      {showAffectedAssetsPane && selectedFinding && (
        <AffectedAssetsPane
          findingId={selectedFinding.id}
          onClose={() => setShowAffectedAssetsPane(false)}
        />
      )}
    </>
  );
};
export default Findings;
