import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  HeaderMain,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiSingleFinding } from "../../../hooks/queries/findingContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { FindingBadges } from "../findingPane/FindingBadges";
import { AffectedAssetsPane } from "./AffectedAssetsPane";
import { FindingsPageNavButton } from "./FindingsPageNavButton";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { TopBoxes } from "./TopBoxes";
import { TopBoxesSkeleton } from "./TopBoxesSkeleton";
import { MiddleBox } from "./MiddleBox";
import { MiddleBoxSkeleton } from "./MiddleBoxSkeleton";
import { BottomBox } from "./BottomBox";
import { BottomBoxSkeleton } from "./BottomBoxSkeleton";
import { ImageModal } from "../../../components/composed/imageModal/ImageModal";
import { Link, useSearchParams } from "react-router-dom";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { PushFindingJira } from "../findingsSync/pushFindingJira/PushFindingJira";
import { FindingHistory } from "../findingHistoryAndComments/FindingHistory";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { NoDataAvailable } from "../../insights/NoDataAvailable";
import { useLicense } from "../../../licensing/LicenseManager";
import { FindingsTrialLimitation } from "../../../components/composed/trialLimitationsMessages/FindingsTrialLimitation";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_MOBILE_WIDTH } from "../../../shared/consts";
import FindingDetailsMobile from "./FindingDetailsMobile";
import { FindingsExpired } from "../../../licensing/sub-components/FindingsExpired";
import { Badge } from "../../../components/elements/badge/Badge";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { RelatedFindings } from "./RelatedFindings";
import { ProjectPane } from "../../projects/projectPane/ProjectPane";
import { Project } from "../../../types/Project";
import { AssetPane } from "../../assets/assetsPane/AssetPane";
import { DifferentCustomerWarning } from "./DifferentCustomerWarning";
import {
  FindingsDetailsEdit,
  isFindingHasUnsavedChanges,
} from "./FindingDetailsEdit";
import { FindingDetailsBreadCrumbs } from "./FindingDetailsBreadCrumbs";
import { FindingAttachmentsDetails } from "./FindingAttachmentsDetails";
import { Mixpanel } from "../../../shared/mixpanel";

export const FindingDetails = () => {
  const { id: findingId } = useParams();
  useTrackPage("Findings - Details", { findingId: findingId || "" });
  const { licenseType } = useLicense();
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;

  const [searchParams, setSearchParams] = useSearchParams();
  const filterBase64 = searchParams.get("filters");
  const isEditMode = searchParams.get("mode") === "edit";

  const { data: me } = useApiMe();

  const {
    data: finding,
    isFetching,
    isRefetching,
    status: findingQueryStatus,
    refetch,
  } = useApiSingleFinding(
    parseInt(findingId || "0"),
    searchParams.get("admin-mode") === "true"
  );

  const isTrialLimited =
    licenseType === "trial" &&
    finding?.overall_risk &&
    finding?.overall_risk >= 3;

  const [showImageModal, setShowImageModal] = useState(false);
  const [showAffectedAssetsPane, setShowAffectedAssetsPane] = useState(false);

  const [showProject, setShowProject] = useState<Project | undefined>();
  const [showAsset, setShowAsset] = useState<number>(0);

  const [clickedSource, setClickedSource] = useState("");

  const exportPdf = () => {
    window
      .open(
        `${me?.customer?.jira_details?.url}/browse/${finding?.customer_jira_issue_key}`,
        "_blank"
      )
      ?.focus();
  };

  const registerOnClickImageEvents = () => {
    const elements = document.getElementsByClassName("wasp-image-big");
    if (elements.length > 0) {
      Array.from(elements).forEach((elm) => {
        if (elm instanceof HTMLImageElement) {
          elm.onclick = () => {
            setClickedSource(elm.src);
            setShowImageModal(true);
          };
        }
      });
    }
  };
  setTimeout(registerOnClickImageEvents, 1000);

  const hasProjectMapping = !!me?.customer?.jira_projects_mapping?.filter(
    (p) => !p.auto_send && p.enabled
  ).length;
  const hasAutoSendMapping = !!me?.customer?.jira_projects_mapping?.some(
    (p) => p.auto_send && p.enabled
  );

  const cachedFindingStr = localStorage.getItem(`findingEdit${findingId}`);

  const cachedFinding = !!cachedFindingStr?.length
    ? JSON.parse(cachedFindingStr)
    : null;

  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false);

  const toggleEditMode = () => {
    if (searchParams.get("mode") === "edit") {
      searchParams.delete("mode");
      refetch();
      if (finding && cachedFinding)
        setIsUnsavedChanges(isFindingHasUnsavedChanges(cachedFinding, finding));
    } else {
      searchParams.set("mode", "edit");
      Mixpanel.track("Finding Details - edit finding");
    }
    setSearchParams(searchParams);
  };

  // Check if finding have unsaved changes
  // Remove local storage data if no change
  useEffect(() => {
    if (!finding || isEditMode) return;
    const isDiff = isFindingHasUnsavedChanges(cachedFinding, finding);
    // console.log({ isDiff });
    if (!isDiff) localStorage.removeItem(`findingEdit${findingId}`);
    setIsUnsavedChanges(isDiff);
  }, [cachedFinding, isUnsavedChanges, findingId, finding, isEditMode]);

  const backUrl = filterBase64
    ? `/findings?filters=${filterBase64}`
    : "/findings";

  if (!findingId) return null;

  if (findingQueryStatus === "error") return <NoDataAvailable />;

  if (isMobile) return <FindingDetailsMobile finding={finding} />;

  if (isEditMode) return <FindingsDetailsEdit onClose={toggleEditMode} />;

  return (
    <Flex column gap="16px">
      {!isRefetching &&
        !isFetching &&
        me?.customer.id !== finding?.customer && (
          <DifferentCustomerWarning customerId={finding?.customer} />
        )}

      <Flex align="center" w100>
        <FindingDetailsBreadCrumbs finding={finding} backUrl={backUrl} />

        <FindingsPageNavButton
          isFetching={isFetching}
          findingId={findingId}
          finding={finding}
          currentFiltersBase64={filterBase64 || ""}
        />
      </Flex>

      <Flex column gap="24px">
        <Flex justify="between" align="end">
          <Flex column justify="center" gap="16px" style={{ maxWidth: "60%" }}>
            <Flex gap="8px" align="center">
              {/* TITLE */}
              <HeaderMain>
                {isFetching ? (
                  <SkeletonLoading height="58px" width="600px" />
                ) : (
                  finding?.title
                )}
              </HeaderMain>

              {/* BADGES FOR SUPERUSERS */}
              {(isSuperuser ||
                (me?.customer.is_multi_tenant &&
                  me?.can_manage_customer &&
                  finding?.source === "CUSTOMER_INSERTED")) && (
                <Flex align="center" gap="8px">
                  <Flex align="center">
                    <LinkButton
                      style={{ width: "160px" }}
                      size="medium"
                      label="Edit Finding"
                      iconName="edit"
                      onClick={toggleEditMode}
                    />
                    {isUnsavedChanges && (
                      <LabelRegular style={{ width: "140px" }}>
                        • Unsaved changes
                      </LabelRegular>
                    )}
                  </Flex>
                  {finding?.retest_not_found && (
                    <Badge
                      color={theme.redPrimary}
                      backgroundColor={theme.red50}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyItems: "center",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      Retest Not Found
                    </Badge>
                  )}
                  {finding?.is_pending && (
                    <Badge
                      color={theme.redPrimary}
                      backgroundColor={theme.red50}
                    >
                      Pending
                    </Badge>
                  )}
                </Flex>
              )}
            </Flex>

            {/* CVE/CWE BADGES AND TAGS */}
            <Flex align="center">
              <FindingBadges finding={finding} />
            </Flex>
          </Flex>

          {/* Export PDF / Jira buttons  */}
          {!isTrialLimited && (
            <Flex align="center" gap="16px" justify="end">
              <Link
                to={`/findings/${finding?.id}/pdf`}
                style={{ textDecoration: "none", color: "inherit" }}
                target="_blank"
              >
                <LinkButton
                  size="medium"
                  label="Export PDF"
                  iconName="export"
                />
              </Link>

              {finding?.customer_jira_issue_key ? (
                <SecondaryButton
                  size="small"
                  label={finding.customer_jira_issue_key}
                  iconName="export"
                  onClick={exportPdf}
                />
              ) : !hasAutoSendMapping && hasProjectMapping ? (
                <PushFindingJira
                  customerJiraUrl={me?.customer?.jira_details?.url}
                  jiraIssueKey={finding?.customer_jira_issue_key || undefined}
                  finding={finding}
                  placeholder="Sync with Jira"
                  buttonStyle="secondary"
                />
              ) : null}
            </Flex>
          )}
        </Flex>

        {isTrialLimited ? (
          <FindingsTrialLimitation />
        ) : (
          <Flex gap="24px" column>
            {isFetching && !isRefetching ? (
              <Flex gap="24px" column w100>
                <TopBoxesSkeleton />
                <MiddleBoxSkeleton />
                <BottomBoxSkeleton />
              </Flex>
            ) : (
              <Flex gap="24px" column w100>
                {me?.customer.is_locked ? (
                  <FindingsExpired />
                ) : (
                  <Flex column gap="24px">
                    {/* DATE OPENED , SLA, STATUS, SEVERITY  */}
                    <TopBoxes finding={finding} />

                    {/* ASSIGNEE, IMPACT, EXPLOITABILITY, CVSS, PROJECT, ISSUED BY, LAST SEEN, SCANNER */}
                    <MiddleBox
                      finding={finding}
                      setShowAffectedAssetsPane={setShowAffectedAssetsPane}
                      onClickProject={(project) => setShowProject(project)}
                      onClickAsset={(assetId) => setShowAsset(assetId)}
                    />

                    <FindingAttachmentsDetails findingId={finding?.id} />

                    {isSuperuser && <RelatedFindings finding={finding} />}

                    {/* DESCRIPTION , ATTACK DETAILS , MITIGATION */}
                    <BottomBox finding={finding} />
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        )}
        <Box>
          <FindingHistory
            finding={finding}
            isAdminMode={searchParams.get("admin-mode") === "true"}
          />
        </Box>
      </Flex>

      {showAffectedAssetsPane && finding && (
        <AffectedAssetsPane
          findingId={finding.id}
          onClose={() => setShowAffectedAssetsPane(false)}
          onClickAsset={(assetId) => setShowAsset(assetId)}
        />
      )}

      {showAsset ? (
        <AssetPane assetId={showAsset} onClose={() => setShowAsset(0)} />
      ) : null}

      {showProject && (
        <ProjectPane
          onClose={() => setShowProject(undefined)}
          project={showProject}
        />
      )}

      {showImageModal && (
        <ImageModal
          onClose={() => setShowImageModal(false)}
          image={clickedSource}
        />
      )}
    </Flex>
  );
};
