import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { useContext } from "react";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { SCREEN_SMALL_LAPTOP_WIDTH } from "../../../shared/consts";
import {
  BodyRegular,
  HeaderMain,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { useNavigate } from "react-router";
import { LoginSSO } from "./LoginSSO";
import { PoweredByOP } from "./PoweredByOP";
import { IS_DEMO_ENV } from "../../../hooks/queries/utils";
import { TermsAndPrivacyPolicy } from "./TermsAndPrivacyPolicy";
import { HexagonsBackground } from "./HexagonsBackground";

import loginImage from "../../../assets/images/loginImage.png";
import { WaspLogo } from "./WaspLogo";

export const Login = () => {
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isLaptop = screenWidth < SCREEN_SMALL_LAPTOP_WIDTH;

  return (
    <Flex style={{ height: "100vh", background: theme.bg1 }} justify="between">
      <Flex
        column
        justify="between"
        style={{
          width: "100%",
          height: "100vh",
          padding: "12px",
        }}
      >
        <WaspLogo />

        <Flex w100 gap="32px" column align="center">
          <HeaderMain>Sign In to WASP {IS_DEMO_ENV ? "Demo" : ""}</HeaderMain>
          <LoginSSO />
          <Flex align="center" column>
            <BodyRegular>Don't have an account ?</BodyRegular>
            <Flex align="center">
              <TextButton
                fontSize="14px"
                label="Sign Up Now"
                onClick={() => navigate("/register")}
              />
            </Flex>
          </Flex>
          <TermsAndPrivacyPolicy />
        </Flex>
        <PoweredByOP />
      </Flex>

      {!isLaptop && (
        <Flex
          column
          align="center"
          justify="center"
          style={{
            minWidth: "66%",
            height: "100vh",
            backgroundColor: theme.primary,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <HexagonsBackground />
          <img
            src={loginImage}
            width={"900px"}
            alt=""
            style={{ zIndex: 100, maxWidth: "50vw" }}
          />
          <HeaderMain
            style={{
              width: "900px",
              fontSize: "48px",
              color: theme.textWhite,
              zIndex: 100,

              textAlign: "center",
            }}
          >
            Welcome to WASP
          </HeaderMain>
          <HeaderSecondary
            style={{
              color: theme.white80,
              marginTop: "24px",
            }}
          >
            Close Your Application Exposure Gap
          </HeaderSecondary>
        </Flex>
      )}
    </Flex>
  );
};
