import { useNavigate } from "react-router";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { getIsoString, objectToBase64 } from "../../../shared/helper";
import { Dispatch, SetStateAction, useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  KPISmall,
  LabelRegular,
  LabelRegularHover,
} from "../../../components/elements/typography/Typography";
import { TrendUpGreenIcon } from "../../../components/elements/icon/TrendUpGreenIcon";
import { ThemeContext } from "styled-components";

const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

const fixedInLastSevenDaysFilters = {
  status_updated_at_after: getIsoString(sevenDaysAgo),
  status: "fixed",
};

export const FixedInTheLast7Days = ({
  setFilters,
  isLoading,
}: {
  setFilters: Dispatch<SetStateAction<{ [key: string]: any }>>;
  isLoading: boolean;
}) => {
  const theme = useContext(ThemeContext);

  const navigate = useNavigate();

  const { data: lastSevenDaysCounts } = useApiFindingsCounts(
    fixedInLastSevenDaysFilters
  );

  const fixedCount = lastSevenDaysCounts?.fixed || 0;

  const onClickFixedFindings = () => {
    navigate(
      `/findings?filters=${objectToBase64(fixedInLastSevenDaysFilters)}`
    );
    setFilters(fixedInLastSevenDaysFilters);
  };

  return (
    <Flex column justify="evenly">
      <Flex gap="8px">
        <KPISmall
          title={`findings fixed in the last 7 days: ${fixedCount}`}
          style={{ color: isLoading ? theme.black500 : theme.greenPrimary }}
        >
          {fixedCount}
        </KPISmall>
        <TrendUpGreenIcon />
      </Flex>
      <Flex>
        {!!fixedCount ? (
          <LabelRegularHover
            onClick={onClickFixedFindings}
            title="Findings Fixed"
          >
            Findings Fixed
          </LabelRegularHover>
        ) : (
          <LabelRegular title="Findings Fixed">Findings Fixed</LabelRegular>
        )}
      </Flex>
      <LabelRegular style={{ color: theme.black600 }} title="In last 7 days">
        In last 7 days
      </LabelRegular>
    </Flex>
  );
};
