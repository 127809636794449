import React, { useContext } from "react";
import { AssetStatus, assetStatusOptions } from "../../../types/Asset";
import { Badge } from "../../../components/elements/badge/Badge";
import { ThemeContext } from "styled-components";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Icon } from "../../../components/elements/icon/Icon";
import { getAssetStatusIcon } from "../AssetUtils";

type Props = {
  status: AssetStatus;
};

export const AssetStatusBadge = ({ status }: Props) => {
  const theme = useContext(ThemeContext);
  const label = assetStatusOptions.find(
    (option) => option.value === status
  )?.label;
  if (!label) {
    return null;
  }
  const textColor = status === "live" ? theme.green800 : theme.black700;
  const iconColor = status === "live" ? theme.green600 : theme.black600;
  const backgroundColor = status === "live" ? theme.green50 : theme.black400;
  return (
    <Badge
      backgroundColor={backgroundColor}
      style={{
        padding: "4px 12px 4px 6px",
      }}
    >
      <Flex align="center" gap="8px">
        <Icon name={getAssetStatusIcon(status)} color={iconColor} size={24} />
        <LabelRegular color={textColor}>{label}</LabelRegular>
      </Flex>
    </Badge>
  );
};
