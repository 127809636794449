import { useState } from "react";
import { FirstPage } from "../generator/FirstPage";
import {
  DisclaimerPage,
  MultiTenantDisclaimerPage,
} from "../generator/DisclaimerPage";
import { AboutPage } from "../generator/AboutPage";
import { TableOfContents } from "../generator/TableOfContents";
import { PageLayout } from "../generator/PageLayout";
import { ExecutiveSummary } from "../generator/ExecutiveSummary";
import { getActivityType } from "../../../shared/helper";
import { Methodology } from "../generator/Methodology";
import { TeamMembers } from "../generator/TeamMembers";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";
import { SummaryOfResults } from "../generator/SummaryOfResults";
import { ProjectObjectives } from "../generator/ProjectObjectives";
import { FindingsInDetails } from "./FindingsInDetails";
import { FindingPage } from "./FindingPage";
import { AppendixA } from "../generator/AppendixA";
import { AppendixB } from "../generator/AppendixB";
import { ResultsAtGlance } from "../generator/ResultsAtGlance";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { ProjectScope } from "../generator/ProjectScope";
import { useReportData } from "../useReportData";
import { ReportsLoader } from "../ReportsLoader";
import { ProjectFirstPage } from "../generator/ProjectFirstPage";
import "../reports.css";

const WAITING_TIME = 10; //seconds

export const FullReport = () => {
  const {
    projects,
    isFetchingProject,
    findings,
    isMultiTenantReport,
    isFetchingFindings,
    isPageLoaded,
    isFetching,
    projectsNames,
  } = useReportData();

  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);

  const isReadyToPrint = !!(
    !loadingData &&
    !!projects?.length &&
    !!findings?.length
  );

  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  usePrint(isReadyToPrint, `Full-Report-${projectsNames}`);

  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <ReportsLoader
          setLoadingData={(state) => setLoadingData(state)}
          countdown={countdown}
        />
      )}
      {/* HEADER */}
      <FirstPage projects={projects} reportType="Full Report" />
      {/* DISCLAIMER */}
      {isMultiTenantReport ? <MultiTenantDisclaimerPage /> : <DisclaimerPage />}
      {/* ABOUT PAGE */}
      {!isMultiTenantReport && <AboutPage />}
      <TableOfContents
        projects={projects}
        findings={findings || []}
        isMultiTenantReport={isMultiTenantReport}
      />
      {!isMultiTenantReport && (
        <>
          <ExecutiveSummary
            findings={findings || []}
            activityType={getActivityType(projects?.[0].type)}
          />
          <Methodology />
          <ProjectObjectives />
          <TeamMembers findings={findings || []} />
        </>
      )}

      {/* PROJECTS */}
      {projects?.map((project, idx) => {
        const projectFindings = findings?.filter(
          (f) => f.project === project.id
        );
        return (
          <div key={idx} style={{ width: "663px" }}>
            {idx > 0 && (
              <div style={{ pageBreakBefore: "always", marginTop: "48px" }} />
            )}
            {/* Show project header only on multi projects reports */}
            {projects.length > 1 && (
              <ProjectFirstPage project={project} reportType="Full Report" />
            )}

            {!!project?.project_scope && <ProjectScope project={project} />}
            <SummaryOfResults findings={projectFindings || []} />
            <ResultsAtGlance findings={projectFindings || []} />
            <FindingsInDetails />

            <div
              style={{
                height: "auto",
                width: "663px",
                breakAfter: "always",
              }}
            >
              {projectFindings?.map((finding, index) => (
                <FindingPage
                  finding={finding}
                  key={`finding-page-${finding.id}`}
                  index={index}
                />
              ))}
            </div>
          </div>
        );
      })}

      {!isMultiTenantReport && <AppendixA />}
      <AppendixB />
    </PageLayout>
  );
};
