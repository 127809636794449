import {
  ReactFlow,
  type Node,
  OnNodesChange,
  applyNodeChanges,
  ReactFlowInstance,
  OnInit,
  Edge,
} from "@xyflow/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "@xyflow/react/dist/style.css";
import { GraphAsset } from "../sigmaGraph/GraphDataProvider";
import { Loading } from "../../../../components/elements/loading/Loading";
import { AssetsViewProps, Filter } from "../../../../types/AssetsView";
import { Box } from "../../../../components/elements/box/Box";
import {
  Dropdown,
  Option,
} from "../../../../components/elements/dropdowns/Dropdown";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { calculateAssetGrade } from "../../AssetUtils";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { CheckboxState } from "../../../../components/elements/checkbox/Checkbox";
import {
  useApiAssetsGraphData,
  useApiAssetsGraphFilter,
} from "../../../../hooks/queries/assetsGraphContext";
import { GraphGroupNode, GraphNode, NodeTypes } from "./GraphTypes";
import { createGroupsLayout, createNodesLayout } from "./NodeLayoutManager";

import "./customReactFlow.css";
import { CustomControls } from "./CustomControls";
import { SeparatorHorizontal } from "../../../../components/elements/separators/SeparatorHorizontal";
import { useSearchParams } from "react-router-dom";
import {
  fromBase64AssetsView,
  getLetterByIndex,
  toBase64AssetsView,
} from "../../../../shared/helper";
import { AssetsFiltersBadges } from "../../filters/AssetsFiltersBadges";
import { TextButton } from "../../../../components/elements/button/text/TextButton";
import { defaultAssetsView } from "../../Assets";
import { Ellipse } from "../../../../components/elements/ellipse/Ellipse";
import { emptyAssetsViewProps } from "../../filters/FiltersUtils";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  useDebounceCallback,
  useScreenHeight,
  useScreenWidth,
} from "../../../../hooks/utilsHooks";
import { AssetPane } from "../../assetsPane/AssetPane";
import {
  calcGroupRisk,
  getGraphRiskColor,
  getNodeRiskColor,
  handleClickGroupNode,
  handleNodeDrag,
  MAP_ASSET_GRADE_RISK,
  mapGraphNodeToFlowNode,
  useLabelOrColorStateChange,
} from "./GraphHelper";
import {
  DEFAULT_COLOR_BY_RISK,
  DEFAULT_EXPAND_ALL,
  DEFAULT_SHOW_LABELS,
  DEFAULT_SHOW_NEW,
} from "./constants";
import { MapLayers } from "./MapLayers";
import { MapFilters } from "./MapFilters";
import {
  groupByVulnerabilities,
  setVulnerabilitiesGroupNodes,
} from "./VulnerabilitiesGroupHelper";
import { Illustration } from "../../../../components/elements/illustrations/Illustration";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../../components/elements/typography/Typography";
import { AssetCloudProperties } from "../../../../types/Asset";

const groupByOptions: Option[] = [
  { label: "Environment", value: "environment" },
  { label: "Product", value: "product" },
  { label: "Risk", value: "risk" },
  { label: "Technology", value: "technology" },
  { label: "Vulnerabilities", value: "vulnerabilities" },
  { label: "Cloud Service", value: "cloud_service" },
];

type Props = {
  setTotalAssets: (total: number | undefined) => void;
};

export const AssetsFlowGraph = ({ setTotalAssets }: Props) => {
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const screenHeight = useScreenHeight();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [showLabels, setShowLabels] = useState(DEFAULT_SHOW_LABELS);
  const [colorByRisk, setColorByRisk] = useState(DEFAULT_COLOR_BY_RISK);
  const [showNew, setShowNew] = useState(DEFAULT_SHOW_NEW);
  const [selectedAssetId, setSelectedAssetId] = useState(0);
  const [groupedAssets, setGroupedAssets] = useState<Record<
    string,
    GraphNode[]
  > | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [assetsView, setAssetsView] =
    useState<AssetsViewProps>(defaultAssetsView);
  const [triggerOpenFilterPane, setTriggerOpenFiltersPane] = useState(0);
  const [groupChangeCount, setGroupChangeCount] = useState(0);

  const [groupBy, setGroupBy] = useState<Option>(
    groupByOptions.find((o) => o.value === "product") as Option
  );
  const [groupIds, setGroupIds] = useState<string[]>([]);

  const { data: products, isFetching: isFetchingProducts } = useApiProducts();
  const { data: graphData, isFetching: isFetchingGraph } =
    useApiAssetsGraphData();
  const { data: filteredAssetIds, isFetching: isFetchingFilteredAssetIds } =
    useApiAssetsGraphFilter(assetsView.filters);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const reactFlowInstance = useRef<ReactFlowInstance<Node, Edge> | null>(null);

  const onInit = useCallback<OnInit<Node, Edge>>((instance) => {
    reactFlowInstance.current = instance;
  }, []);

  useEffect(() => {
    // Sets the assets view state from URL params,
    // If no view attribute on URL params, sets the URL to default view
    let encodedView = searchParams.get("view");
    if (encodedView) {
      let decodedView = fromBase64AssetsView(encodedView);
      if (decodedView) setAssetsView(decodedView);
    }
    const assetId = searchParams.get("assetId");
    if (!!assetId) {
      setSelectedAssetId(Number(assetId));
    }
    const tempGroupBy = searchParams.get("groupBy");
    if (tempGroupBy) {
      setGroupBy(groupByOptions.find((o) => o.value === tempGroupBy) as Option);
    }
    const showLabels = searchParams.get("showLabels");
    if (showLabels) {
      setShowLabels(showLabels === "true");
    }
    const colorByRisk = searchParams.get("colorByRisk");
    if (colorByRisk) {
      setColorByRisk(colorByRisk === "true");
    }
    const showNew = searchParams.get("showNew");
    if (showNew) {
      setShowNew(showNew === "true");
    }
  }, [searchParams, setSearchParams]);

  const handleCollapseAll = (state: CheckboxState) => {
    groupIds.forEach((groupId) => {
      handleClickGroup(
        groupId,
        groupedAssets?.[groupId] || [],
        state !== "checked"
      );
    });
  };

  const handleAssetsViewChange = (updatedAssetsView: AssetsViewProps) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("view", toBase64AssetsView(updatedAssetsView));
    setSearchParams(newSearchParams); // trigger useEffect to update filters
  };

  const handleFiltersChange = (filters: Filter[]) => {
    assetsView.filters = filters; // update filters
    const viewBase64 = toBase64AssetsView(assetsView);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("view", viewBase64);
    setSearchParams(newSearchParams); // trigger useEffect to update filters
  };

  const riskToColor = useCallback(
    (riskScore: number, shouldColorByRisk: boolean): string => {
      return getNodeRiskColor(
        riskScore,
        shouldColorByRisk,
        theme,
        groupBy.value === "risk"
      );
    },
    [groupBy, theme]
  );

  const onNodeDrag = useCallback(
    (event: React.MouseEvent, node: Node) => {
      return handleNodeDrag(nodes, setNodes, node);
    },
    [setNodes, nodes]
  );

  const groupAssetsByTech = useCallback((assets: GraphAsset[]) => {
    return assets.reduce(
      (acc, asset) => {
        const graphAsset = {
          id: `a${asset.id}`,
          risk: asset.risk_score,
          x: 0,
          y: 0,
          name: asset.name,
          createdAt: asset.created_at,
        };
        if ((asset.properties?.technologies || []).length === 0) {
          const tech = "No Technology";
          if (!acc[tech]) {
            acc[tech] = [];
          }
          acc[tech].push({ ...graphAsset, groupId: tech });
        } else {
          asset.properties?.technologies?.forEach((tech, i) => {
            if (!acc[tech]) {
              acc[tech] = [];
            }
            acc[tech].push({
              ...graphAsset,
              id: `${getLetterByIndex("a", i)}${asset.id}`,
              groupId: tech,
              isDuplicated: (asset.properties?.technologies || []).length > 1,
              createdAt: asset.created_at,
            });
          });
        }

        return acc;
      },
      {} as Record<string, GraphNode[]>
    );
  }, []);

  const groupAssetsByFindings = useCallback(
    (assets: GraphAsset[]) => {
      return groupByVulnerabilities(assets, graphData?.relations || []);
    },
    [graphData?.relations]
  );

  const getAssetsGroupedBy = useCallback(
    (assets: GraphAsset[]): Record<string, GraphNode[]> => {
      const groupAssetsBy = (
        assets: GraphAsset[],
        getKey: (asset: GraphAsset) => string
      ) => {
        return assets
          .filter((a) => getKey(a))
          .reduce(
            (acc, asset) => {
              const key = getKey(asset);
              if (key) {
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push({
                  id: `a${asset.id}`,
                  groupId: key,
                  risk: asset.risk_score,
                  x: 0,
                  y: 0,
                  name: asset.name,
                  createdAt: asset.created_at,
                });
              }
              return acc;
            },
            {} as Record<string, GraphNode[]>
          );
      };

      if (groupBy.value === "environment") {
        return groupAssetsBy(assets, (asset) => asset.environment || "Unknown");
      }

      if (groupBy.value === "product") {
        return groupAssetsBy(
          assets,
          (asset) =>
            products?.find((p) => p.id === asset.product_id)?.name || "Unknown"
        );
      }

      if (groupBy.value === "risk") {
        return groupAssetsBy(
          assets,
          (asset) => MAP_ASSET_GRADE_RISK[calculateAssetGrade(asset.risk_score)]
        );
      }

      if (groupBy.value === "technology") {
        return groupAssetsByTech(assets);
      }

      if (groupBy.value === "vulnerabilities") {
        return groupAssetsByFindings(assets);
      }

      if (groupBy.value === "cloud_service") {
        return groupAssetsBy(
          assets.filter((a) => a.type === "cloud"),
          (asset) =>
            (asset.properties as AssetCloudProperties)?.service || "Other"
        );
      }

      return {};
    },
    [groupBy, products, groupAssetsByTech, groupAssetsByFindings]
  );

  const handleClickGroup = useCallback(
    (groupId: string, groupNodes: GraphNode[], forceExpand?: boolean) => {
      handleClickGroupNode(
        groupId,
        groupNodes,
        setNodes,
        forceExpand,
        setGroupChangeCount
      );
    },

    []
  );

  const getGroupRisk = useCallback((group: GraphGroupNode) => {
    return calcGroupRisk(group);
  }, []);

  useEffect(() => {
    if (
      isFetchingGraph ||
      isFetchingProducts ||
      isFetchingFilteredAssetIds ||
      !graphData?.assets?.length
    )
      return;
    const assetsToUse = filteredAssetIds?.length
      ? graphData.assets.filter((a) => filteredAssetIds.includes(a.id))
      : graphData.assets;
    setTotalAssets(assetsToUse.length);
    const groupedAssets = getAssetsGroupedBy(assetsToUse);
    setGroupedAssets(groupedAssets);
    setGroupIds(Object.keys(groupedAssets));

    // map to layout manager object
    const groups: GraphGroupNode[] = Object.keys(groupedAssets).map(
      (groupId) => ({
        id: groupId,
        center: { x: 0, y: 0 },
        radius: 0,
        nodes: groupedAssets[groupId],
        isExpand: DEFAULT_EXPAND_ALL,
      })
    );

    var legendAndSeparatorsNodes: Node[] = [];
    if (groupBy.value === "vulnerabilities") {
      legendAndSeparatorsNodes = setVulnerabilitiesGroupNodes(
        screenHeight,
        screenWidth,
        graphData?.findings || [],
        Object.keys(groupedAssets).map(Number),
        groups,
        theme
      );
    }

    createGroupsLayout(groups, groupBy.value === "vulnerabilities");

    const groupNodes = groups.map((group) => {
      if (group.isExpand) {
        createNodesLayout(group.radius, groupedAssets[group.id]);
      }

      const finding =
        groupBy.value === "vulnerabilities"
          ? graphData?.findings.find((f) => f.id === Number(group.id))
          : undefined;

      const groupRisk = getGroupRisk(group);
      return {
        id: group.id,
        data: {
          label: finding !== undefined ? finding.title : `${group.id}`,
          radius: group.radius,
          x: group.center.x, // need to save the center position for expand/collapse
          y: group.center.y, // need to save the center position for expand/collapse
          nodesCount: group.nodes.length,
          isGroup: true,
          risk: finding !== undefined ? finding.overall_risk : groupRisk,
          color:
            finding !== undefined
              ? theme[getGraphRiskColor(finding.overall_risk)]
              : riskToColor(groupRisk, DEFAULT_COLOR_BY_RISK),
          isExpand: DEFAULT_EXPAND_ALL,
          onClick: () => handleClickGroup(group.id, group.nodes),
          isFinding: finding !== undefined,
          maxHeight: group.maxHeight,
          startY: group.startY,
        },
        type: "assetsGroup",
        position: {
          x: group.center.x,
          y: group.center.y,
        },
      };
    });
    const assetsNodes = DEFAULT_EXPAND_ALL
      ? Object.values(groupedAssets)
          .flat()
          .map((node) =>
            mapGraphNodeToFlowNode(
              node,
              riskToColor(node.risk, DEFAULT_COLOR_BY_RISK),
              DEFAULT_EXPAND_ALL,
              DEFAULT_SHOW_LABELS
            )
          )
      : [];

    setNodes([...groupNodes, ...assetsNodes, ...legendAndSeparatorsNodes]);
  }, [
    graphData,
    isFetchingFilteredAssetIds,
    filteredAssetIds,
    isFetchingGraph,
    isFetchingProducts,
    getAssetsGroupedBy,
    handleClickGroup,
    riskToColor,
    getGroupRisk,
    setTotalAssets,
    groupBy.value,
    theme,
    screenHeight,
    screenWidth,
  ]);

  // updates without reset positions
  useLabelOrColorStateChange(
    setNodes,
    showLabels,
    colorByRisk,
    showNew,
    groupBy.value === "risk",
    theme,
    groupChangeCount
  );

  const onSearchTextChange = useDebounceCallback((text: string) => {
    setNodes((nodes) =>
      nodes.map((n) => {
        return {
          ...n,
          data: {
            ...n.data,
            isHidden: !text
              ? false
              : !(n.data?.label || "")
                  .toString()
                  .toLowerCase()
                  .includes(text.toLowerCase()),
          },
        };
      })
    );
  }, 500);

  const handlePaneOnClosed = () => {
    setSelectedAssetId(0);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("assetId");
    setSearchParams(newSearchParams);
  };

  return (
    <Box
      id="graph-component-container"
      className="d-flex w-100"
      style={{
        position: "relative",
        height: `calc(100vh - 164px)`,
      }}
    >
      <Flex column w100 h100 gap="24px">
        <Flex align="center" gap="24px" justify="between">
          <Flex align="center" gap="16px">
            <Flex align="center" gap="8px">
              <Dropdown
                onChange={(option) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set(
                    "groupBy",
                    option?.value.toString() || ""
                  );
                  setSearchParams(newSearchParams);
                }}
                options={groupByOptions}
                size="small"
                variant="border"
                width="218px"
                placeholder="Group by"
                value={groupBy}
                valuePrefix="View By: "
              />
              <Flex
                style={{
                  position: "relative",
                }}
              >
                <IconButton
                  iconName="filter"
                  color={
                    assetsView.filters.length > 0
                      ? theme.primary
                      : theme.black600
                  }
                  size={"small"}
                  onClick={() =>
                    setTriggerOpenFiltersPane((state) => state + 1)
                  }
                />
                {assetsView.filters.length > 0 && (
                  <Ellipse
                    color={theme.redPrimary}
                    size={8}
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      transform: "translate(50%, -50%)",
                    }}
                  />
                )}
              </Flex>
            </Flex>

            <AssetsFiltersBadges
              filters={assetsView.filters}
              setFilters={handleFiltersChange}
            />
            {assetsView.filters.length > 0 && (
              <TextButton
                label="Clear all filters"
                onClick={() => handleAssetsViewChange(emptyAssetsViewProps)}
              />
            )}
          </Flex>
          <Flex>
            <InputText
              placeholder="Search..."
              iconName="search"
              onChange={(e) => onSearchTextChange(e.target.value)}
            />
          </Flex>
        </Flex>
        <Flex w100 h100>
          <Flex
            column
            gap="16px"
            style={{
              width: "280px",
            }}
          >
            <MapLayers handleCollapseAllGroupsChange={handleCollapseAll} />
            <SeparatorHorizontal />
            <MapFilters
              assetsView={assetsView}
              handleAssetsViewChange={handleAssetsViewChange}
              triggerOpenFilterPane={triggerOpenFilterPane}
            />
          </Flex>

          {isFetchingGraph ||
          isFetchingProducts ||
          isFetchingFilteredAssetIds ? (
            <Flex w100 h100 align="center" justify="center">
              <Loading />
            </Flex>
          ) : !isFetchingFilteredAssetIds && filteredAssetIds?.length === 0 ? (
            <Flex w100 h100 column justify="center" align="center" gap="8px">
              <Illustration
                name="empty"
                style={{
                  width: "50%",
                  height: "auto",
                  maxWidth: "300px",
                }}
              />
              <HeaderSubBold>
                No assets found with the selected filters
              </HeaderSubBold>
              <BodyRegular>
                Try changing the filters or search criteria
              </BodyRegular>
            </Flex>
          ) : (
            <ReactFlow
              nodes={nodes}
              onNodesChange={onNodesChange}
              // onConnect={onConnect}
              nodeTypes={NodeTypes}
              onNodeDrag={onNodeDrag}
              onNodeDragStop={onNodeDrag}
              onInit={onInit}
            >
              <CustomControls />
            </ReactFlow>
          )}
          {selectedAssetId > 0 && (
            <AssetPane onClose={handlePaneOnClosed} assetId={selectedAssetId} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
