import { useMutation, useQuery } from "@tanstack/react-query";
import { createItem, getItems } from "../../../hooks/queries/sdk";

const USER_MANUAL_STATUS_INTERVAL = 5 * 1000; // 7 sec stale time

type UpdateUserManualParams = {
  user_manual_text: string;
  onSuccessCallback?: (answer: any) => void;
  onErrorCallback?: (error: Error) => void;
};

type UserManualStatus = {
  status?:
    | "Uploading new user manual..."
    | "Deleting old user manual..."
    | "Chunking new user manual..."
    | "Indexing new user manual..."
    | "Done.";
};

const key = "update-user-manual";

export const useApiUpdateUserManual = () => {
  return useMutation<any, Error, UpdateUserManualParams>({
    mutationKey: [key],
    mutationFn: async (newUserManual): Promise<any> =>
      await createItem(key, newUserManual),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiUserManualGetStatus = () =>
  useQuery<UserManualStatus, Error>({
    queryKey: [key],
    keepPreviousData: true,
    staleTime: USER_MANUAL_STATUS_INTERVAL,
    refetchInterval: USER_MANUAL_STATUS_INTERVAL,
    enabled: true,
    queryFn: async (): Promise<UserManualStatus> => getItems(key),
  });
