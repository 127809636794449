import { IconProps } from "./WaspIcon";

export function DeadStatusIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38437 12.0008C4.38437 7.79649 7.7926 4.38828 11.9969 4.38828C16.2011 4.38828 19.6094 7.79649 19.6094 12.0008C19.6094 16.205 16.2011 19.6133 11.9969 19.6133C7.7926 19.6133 4.38437 16.205 4.38437 12.0008ZM11.9969 3.11328C7.08845 3.11328 3.10938 7.09235 3.10938 12.0008C3.10938 16.9092 7.08845 20.8883 11.9969 20.8883C16.9053 20.8883 20.8844 16.9092 20.8844 12.0008C20.8844 7.09235 16.9053 3.11328 11.9969 3.11328ZM10.5952 10.1088L11.0956 9.71387L10.3057 8.71307L9.8053 9.10802L8.79977 9.90159L7.79418 9.10802L7.29376 8.71307L6.5039 9.71394L7.00433 10.1089L7.77077 10.7137L7.00452 11.3185L6.50409 11.7134L7.29399 12.7142L7.7944 12.3193L8.79985 11.5258L9.8053 12.3194L10.3058 12.7142L11.0956 11.7134L10.5952 11.3185L9.82885 10.7137L10.5952 10.1088ZM17.4893 9.71537L16.9889 10.1103L16.2243 10.7137L16.9889 11.3171L17.4893 11.7119L16.6994 12.7128L16.199 12.3179L15.1953 11.5259L14.1917 12.3179L13.6913 12.7128L12.9014 11.712L13.4018 11.3171L14.1663 10.7138L13.4017 10.1103L12.9012 9.71537L13.6911 8.71449L14.1915 9.10944L15.1953 9.90159L16.199 9.10944L16.6994 8.71449L17.4893 9.71537ZM10.7081 14.9569L10.3235 14.6515L9.93003 14.9455L9.0811 15.58L8.57043 15.9616L9.3337 16.9829L9.8443 16.6013L10.2998 16.2608L11.2136 16.9864L11.5829 17.2796L11.9714 17.0123L13.0988 16.2365L14.1575 17.0034L14.6738 17.3774L15.4217 16.3448L14.9054 15.9709L13.4822 14.9399L13.1176 14.6758L12.7468 14.931L11.6372 15.6947L10.7081 14.9569Z"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
