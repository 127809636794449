import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createItem } from "../../../../hooks/queries/sdk";
import { invalidateApiQueries } from "../../../../hooks/queries/utils";

const key = "analyze-leak";

export type CredDetail = {
  username: string;
  password: string;
  credential_category: string;
};

type Credential = {
  url: string;
  creds: CredDetail[];
  root_domain: string;
  email_domains: string[];
};

export type Leak = {
  id: string;
  log_checksum: string;
  log_file_name: string;
  stealer_type: string;
  handle_status: any;
  computer_information: {
    build_id: string;
    infection_date: string;
    ip: string;
    malware_path: string;
    username: string;
    country: string;
    os: string;
    hwid: string;
  };
  credentials: Credential[];
};

type AnalyzeDomainParams = {
  domain: string;
  onSuccessCallback?: (answer: DomainAnalysisResponse) => void;
  onErrorCallback?: (error: Error) => void;
};

type DomainAnalysisResponse = {
  search_id: string;
  search_consumed_credits: number;
  credits_left: number;
  next: string;
  total_items_count: number;
  items_count: number;
  data: Leak[];
};

export const useApiAnalyzeDomain = () => {
  const queryClient = useQueryClient();
  return useMutation<DomainAnalysisResponse, Error, AnalyzeDomainParams>({
    mutationKey: [key],
    mutationFn: async ({ domain }): Promise<DomainAnalysisResponse> =>
      await createItem(key, { domain: domain }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
