import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "../../../components/elements/modal/Modal";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  LabelBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { LoadingProgressBar } from "../../../components/elements/loading/LoadingProgressBar";
import { useNavigate } from "react-router-dom";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { toBase64AssetsView } from "../../../shared/helper";

type Props = {
  onClose: () => void;
};

const timeToMap = 65;

export const CloudAssetsMappingInProgressModal = ({ onClose }: Props) => {
  const progressSentences = [
    "Scanning available cloud accounts...",
    "Looking for accessible regions...",
    "Fetching available services...",
    "Discovering network interfaces...",
    "Looking for storage volumes...",
    "Scanning for computing instances...",
    "Fetching Databases clusters...",
    "Fetching available Lambda functions...",
    "Mapping resources...",
    "Updating the your assets inventory...",
  ];

  const intervalDuration = (timeToMap * 1000) / progressSentences.length;
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const navigate = useNavigate();

  const handleOnClose = useCallback(() => {
    navigate(
      `/assets?view=${toBase64AssetsView({ name: "", filters: [{ column: "type", value: "cloud", condition: "is", order: 0 }] })}`
    );
    onClose();
  }, [navigate, onClose]);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        handleOnClose();
      },
      (timeToMap - 5) * 1000
    );

    return () => clearTimeout(timeout);
  }, [handleOnClose]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSentenceIndex(
        (prevIndex) => (prevIndex + 1) % progressSentences.length
      );
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [intervalDuration, progressSentences.length]);

  return (
    <Modal onClose={onClose} width="700px">
      <Flex column className="text-center" gap="16px">
        <HeaderSecondary>Mapping Cloud Assets</HeaderSecondary>
        <Flex column gap="8px">
          <BodyRegular>We are currently mapping your cloud assets.</BodyRegular>
          <BodyRegular>This process may take about a minute.</BodyRegular>
          <LoadingProgressBar duration={timeToMap} height="20px" width="100%" />
          <LabelBold>{progressSentences[currentSentenceIndex]}</LabelBold>
          <LabelRegular>
            You can close this window and continue using the platform, the
            results will shortly be available in the assets page.
          </LabelRegular>
        </Flex>
        <Flex align="center" justify="end" gap="16px">
          <MainButton label="Show Cloud Assets" onClick={handleOnClose} />
          <SecondaryButton label="Close" onClick={handleOnClose} />
        </Flex>
      </Flex>
    </Modal>
  );
};
