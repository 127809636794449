import { useContext } from "react";
import { CodeBox } from "../../../components/elements/box/Box";
import { Asset, AssetDomainProperties } from "../../../types/Asset";
import { ThemeContext } from "styled-components";
import {
  HeaderSecondary,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { Icon } from "../../../components/elements/icon/Icon";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Illustration } from "../../../components/elements/illustrations/Illustration";

type Props = {
  asset: Asset | undefined;
};

export const AssetWhois = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);

  if (asset?.type !== "domain" && asset?.type !== "ip") {
    return null;
  }

  const whois = (asset?.properties as AssetDomainProperties)?.whois
    ?.replace('b"', "")
    .slice(0, -1)
    .split(/\\n/);
  return (
    <>
      <Flex align="center">
        <Icon name="data" size={32} color={theme.primary} />
        <HeaderSecondary>Raw Whois Data</HeaderSecondary>
      </Flex>
      {whois ? (
        <CodeBox>
          <div style={{ height: "200px", overflowX: "auto" }}>
            {whois?.map((line) => <div>{line}</div>)}
          </div>
        </CodeBox>
      ) : (
        <Flex column align="center" justify="center">
          <Illustration
            name="empty"
            style={{
              width: "150px",
            }}
          />
          <HeaderSub>No Whois data available</HeaderSub>
        </Flex>
      )}
    </>
  );
};
