import React from "react";
import { IconProps } from "./WaspIcon";

export function LiveStatusIcon(props: IconProps) {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7"
        y="7"
        width="10"
        height="10"
        rx="5"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
}
