import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
} from "./utils";
import {
  getItems,
  updateItem,
  createItem,
  getResource,
  updateBulk,
  getPage,
  getViewPage,
  updateUrl,
  deleteItem,
  deleteBulk,
  sendFile,
  deleteUrl,
  getSingleItem,
} from "./sdk";
import {
  Asset,
  AssetCloudConnectionTestResponse,
  AssetEdit,
  AssetsCounts,
  AssetSource,
  AssetsPage,
  AssetsRiskDistribution,
  BulkUpdateAssets,
  DefaultAssetsCounts,
} from "../../types/Asset";
import {
  bitAfterNow,
  getQueryParams,
  toBase64Filters,
} from "../../shared/helper";
import { Filter } from "../../types/AssetsView";
import {
  keyFindingTrends,
  keySecurityScoreExtended,
} from "../../features/insights/findingsTrendsGraph/findingsTrendsContext";

const key = "assets";
const viewKey = "assets/view";
const countKey = "assets/counts";
const mobileKey = "assets/mobile";
const riskDistributionKey = "assets/risk_distribution";
const logsKey = "assets-logs";
// All possible attributes that can pass to API call

interface AssetsBulkContext {
  assetsData: BulkUpdateAssets;
  onSuccessCallback?: (assets: Asset[]) => void;
  onErrorCallback?: (error: Error) => void;
}

interface AssetsBulkDeleteContext {
  assetsData: BulkUpdateAssets;
  onSuccessCallback?: (data: number | undefined) => void;
  onErrorCallback?: (error: Error) => void;
}

interface MobileBundleUploadContext {
  mobile_app_bundle: File;
  assetId: number;
  onSuccessCallback?: (data: { mobile_app_bundle_name: string }) => void;
  onErrorCallback?: (error: Error) => void;
}

interface MobileBundleDeleteContext {
  assetId: number;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: Error) => void;
}

interface AssetsIdsBulkContext {
  asset_ids: number[];
  onSuccessCallback?: (assets: Asset[]) => void;
  onErrorCallback?: (error: Error) => void;
}

interface ImportAssetsContext {
  domain_names: string[];
  onSuccessCallback?: (assets: Asset[]) => void;
  onErrorCallback?: (error: Error) => void;
}

interface TestAssetConnectionContext {
  cloud_credentials: Record<string, string>;
  source: AssetSource;
  onSuccessCallback?: (data: AssetCloudConnectionTestResponse) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface AssetContext {
  assetId: number;
  assetData: AssetEdit;
  onSuccessCallback?: (asset: Asset) => void;
  onErrorCallback?: (
    error: Error,
    assetData?: AssetEdit,
    context?: unknown
  ) => void;
}

export interface MobileReport {
  report_download_url: string;
}

export interface MobileBundle {
  mobile_app_bundle_name: string;
}

export interface createAssetContext extends AssetEdit {
  onSuccessCallback?: (asset: Asset) => void;
  onErrorCallback?: (error: Error) => void;
}

export function getAsset(
  assetId: number,
  count_children?: boolean,
  adminMode?: boolean
): Promise<Asset> {
  if (!assetId) return Promise.resolve({} as Asset);
  return getResource(
    `${key}/${assetId}${count_children ? "?count_children=true" : ""}${adminMode ? (count_children ? "&admin-mode=true" : "?admin-mode=true") : ""}`
  );
}

export const useApiAsset = (id?: number, adminMode?: boolean) => {
  return useQuery<Asset, Error>({
    queryKey: [key, id?.toString()],
    queryFn: async (): Promise<Asset> => getAsset(id || 0, false, adminMode),
    enabled: !!id,
  });
};

export const useApiAssetsGetById = (
  assetsIds: number[],
  params?: { [key: string]: any },
  data?: unknown
) => {
  const enabled = !!assetsIds?.length;

  return useQuery<Asset[], Error>({
    queryKey: [key, { ...params, id: assetsIds }, data],
    keepPreviousData: true,
    placeholderData: [],
    enabled: enabled,
    queryFn: async (): Promise<Asset[]> =>
      getItems(key, { ...params, id: assetsIds }),
  });
};

export const useApiAssetsPaging = (
  filters?: { [key: string]: any },
  enabled?: boolean
) =>
  useInfiniteQuery<AssetsPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<AssetsPage> =>
      getPage(key, {
        ...filters,
        page: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiAssetsWithViewPaging = (
  filters?: Filter[],
  ordering?: string,
  enabledRefetch: boolean = true,
  adminMode?: boolean
) =>
  useInfiniteQuery<AssetsPage, Error>({
    queryKey: [viewKey, filters, ordering, adminMode],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    refetchIntervalInBackground: enabledRefetch,
    enabled: true,
    queryFn: async ({ pageParam = 1 }): Promise<AssetsPage> =>
      getViewPage(viewKey, filters || [], pageParam, ordering, adminMode),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiUpdateAssetsBulk = () => {
  const queryClient = useQueryClient();
  return useMutation<Asset[], Error, AssetsBulkContext>({
    mutationKey: [key],
    mutationFn: async ({ assetsData }: AssetsBulkContext): Promise<Asset[]> =>
      await updateBulk(`${key}/bulk`, assetsData),
    onSuccess: (data: Asset[], { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
  });
};

export const useApiDeleteAssetsBulk = () => {
  const queryClient = useQueryClient();
  return useMutation<number | undefined, Error, AssetsBulkDeleteContext>({
    mutationKey: [key],
    mutationFn: async ({
      assetsData,
    }: AssetsBulkDeleteContext): Promise<number | undefined> =>
      await deleteBulk(`${key}/bulk`, assetsData),
    onSuccess: (data: number | undefined, { onSuccessCallback }) => {
      if (onSuccessCallback && data) onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
  });
};

export const useApiImportAssets = () => {
  const queryClient = useQueryClient();
  const queryKey = "assets/bulk_import";
  return useMutation<any, Error, ImportAssetsContext>({
    mutationKey: [queryKey],
    mutationFn: async (newAsset): Promise<any> =>
      await createItem(queryKey, newAsset),
    onSuccess: (data: any, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([countKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiCreateAsset = (params?: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  const queryKey = params ? `${key}?${new URLSearchParams(params)}` : key;
  return useMutation<Asset, Error, createAssetContext>({
    mutationKey: [queryKey],
    mutationFn: async (newAsset): Promise<Asset> =>
      await createItem(queryKey, newAsset),
    onSuccess: (data: Asset, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onSettled: () => {
      invalidateApiQueries([queryKey], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([countKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiUpdateAsset = () => {
  const queryClient = useQueryClient();
  return useMutation<Asset, Error, AssetContext>({
    mutationKey: [key],
    mutationFn: async ({ assetId, assetData }: AssetContext): Promise<Asset> =>
      await updateItem(key, assetId, assetData),
    onMutate: ({ assetId, assetData, onSuccessCallback }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries([key]);
      // Snapshot the previous value
      const previousAsset = queryClient.getQueryData<Asset>([key, assetId]);
      // Optimistically update to the new value
      queryClient.setQueryData([key, assetId], {
        ...previousAsset,
        ...assetData,
      });
      return { previousAsset };
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
    onSuccess: (data: Asset, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback, assetData }, context) => {
      onErrorCallback && onErrorCallback(error, assetData, context);
    },
  });
};

export const useApiAssetsCount = (
  paramsFilters?: { [key: string]: any },
  deepFilters?: Filter[]
) => {
  var filters = paramsFilters;
  if (deepFilters)
    filters = { ...filters, filters: toBase64Filters(deepFilters) };
  return useQuery<AssetsCounts, Error>({
    queryKey: [countKey, paramsFilters, deepFilters],
    keepPreviousData: true,
    placeholderData: DefaultAssetsCounts,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<AssetsCounts> => getItems(countKey, filters),
  });
};

export const useApiAssetsRiskDistribution = (
  filters?: Filter[],
  enabled: boolean = true
) => {
  var filtersToUse = {};
  if (filters) {
    filtersToUse = { filters: toBase64Filters(filters) };
  }
  return useQuery<AssetsRiskDistribution, Error>({
    queryKey: [riskDistributionKey, filters],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled,
    queryFn: async (): Promise<AssetsRiskDistribution> =>
      getItems(riskDistributionKey, filtersToUse),
  });
};

export const useApiAssetsBreakdown = (
  assetProperty: string,
  sort?: string,
  filters?: Filter[],
  enabled = true
) => {
  var params: any = {
    property: assetProperty,
    sort: sort,
  };
  if (filters?.length) {
    params = { ...params, filters: toBase64Filters(filters) };
  }
  const queryKey = `${key}/breakdown?${new URLSearchParams(params)}`;
  return useQuery<{ [key: string]: number }>({
    queryKey: [queryKey, assetProperty, filters],
    queryFn: async (): Promise<{ [key: string]: number }> =>
      getResource(queryKey),
    keepPreviousData: true,
    initialData: undefined,
    initialDataUpdatedAt: Date.now() / 1000 + 100,
    staleTime: 60000, // only eligible to refetch after 1 minute
    enabled: enabled && !!assetProperty,
  });
};

export const useApiAssetsTags = (enabled: boolean = true) => {
  const queryKey = `${key}/tags`;
  return useQuery<string[]>({
    queryKey: [queryKey],
    queryFn: async (): Promise<string[]> => getResource(queryKey),
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: Date.now() / 1000 + 100,
    staleTime: 60000, // only eligible to refetch after 1 minute
    enabled: enabled,
  });
};

export const exportAssetsTable = async (
  filters: Filter[]
): Promise<Blob | void> => {
  return updateUrl(`assets/csv`, filters, false)
    .then((response) => response.blob())
    .catch((err) => {
      console.error(err);
    });
};

export const useApiDeleteAsset = () => {
  const queryClient = useQueryClient();
  return useMutation<Asset, Error, AssetContext>({
    mutationKey: [key],
    mutationFn: async ({ assetId, assetData }: AssetContext): Promise<any> =>
      await deleteItem(key, assetData, assetId),
    // onSuccess: (data, variables) =>
    onSuccess: (data: Asset, { onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: data.id }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([countKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
  });
};

export const useApiVerifyAsset = () => {
  const queryClient = useQueryClient();
  return useMutation<Asset, Error, AssetContext>({
    mutationKey: [key],
    mutationFn: async ({ assetId }: AssetContext): Promise<Asset> =>
      await updateItem(`${key}/${assetId}/verify_asset`, 0, {}),
    onSuccess: (data: Asset, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
      queryClient.invalidateQueries({ queryKey: [key] });
      queryClient.invalidateQueries({ queryKey: [viewKey] });
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
  });
};

export const useApiVerifyAssetsBulk = () => {
  const queryClient = useQueryClient();
  const queryKey = "assets/verify_bulk_assets";
  return useMutation<any, Error, AssetsIdsBulkContext>({
    mutationKey: [queryKey],
    mutationFn: async (newAsset): Promise<any> =>
      await createItem(queryKey, newAsset),
    onSuccess: (data: any, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onSettled: () => {
      invalidateApiQueries([viewKey], queryClient);
      invalidateApiQueries([logsKey], queryClient);
      // invalidate the security score component
      invalidateApiQueries(
        [keyFindingTrends, keySecurityScoreExtended],
        queryClient
      );
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

// Mobile Asset Queries

export const useApiUploadMobileBundle = () => {
  const queryClient = useQueryClient();
  return useMutation<MobileBundle, Error, MobileBundleUploadContext>({
    mutationKey: [mobileKey, "upload"],
    mutationFn: async ({
      mobile_app_bundle,
      assetId,
    }: MobileBundleUploadContext): Promise<{
      mobile_app_bundle_name: string;
    }> => await sendFile(`${key}/mobile/${assetId}`, mobile_app_bundle),
    onSuccess: (data: MobileBundle, { onSuccessCallback }) => {
      if (onSuccessCallback && data) onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};

export const useApiDeleteMobileBundle = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, MobileBundleDeleteContext>({
    mutationKey: [mobileKey, "delete"],
    mutationFn: async ({ assetId }: MobileBundleDeleteContext): Promise<any> =>
      await deleteUrl(`${key}/mobile/${assetId}`, {}),
    onSuccess: (data, { assetId, onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback();
      invalidateApiQueries([key, `${assetId}`], queryClient);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiMobileReport = (
  assetId: number | undefined,
  enabled: boolean
) =>
  useQuery<MobileReport | undefined, Error>({
    queryKey: [mobileKey, "report", assetId],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    refetchInterval: 60000 * 60, // 1 hour
    enabled: enabled,
    queryFn: async (): Promise<MobileReport | undefined> =>
      getSingleItem(mobileKey, `${assetId}`).then((data) =>
        data ? (data as MobileReport) : undefined
      ),
  });

export const useApiTestAssetConnection = () => {
  return useMutation<
    AssetCloudConnectionTestResponse,
    Error,
    TestAssetConnectionContext
  >({
    mutationKey: [key, "test_connection"],
    mutationFn: async ({
      cloud_credentials,
      source,
    }: TestAssetConnectionContext): Promise<AssetCloudConnectionTestResponse> =>
      await createItem(`${key}/test_connection`, {
        cloud_credentials,
        source,
      }),
    onSuccess: (
      data: AssetCloudConnectionTestResponse,
      { onSuccessCallback }
    ) => {
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
