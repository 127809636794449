import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";
import {
  KPILarge,
  LabelRegularHover,
} from "../../../../components/elements/typography/Typography";
import { FindingsCounts, OPEN_STATUSES } from "../../../../types/Finding";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { FindingDoughnutChart } from "../../../findings/findingsCharts/FindingDoughnutChart";
import { SkeletonLoading } from "../../../../components/elements/loading/SkeletonLoading";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { objectToBase64 } from "../../../../shared/helper";
import { findingsDefaultFilters } from "../../../findings/Findings";

ChartJS.register(ArcElement, Tooltip);

type Props = {
  findingsCounts: FindingsCounts;
  isFetching?: boolean;
  selectedProduct?: number | "all";
};

export const FindingsOverview = (props: Props) => {
  const { findingsCounts, isFetching, selectedProduct } = props;
  const theme = useContext(ThemeContext);

  return (
    <Flex align="end" justify="between" gap="16px" w100>
      <Flex w100>
        {isFetching && !findingsCounts?.open ? (
          <SkeletonLoading height="84px" width="69%" />
        ) : (
          <FindingDoughnutChart
            findingsCounts={findingsCounts}
            isLoading={isFetching && !findingsCounts?.open}
            selectedProduct={selectedProduct}
            filters={{ status: OPEN_STATUSES }}
            showOpenStatus
          />
        )}
      </Flex>
      <Flex gap="24px" align="center" w100 className="text-truncate ">
        <SeparatorVertical height="70px" />
        <Flex column gap="4px" style={{ overflow: "auto" }}>
          {isFetching && !findingsCounts?.open ? (
            <SkeletonLoading height="38px" width="38px" />
          ) : (
            <KPILarge>{findingsCounts?.breached_sla}</KPILarge>
          )}
          <Link
            to={`/findings?filters=${objectToBase64({
              ...findingsDefaultFilters,
              breached_sla: true,
              product: selectedProduct,
            })}`}
            className="d-flex align-items-center text-truncate w-100"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <LabelRegularHover
              className="text-truncate  w-100"
              title="Breached SLA"
              color={theme.textSecondary}
            >
              Breached SLA
            </LabelRegularHover>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
