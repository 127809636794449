import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { useApiMe } from "../../../hooks/queries/meContext";
import { light } from "../../../shared/theme";

export const ProjectObjectives = () => {
  const { data: me } = useApiMe();

  return (
    <Flex column w100 gap="8px" style={{ paddingTop: "48px" }}>
      <HeaderSecondary color={light.blue800}>
        Project Objectives
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        The project objectives were to identify cyber security vulnerabilities
        or potential weaknesses in cyber-attack vectors among{" "}
        {me?.customer.name || "Customer"}’s assets. The focus was on the
        published features available to end users and guests which could be
        challenged by an unauthorized and authorized threat actor. The assets in
        scope were tested for vulnerabilities that would be exploited in
        scenarios such as:
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <ul>
          <li>
            Potential theft or leak of customer and / or vendor data with
            respect to board ownership bypassing, workspace permissions, etc.
          </li>
          <li>Potential data manipulation that may damage reputation.</li>
          <li>
            Potential system misconfiguration that may allow an attacker to
            alter crucial data or reveal sensitive data.
          </li>
          <li>
            An attempt to determine whether, and how, a malicious user could
            manipulate data on the app to gain control and advantage.
          </li>
          <li>
            Potential bypass of application own security mechanisms designed to
            protect the system from improper behavior.
          </li>
        </ul>
      </BodyRegular>
      <BodyRegular color={light.black800}>
        This project followed a strict ‘PT boundaries definition’ to ensure full
        alignment between OP Innovate’s Red Team and customers’ single point of
        contact (SPOC) to ensure that any potential disruption to the production
        environment was avoided.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        We would like to thank {me?.customer.name || "Customer"} for their
        support and commitment throughout the project.
      </BodyRegular>
      <HeaderSubBold className="my-3" color={light.blue800}>
        Using the Wasp
      </HeaderSubBold>
      <BodyRegular color={light.black800}>
        The PT process included live reporting of critical and high severity
        findings via a dedicated Slack channel. <br /> Using OP’s WASP platform,
        findings were synced to {me?.customer.name || "Customer"}’s Jira in
        real-time in order to facilitate a short “discovery to mitigation”
        cycle. This project also includes re-testing of uncovered exploits after
        the requisite mitigation steps have been employed (e.g. patching), to
        confirm remediation of vulnerabilities found. The Red Team is using the
        Wasp’s automated re-testing features.{" "}
      </BodyRegular>
    </Flex>
  );
};
