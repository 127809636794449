import { useContext } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Assignee } from "../../../components/composed/assignee/Assignee";
import JiraIcon from "../../../assets/images/Jira.svg";
import WaspIcon from "../../../assets/images/Brand-logo.png";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import moment from "moment";
import { ThemeContext } from "styled-components";
import { Icon } from "../../../components/elements/icon/Icon";
import { getDateTime, getTime } from "../../../shared/helper";

type Props = {
  userName: string;
  avatarUrl?: string;
  createdAt: string;
};

export function CardHeader({ userName, avatarUrl, createdAt }: Props) {
  const theme = useContext(ThemeContext);
  return (
    <Flex align="center" gap="16px" w100>
      {userName ? (
        <Flex className="text-truncate">
          <Assignee
            name={userName}
            imageSrc={
              userName.trim() === "(JIRA)"
                ? JiraIcon
                : userName.trim() === "WASP"
                  ? WaspIcon
                  : avatarUrl
            }
          />
        </Flex>
      ) : (
        <Icon name="wasp" size={24} color={theme.primary} />
      )}
      <SeparatorVertical height="24px" />
      <LabelRegular
        style={{
          color: theme.black600,
          width: "30%",
        }}
        className="text-truncate"
        title={`${getDateTime(createdAt)} at ${getTime(createdAt)}`}
      >
        {moment(createdAt).fromNow()}
      </LabelRegular>
    </Flex>
  );
}
