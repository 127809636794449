import React, { ChangeEvent, useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { Box, CodeBox } from "../../../components/elements/box/Box";
import {
  BodyBold,
  BodyMedium,
  HeaderMain,
  HeaderSecondary,
  HeaderSub,
} from "../../../components/elements/typography/Typography";
import { UploadButton } from "../../../components/elements/button/upload/UploadButton";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { Illustration } from "../../../components/elements/illustrations/Illustration";
import mammoth from "mammoth";
import {
  useApiUpdateUserManual,
  useApiUserManualGetStatus,
} from "./userManualContext";
import { Loading } from "../../../components/elements/loading/Loading";
import useToastContext from "../../../hooks/toastHook";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { useNavigate } from "react-router";

export const UploadUserManual = () => {
  const navigate = useNavigate();
  const addToast = useToastContext();
  const { mutate: updateUserManual } = useApiUpdateUserManual();
  const { data: userManualStatus } = useApiUserManualGetStatus();
  const [userManualText, setUserManualText] = useState<string>("");

  const handleOnUpload = () => {
    if (userManualStatus) {
      updateUserManual({
        user_manual_text: userManualText,
        onSuccessCallback() {
          addToast({
            type: "success",
            message: "Initializing upload process - Await status updates.",
          });
        },
        onErrorCallback(error) {
          addToast({
            type: "error",
            message: `Oopsie! Something went wrong... ${error.message}`,
          });
        },
      });
    }
  };

  const handleOnSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const arrayBuffer = await file.arrayBuffer(); // Convert File to ArrayBuffer
    const uint8Array = new Uint8Array(arrayBuffer); // Convert to Uint8Array

    const result = await mammoth.extractRawText({
      arrayBuffer: uint8Array.buffer,
    });

    setUserManualText(result.value);
  };

  return (
    <Flex column gap="16px" style={{ height: "100vh", width: "100wh" }}>
      <Flex justify="between" column gap="16px">
        <HeaderMain>Update User Manual</HeaderMain>
        <HeaderSecondary>
          Upload an updated version of the user manual to enhance the accuracy
          of WASP AI's responses.
        </HeaderSecondary>
      </Flex>
      <Flex
        w100
        column
        style={{ marginTop: "64px" }}
        justify="center"
        align="center"
        gap="48px"
      >
        <Flex
          column
          gap="32px"
          style={{ width: "500px" }}
          justify="center"
          align="center"
        >
          <Box style={{ width: "100%" }}>
            <Flex
              gap="32px"
              justify="between"
              align="center"
              style={{ width: "100%" }}
            >
              <UploadButton
                label={"Select New User Manual"}
                onDelete={() => {
                  setUserManualText("");
                }}
                onClick={handleOnSelectFile}
              />
              <MainButton
                label={"Upload to AI"}
                size="medium"
                style={{ width: "220px" }}
                disabled={userManualText === ""}
                onClick={() => {
                  handleOnUpload();
                }}
              />
            </Flex>
          </Box>
          <Box style={{ width: "100%" }}>
            <Flex style={{ width: "100%" }} column gap="16px">
              <BodyBold>Status:</BodyBold>
              <CodeBox style={{ height: "100px" }}>
                <Flex w100 h100 justify="evenly" align="center" gap="16px">
                  {userManualStatus?.status ? (
                    <>
                      {userManualStatus.status !== "Done." && (
                        <Loading noTitle />
                      )}
                      <BodyMedium>{userManualStatus.status}</BodyMedium>
                    </>
                  ) : (
                    <BodyMedium>{"No process is running..."}</BodyMedium>
                  )}
                  {userManualStatus?.status &&
                    userManualStatus.status === "Done." && (
                      <TextButton
                        label={"Go to WASP AI"}
                        size="large"
                        onClick={() => {
                          navigate("/wasp-ai");
                        }}
                      />
                    )}
                </Flex>
              </CodeBox>
            </Flex>
          </Box>
        </Flex>
        <Flex column gap="16px" style={{ width: "300px" }} justify="center">
          <Illustration name="search" />
        </Flex>
        <HeaderSub>
          {"Be the hero WASP AI deserves—upload the manual and save the day!"}
        </HeaderSub>
      </Flex>
    </Flex>
  );
};
