import { ChangeEvent, useContext, useState } from "react";
import { Icon } from "../../components/elements/icon/Icon";
import { Modal } from "../../components/elements/modal/Modal";
import {
  HeaderSecondary,
  LabelBold,
  LabelRegular,
} from "../../components/elements/typography/Typography";
import { ThemeContext } from "styled-components";
import { Flex } from "../../components/layouts/flex/Flex";
import { MultiSelect } from "../../components/elements/dropdowns/MultiSelect";
import { MultiValue, SingleValue } from "react-select";
import { Dropdown, Option } from "../../components/elements/dropdowns/Dropdown";
import { useApiProjects } from "../../hooks/queries/projectsContext";
import { useApiProducts } from "../../hooks/queries/productsContext";
import { MainButton } from "../../components/elements/button/main/MainButton";
import { objectsToOptions } from "../../shared/formUtils";
import {
  dateObjToDateString,
  getCustomerNameUrlFormat,
} from "../../shared/helper";
import { Mixpanel } from "../../shared/mixpanel";
import { useApiMe } from "../../hooks/queries/meContext";
import { findingsTimeOptions } from "../findings/filters/Timeframe";
import {
  CustomTimeframe,
  funnelTimeOptions,
} from "../findings/findingsCharts/FindingsChartsContainer";
import { DatePicker } from "../../components/elements/datetimePicker/DatePicker";
import { Filters } from "../findings/Findings";
import { DAY_MS } from "../../shared/consts";
import { PrimaryBadge } from "../../components/elements/badge/PrimaryBadge";
import { TextButton } from "../../components/elements/button/text/TextButton";

export const reportTypesOptions: Option[] = [
  { label: "Full Report", value: "full-report" },
  { label: "Executive Report", value: "executive-summary-report" },
  { label: "Retest Report", value: "retest-report" },
  { label: "Attestation letter", value: "attestation-letter-report" },
  { label: "ASM Report", value: "asm-report" },
];

const today = new Date();

const timeframeOptionToProjectsStartAfterFilter = (
  option: SingleValue<Option>
) =>
  new Date(
    Date.now() - DAY_MS * parseInt(option?.value as string)
  ).toISOString();

export const ReportsGenV2 = ({ onClose }: { onClose: () => void }) => {
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const [closeMenus, setCloseMenus] = useState<boolean>(false);

  const [selectedReportType, setSelectedReportType] =
    useState<SingleValue<Option>>();

  const [timeframe, setTimeframe] = useState<SingleValue<Option>>(
    findingsTimeOptions[5]
  );
  const [isCustomTimeframe, setIsCustomTimeframe] = useState(false);
  const [customTimeframe, setCustomTimeframe] = useState<CustomTimeframe>({
    before: dateObjToDateString(today),
  });

  const [projectsFilters, setProjectsFilters] = useState<Filters>({});

  const [selectedProjects, setSelectedProjects] =
    useState<MultiValue<Option>>();

  const [selectedProducts, setSelectedProducts] =
    useState<MultiValue<Option>>();

  const { data: projects } = useApiProjects(
    projectsFilters,
    !!selectedReportType && selectedReportType?.value !== "asm-report"
  );

  const { data: asmProjects } = useApiProjects(
    { type: "asm" },
    selectedReportType?.value === "asm-report"
  );
  const asmProject = asmProjects?.[0];

  const projectsProducts = projects?.map((p) => p.products).flat();
  const { data: products } = useApiProducts(
    { id: projectsProducts },
    !!projectsProducts?.length || selectedReportType?.value === "asm-report"
  );

  const handleSelectProject = (options: MultiValue<Option>) => {
    const projectIds = options?.map((opt) => opt.value);
    const projectsObjs = projects?.filter((p) => projectIds?.includes(p.id));
    const productsIds = projectsObjs?.map((p) => p.products).flat();
    const productsObjs = products?.filter((p) => productsIds?.includes(p.id));
    setSelectedProducts(objectsToOptions(productsObjs || []));
  };

  const handleSelectReportType = (option: SingleValue<Option>) => {
    setSelectedReportType(option);
    if (option?.value === "asm-report")
      setSelectedProducts(objectsToOptions(products || []));
    else selectedProjects && handleSelectProject(selectedProjects);
  };

  const handleTimeframeSelect = (option: SingleValue<Option>) => {
    setTimeframe(option);
    setIsCustomTimeframe(option?.value === "-1");

    if (selectedReportType?.value !== "asm-report") {
      let newFilters: Filters = { ...projectsFilters };
      // All Times -  Remove all time filters
      if (option?.value === "0") {
        delete newFilters.start_after;
        delete newFilters.end_before;
      }

      // Custom Timeframe - match query filter to values
      else if (option?.value === "-1") {
        if (customTimeframe.before)
          newFilters.end_before = new Date(
            customTimeframe.before
          ).toISOString();
        else delete newFilters.end_before;

        if (customTimeframe.after)
          newFilters.start_after = new Date(
            customTimeframe.after
          ).toISOString();
        else delete newFilters.start_after;
      }

      // Predefined timeframe from options
      else {
        delete newFilters.end_before;
        newFilters.start_after =
          timeframeOptionToProjectsStartAfterFilter(option);
      }

      setProjectsFilters(newFilters);
    }
  };

  const handleCustomTimeframeStartAfterChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    // update display state
    setCustomTimeframe((prev) => ({
      ...prev,
      after: e.target.value,
    }));
    // update the query filter
    let newFilters: Filters = { ...projectsFilters };
    if (e.target.value)
      newFilters.start_after = new Date(e.target.value).toISOString();
    else delete newFilters.start_after;
    setProjectsFilters(newFilters);
  };

  const handleCustomTimeframeEndBeforeChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    // update display state
    setCustomTimeframe((prev) => ({
      ...prev,
      before: e.target.value,
    }));
    // update the query filter
    let newFilters: Filters = { ...projectsFilters };
    if (e.target.value)
      newFilters.end_before = new Date(e.target.value).toISOString();
    else delete newFilters.end_before;
    setProjectsFilters(newFilters);
  };

  const handleGenerateReport = () => {
    if (
      !me ||
      !selectedReportType ||
      !selectedProducts?.length ||
      (!selectedProjects?.length && selectedReportType.value !== "asm-report")
    )
      return;

    Mixpanel.track("Generate Report", {
      report_type: selectedReportType.label,
      timeframe: `${timeframe?.label}`,
      projectsCount:
        selectedReportType.value !== "asm-report"
          ? 1
          : selectedProjects?.length,
    });

    let paramsObj: { [key: string]: string } = {
      projectsIds:
        selectedReportType.value === "asm-report"
          ? asmProject?.id.toString() || ""
          : selectedProjects?.map((opt) => `${opt.value}`).join(",") || "",
      productsIds: selectedProducts?.map((opt) => `${opt.value}`).join(","),
    };

    // Add timeframe filters if not all times
    if (selectedReportType.value === "asm-report" && timeframe?.value !== "0") {
      if (projectsFilters.end_before)
        paramsObj["before"] = projectsFilters.end_before;
      if (projectsFilters.start_after)
        paramsObj["after"] = projectsFilters.start_after;
    }

    const urlCustomerName = getCustomerNameUrlFormat(me.customer.name);
    const params = new URLSearchParams(paramsObj).toString();
    window.open(
      `/${urlCustomerName}/reports/${selectedReportType.value}?${params}`
    );
  };

  return (
    <Modal
      keepOpenOnOutsideClick
      onClose={() => onClose()}
      header={
        <Flex align="center" gap="8px">
          <Icon name="export" color={theme.primary} size={32} />
          <HeaderSecondary>Reports Generator</HeaderSecondary>
        </Flex>
      }
      width="650px"
    >
      <Flex column gap="32px" onClick={() => setCloseMenus(true)}>
        <Flex column gap="8px" w100>
          <LabelBold>Report Type</LabelBold>
          <Dropdown
            closeMenuOnSelect
            isMenuPositionFixed
            variant="border"
            placeholder="Select Report Types"
            value={selectedReportType}
            onChange={handleSelectReportType}
            options={reportTypesOptions}
          />
        </Flex>

        <Flex w100 column gap="8px">
          <LabelBold>
            {selectedReportType?.value === "asm-report"
              ? "Findings "
              : "Projects "}
            Timeframe
          </LabelBold>
          <Flex w100 align="center" gap="24px">
            <Dropdown
              isMenuPositionFixed
              onChange={handleTimeframeSelect}
              options={funnelTimeOptions}
              value={timeframe}
              variant="border"
              width="190px"
              disabled={!selectedReportType}
            />

            {/* {isCustomTimeframe && ( */}
            <Flex
              align="center"
              gap="24px"
              style={{ opacity: isCustomTimeframe ? 1 : 0, transition: "0.2s" }}
            >
              <Flex align="center" gap="8px">
                <LabelRegular>After</LabelRegular>
                <DatePicker
                  value={customTimeframe.after}
                  max={customTimeframe.before}
                  onChange={handleCustomTimeframeStartAfterChange}
                />
              </Flex>
              <Flex align="center" gap="8px">
                <LabelRegular>Before</LabelRegular>
                <DatePicker
                  value={customTimeframe.before}
                  min={customTimeframe.after}
                  max={dateObjToDateString(today)}
                  onChange={handleCustomTimeframeEndBeforeChange}
                />
              </Flex>
            </Flex>
            {/* )} */}
          </Flex>
        </Flex>

        <Flex column gap="8px">
          <LabelBold>Projects</LabelBold>
          {selectedReportType?.value === "asm-report" ? (
            <PrimaryBadge content={asmProject?.name || ""} />
          ) : (
            <MultiSelect
              isBadged
              placeholder="Select Projects"
              itemsNameSingle="project"
              itemsNameMulti="projects"
              selected={selectedProjects}
              setSelected={setSelectedProjects}
              onSelect={handleSelectProject}
              options={projects ? objectsToOptions(projects) : []}
              closeMenu={closeMenus}
              onOpenMenu={() => setCloseMenus(false)}
              disabled={!selectedReportType}
            />
          )}
        </Flex>
        <Flex column gap="8px">
          <Flex align="center" w100 justify="between">
            <LabelBold>Products</LabelBold>
            <TextButton
              label="Select All"
              onClick={() =>
                products && setSelectedProducts(objectsToOptions(products))
              }
              disabled={
                !products?.length ||
                !selectedReportType ||
                (!selectedProjects?.length &&
                  selectedReportType?.value !== "asm-report")
              }
            />
          </Flex>
          <MultiSelect
            isBadged
            placeholder="Select Products"
            itemsNameSingle="product"
            itemsNameMulti="products"
            selected={selectedProducts}
            setSelected={setSelectedProducts}
            options={products ? objectsToOptions(products) : []}
            closeMenu={closeMenus}
            onOpenMenu={() => setCloseMenus(false)}
            disabled={
              !selectedReportType ||
              (!selectedProjects?.length &&
                selectedReportType?.value !== "asm-report")
            }
          />
        </Flex>
        <Flex justify="end">
          <MainButton
            label="Generate Report"
            onClick={handleGenerateReport}
            disabled={!selectedProducts?.length}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
