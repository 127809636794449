import { KPI } from "../../composed/kpi/KPI";
import { Flex } from "../../layouts/flex/Flex";
import { BodyRegular, BodyRegularHover } from "../typography/Typography";

type Props = {
  label: string;
  value?: number;
  color: string;
  onClick?: (e: any, label: string) => void;
  isOn?: boolean;
  ellipseSize?: number;
  labelSize?: number;
  labelColor?: string;
  hideLegendText?: boolean;
};

export const LegendItem = (props: Props) => {
  const {
    label,
    value,
    onClick,
    color,
    ellipseSize,
    labelSize,
    labelColor,
    hideLegendText,
    isOn = true,
  } = props;

  return (
    <Flex
      align="center"
      gap="8px"
      className={!!onClick ? "pointer" : ""}
      onClick={(e) => (!!onClick ? onClick(e, label) : {})}
    >
      <Flex align="center">
        <span
          style={{
            width: ellipseSize || "8px",
            height: ellipseSize || "8px",
            background: color,
            borderRadius: "50%",
          }}
        />
      </Flex>

      {value !== undefined && (
        <KPI
          count={value}
          style={{ fontSize: "12px", lineHeight: "14px", fontWeight: "400" }}
        />
      )}

      {!!onClick && !hideLegendText ? (
        <BodyRegularHover
          color={labelColor}
          style={{
            textDecoration: isOn ? "" : "line-through",
            fontSize: `${labelSize || 14}px`,
          }}
        >
          {label}
        </BodyRegularHover>
      ) : !hideLegendText ? (
        <BodyRegular
          color={labelColor}
          style={{
            fontSize: `${labelSize || 14}px`,
          }}
        >
          {label}
        </BodyRegular>
      ) : null}
    </Flex>
  );
};
