import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useApiMe } from "../../../hooks/queries/meContext";
import { light } from "../../../shared/theme";

export const AsmExecutiveSummary = () => {
  const { data: me } = useApiMe();
  const customerName = me?.customer.name || "N/A";

  return (
    <Flex column w100 gap="8px" className="mt-5 fully-justify">
      <HeaderSecondary color={light.blue800}>Executive Summary</HeaderSecondary>
      <BodyRegular color={light.black800}>
        {`
        ${customerName}, an Attack Surface Management (ASM) customer, engaged OP Innovate
        to enhance its security posture. As part of our ASM service, we scanned
        ${customerName}'s assets to assess their security risk level. The assessment
        identified two high-risk vulnerabilities that could pose a threat to
        ${customerName}'s applications. Despite these findings, the evaluation highlighted
        significant efforts by ${customerName} to maintain a robust security posture, and
        further recommendations were provided for mitigation and improvement.`}
      </BodyRegular>
    </Flex>
  );
};
