import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { light } from "../../../shared/theme";

export const AppendixA = () => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{
        pageBreakAfter: "always",
        backgroundColor: "transparent",
        height: "auto",
        paddingTop: "48px",
      }}
    >
      <HeaderSecondary color={light.blue800}>
        Appendix A: Testing methodology
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        The Red Team’s methodology included the following elements:
        <ul>
          <li className="fw-bold">Various tests in order to identify:</li>
          <ul>
            <li>Vulnerable functions.</li>
            <li>Known vulnerabilities.</li>
            <li>Unsanitized Input.</li>
            <li>Vulnerable functions.</li>
            <li>Malformed and user manipulated output.</li>
            <li>Coding errors and security weaknesses.</li>
            <li>Unhandled overload scenarios.</li>
            <li>Information leakage.</li>
          </ul>
          <li className="fw-bold">
            Automatic tools are used in order to identify security related
            issues in the code or the application.
          </li>
          <li className="fw-bold">
            After an automated review, thorough manual tests are performed
            regarding topics such as:
          </li>
          <ul>
            <li>
              <strong>Security functions: </strong>Checking whether security
              functions exist, whether they operate based on a White List of a
              Black List, and whether they can be bypassed.
            </li>
            <li>
              <strong>Authentication mechanism: </strong>The structure of the
              identification mechanism, checking session ID's strength, securing
              the identification details on the client side, bypassing through
              use of mechanisms for changing passwords, recovering passwords,
              etc.
            </li>
            <li>
              <strong>Authorization policy: </strong>Verifying the
              implementation of the authorization validation procedures, whether
              they are implemented in all the application's interfaces, checking
              for a variety of problems, including forced browsing, information
              disclosure, directory listing, path traversal.
            </li>
            <li>
              <strong>Encryption policy: </strong>Checking whether encryption
              mechanisms are implemented in the application, and whether these
              are robust/known mechanisms or ones that were developed in-house,
              decoding scrambled data.
            </li>
            <li>
              <strong>Cache handling: </strong>Checking whether important
              information is not saved in the cache memory on the client side,
              and whether cache poisoning attacks can be executed.
            </li>
            <li>
              <strong>Logoff mechanism: </strong>Checking whether users are
              logged off in a controlled manner after a predefined period of
              inactivity in the application, and whether information that can
              identify the user is saved after he has logged off.
            </li>
            <li>
              <strong>Input validation: </strong>Checking whether stringent
              intactness tests are performed on all the parameters received from
              the user, such as matching the values to the types of parameters,
              whether the values meet maximal and minimal length requirements,
              whether obligatory fields have been filled in, checking for
              duplication, filtering dangerous characters, SQL / Blind SQL
              injection.
            </li>
            <li>
              <strong>Information leakage: </strong>Checking whether essential
              or sensitive information about the system is not leaking through
              headers or error messages, comments in the code, debug functions,
              etc.
            </li>
            <li>
              <strong>Administration settings: </strong>Verifying that the
              connection strings are encrypted and that custom errors are used.
            </li>
            <li style={{ pageBreakAfter: "always" }}>
              <strong>Administration files: </strong>Verifying that the
              administration files are separate from the application and that
              they can be accessed only via a robust identification mechanism.
            </li>
            <li className="mt-5">
              <strong>
                Supervision, documentation and registration functions:{" "}
              </strong>
              Checking the documentation and logging mechanism for all the
              significant actions in the application, checking that the logs are
              saved in a secure location, where they cannot be accessed by
              unauthorized parties.
            </li>
            <li>
              <strong>Error handling: </strong>Checking whether the error
              messages that are displayed are general and do not include
              technical data, and whether the application is operating based on
              the fail safe principle.
            </li>
          </ul>
          <li className="fw-bold">
            In-depth manual tests of application's business logic and complex
            scenarios.
          </li>
          <li className="fw-bold">
            Review of possible attack scenarios, presenting exploit methods and
            POCs.
          </li>
          <li className="fw-bold">
            Alignment of applicative TTPs (Tactics, Techniques and Procedures)
            with the MITRE ATT&CK Matrix
          </li>
          <li className="fw-bold">
            Test results: a detailed report which summarizes the findings,
            including their:
          </li>
          <ul>
            <li>Description.</li>
            <li>Risk level.</li>
            <li>Probability of exploitation.</li>
            <li>Details.</li>
            <li>Mitigation recommendations.</li>
            <li>Screenshots and detailed exploit methods.</li>
          </ul>
        </ul>
      </BodyRegular>
    </Flex>
  );
};
