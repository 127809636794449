import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { MainButton } from "../../../components/elements/button/main/MainButton";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
  LabelBold,
  LabelMedium,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import {
  Asset,
  AssetEdit,
  AssetStatus,
  assetStatusOptions,
} from "../../../types/Asset";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { AssetDetailsBox } from "./AssetDetailsBox";
import { WebDetailsBox } from "./WebDetailsBox";
import {
  isTwoStringsArraysEqual,
  toBase64AssetsView,
} from "../../../shared/helper";
import {
  useApiAsset,
  useApiDeleteAsset,
  useApiUpdateAsset,
} from "../../../hooks/queries/assetsContext";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { convertAssetToEdit, margeEditAndAsset } from "../AssetUtils";
import useToastContext from "../../../hooks/toastHook";
import RelatedVulnerabilitiesBox from "./RelatedVulnerabilitiesBox";
import { useMutationWithDelay } from "../../../hooks/utilsHooks";
import { Link, useSearchParams } from "react-router-dom";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { AssetTech } from "./AssetTech";
import { Mixpanel } from "../../../shared/mixpanel";
import { Loading } from "../../../components/elements/loading/Loading";
import { ThemeContext } from "styled-components";
import { DeleteModal } from "../../../components/composed/deleteModal/DeleteModal";
import { AssetVerificationIndicator } from "../assetVerification/AssetVerificationIndicator";
import { NoDataAvailable } from "../../insights/NoDataAvailable";
import { emptyAssetsView } from "../filters/FiltersUtils";
import { AssetActivityLogs } from "../assetComments/AssetActivityLogs";
import { AssetWhois } from "./AssetWhois";
import { AssetCertificate } from "./AssetCertificate";
import { AssetConnections } from "./AssetConnections";
import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import { useApiMe } from "../../../hooks/queries/meContext";
import { AssetSchema } from "./AssetSchema";
import { AssetMobileBox } from "./AssetMobileBox";
import { AssetCloudDetails } from "./AssetCloudDetails";
import { AssetCloudForm } from "../addAssets/AssetCloudForm";
import { Box } from "../../../components/elements/box/Box";
import { AssetStatusBadge } from "../assetStatus/AssetStatusBadge";
import { AssetStatusDropdown } from "../assetStatus/AssetStatusDropdown";
import { Tab, Tabs } from "../../../components/elements/tabs/Tabs";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { WarningModal } from "../../../components/composed/warningModal/WarningModal";

type Props = {
  onClose: () => void;
  assetId: number;
  setShowVerifyAssetModal?: Dispatch<SetStateAction<number>>;
};

export const AssetPane = (props: Props) => {
  const { onClose, assetId, setShowVerifyAssetModal } = props;
  useTrackPage("Assets - Panel", { asset_id: assetId });
  const addToast = useToastContext();
  const { data: me } = useApiMe();
  const theme = useContext(ThemeContext);
  const [searchParams] = useSearchParams();
  const { data: products } = useApiProducts();

  // Get asset by id
  const { data: assetProp, isLoading: isLoadingAsset } = useApiAsset(
    assetId,
    searchParams.has("admin-mode")
  );
  const [asset, setAsset] = useState<Asset | undefined>(assetProp);
  const [editAsset, setEditAsset] = useState<AssetEdit | undefined>(
    convertAssetToEdit(assetProp)
  );

  // By default the pane is on view mode, edit is false
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // By default the pane is on "main" tab
  // Show comment section tab (activity-logs) if redirected from notification
  const viewedCommentId = searchParams.get("commentId");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);

  const { mutate: updateAsset, isLoading } = useApiUpdateAsset();
  const {
    mutate: deleteAsset,
    isLoading: isDeleting,
    isSuccess,
  } = useApiDeleteAsset();
  const { runMutation, isRunning: isInDelay } = useMutationWithDelay(3000);

  useEffect(() => {
    if (!assetProp || !!asset) return;
    setAsset(assetProp);
    setEditAsset(convertAssetToEdit(assetProp));
  }, [assetProp, asset]);

  const hasChanges = (): boolean => {
    return (
      editAsset?.environment !== asset?.environment ||
      editAsset?.is_asm_enabled !== asset?.is_asm_enabled ||
      editAsset?.priority !== asset?.priority ||
      editAsset?.product !== asset?.product ||
      editAsset?.source !== asset?.source ||
      editAsset?.status !== asset?.status ||
      editAsset?.parent_asset !== asset?.parent_asset ||
      JSON.stringify(editAsset?.cloud_credentials || {}) !==
        JSON.stringify(asset?.cloud_credentials || {}) ||
      !isTwoStringsArraysEqual(editAsset?.tags, asset?.tags)
    );
  };

  const assetView = { ...emptyAssetsView };
  assetView.filters = [
    {
      column: "name",
      value: asset?.name || "",
      condition: "is",
      order: 0,
      next_condition: "and",
    },
  ];
  const encodedAssetView = toBase64AssetsView(assetView);

  const onUpdateAsset = () => {
    if (!!!editAsset) {
      addToast({ message: "No asset selected", type: "error" });
      return;
    }
    runMutation(
      () =>
        updateAsset({
          assetId: assetId,
          assetData: { ...editAsset },
          onSuccessCallback: (updatedAsset) => {
            !!asset &&
              setAsset(
                margeEditAndAsset(
                  { ...updatedAsset },
                  editAsset,
                  products || []
                )
              );
            setIsEditable(false);
            addToast({
              message: `Asset "${updatedAsset.name}" updated successfully `,
              type: "success",
            });
          },
          onErrorCallback: (err) => {
            addToast({
              message: `Failed to update asset "${asset?.name}" - ${err.message}`,
              type: "error",
            });
          },
        }),
      `Updating asset "${asset?.name}"`
    );
  };

  const handleDeleteAsset = () => {
    asset &&
      deleteAsset({
        assetId: assetId,
        assetData: asset,
        onSuccessCallback: () => {
          addToast({ type: "success", message: "Asset has been deleted" });
          setShowDeleteModal(false);
          onClose();
        },
        onErrorCallback: () =>
          addToast({ type: "error", message: "Failed to delete asset" }),
      });
  };

  const handleSave = () => {
    Mixpanel.track("Asset Pane - update asset", editAsset || {});
    if (editAsset?.status !== "live" && asset?.status === "live") {
      setShowStatusChangeModal(true);
    } else {
      onUpdateAsset();
    }
  };

  return (
    <>
      <RightPane onClose={onClose} width="1008px">
        {isLoadingAsset ? (
          <Flex column h100 w100 justify="center" align="center">
            <Loading />
          </Flex>
        ) : !isLoadingAsset && !asset ? (
          <NoDataAvailable />
        ) : (
          <Flex
            column
            gap="24px"
            justify="between"
            style={{
              height: "100vh",
            }}
          >
            <Flex
              column
              gap="24px"
              padding="32px"
              style={{
                overflowY: "auto",
                marginRight: "10px",
              }}
            >
              {!isLoadingAsset && me?.customer.id !== asset?.customer && (
                <Flex w100 style={{ marginBottom: "16px" }}>
                  <AlertBanner
                    message={
                      <>
                        <LabelBold>Attention!</LabelBold>
                        <LabelMedium>
                          You are viewing an asset of a different customer from
                          the one you are assigned to.
                        </LabelMedium>
                      </>
                    }
                    type="warning"
                  />
                </Flex>
              )}
              <Flex column gap="16px">
                <Flex
                  align="center"
                  justify="between"
                  gap="16px"
                  className="w-100"
                >
                  <Tooltip
                    content={asset?.name || ""}
                    placement="bottom"
                    isTextTruncate
                  >
                    <HeaderSecondary
                      className="text-truncate"
                      style={{
                        maxWidth: "600px",
                        textTransform: "none",
                      }}
                    >
                      {asset?.name}
                    </HeaderSecondary>
                  </Tooltip>

                  {isEditable ? (
                    <Flex align="center" gap="8px">
                      <LabelRegular>Change Status:</LabelRegular>
                      <AssetStatusDropdown
                        width="180px"
                        value={assetStatusOptions.find(
                          (option) => option.value === editAsset?.status
                        )}
                        onChange={(option) =>
                          setEditAsset(
                            editAsset
                              ? {
                                  ...editAsset,
                                  status: option?.value as AssetStatus,
                                }
                              : undefined
                          )
                        }
                      />
                    </Flex>
                  ) : (
                    <Flex align="center" gap="16px">
                      <SecondaryButton
                        size="small"
                        iconName="edit"
                        label="Edit"
                        onClick={() => {
                          setIsEditable(true);
                          Mixpanel.track("Asset Pane - Click on 'Edit' button");
                        }}
                        dataTestId="edit-asset-button"
                      />
                      <Link
                        to={`/assets/map?view=${encodedAssetView}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <MainButton
                          size="small"
                          iconName="map"
                          label="View on Map"
                          onClick={() => {}}
                        />
                      </Link>
                    </Flex>
                  )}
                </Flex>
                <Flex align="center" gap="12px">
                  {!!asset?.status && (
                    <AssetStatusBadge status={asset.status} />
                  )}
                  <AssetVerificationIndicator
                    asset={asset}
                    setShowVerifyAssetModal={setShowVerifyAssetModal}
                    onOpenModal={onClose}
                  />
                </Flex>
              </Flex>

              <Tabs selectedInitialTab={!!viewedCommentId ? 3 : 0}>
                <Tab title="Overview">
                  <>
                    <AssetDetailsBox
                      asset={asset}
                      isEditable={isEditable}
                      editAsset={editAsset}
                      setEditAsset={setEditAsset}
                    />
                    {isEditable &&
                      editAsset &&
                      asset?.type === "cloud" &&
                      !asset.parent_asset && (
                        <Box>
                          <AssetCloudForm
                            formValues={editAsset}
                            setFormValues={
                              setEditAsset as Dispatch<
                                SetStateAction<AssetEdit>
                              >
                            }
                          />
                        </Box>
                      )}
                    <AssetTech asset={asset} />
                    {asset?.type === "domain" && <AssetSchema asset={asset} />}
                    {asset?.type === "mobile" && (
                      <AssetMobileBox assetId={asset.id} />
                    )}
                  </>
                </Tab>

                <Tab
                  title={
                    asset?.type === "cloud" ? "Resource Details" : "Web Details"
                  }
                  onClick={() => {
                    Mixpanel.track("Asset Pane - Click on 'Web Details' tab");
                  }}
                >
                  {asset?.type === "cloud" ? (
                    <AssetCloudDetails asset={asset} />
                  ) : (
                    <>
                      <WebDetailsBox asset={asset} />
                      <AssetCertificate asset={asset} />
                      <AssetWhois asset={asset} />
                    </>
                  )}
                </Tab>
                <Tab
                  title={"Findings"}
                  onClick={() => {
                    Mixpanel.track("Asset Pane - Click on 'Findings' tab");
                  }}
                >
                  <RelatedVulnerabilitiesBox asset={asset} />
                </Tab>
                <Tab
                  title="Activity Log"
                  onClick={() => {
                    Mixpanel.track("Asset Pane - Click on 'Activity Logs' tab");
                  }}
                >
                  <AssetActivityLogs asset={asset} />
                </Tab>
                {(asset?.third_party_connections?.length || 0) > 0 && (
                  <Tab
                    title={"Connections"}
                    onClick={() => {
                      Mixpanel.track("Asset Pane - Click on 'Connections' tab");
                    }}
                  >
                    <AssetConnections
                      connections={asset?.third_party_connections}
                    />
                  </Tab>
                )}
              </Tabs>
            </Flex>
            {isEditable && (
              <Flex column w100 gap="16px">
                <SeparatorHorizontal />
                <Flex
                  align="center"
                  justify="between"
                  gap="16px"
                  padding="4px 32px 32px 32px"
                >
                  <SecondaryButton
                    variant="danger"
                    label="Remove asset"
                    onClick={() => setShowDeleteModal(true)}
                    iconName="remove"
                    size="small"
                  />
                  <Flex align="center" gap="16px">
                    <TextButton
                      label="Cancel"
                      onClick={() => setIsEditable(false)}
                      dataTestId="cancel-asset-button"
                      color={theme.black900}
                    />
                    <MainButton
                      size="small"
                      iconName="save"
                      dataTestId="save-asset-button"
                      label="Save"
                      disabled={!hasChanges()}
                      inProgress={isLoading || isInDelay}
                      onClick={handleSave}
                    />
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </RightPane>
      {showDeleteModal && (
        <DeleteModal
          isSuccess={isSuccess}
          itemName={asset?.name || ""}
          onConfirm={handleDeleteAsset}
          onClose={() => setShowDeleteModal(false)}
          isLoading={isDeleting}
          itemType="Asset"
        />
      )}
      {showStatusChangeModal && (
        <WarningModal
          header="Change asset status?"
          body={
            <Flex
              column
              justify="center"
              align="center"
              className="text-center"
            >
              <BodyRegular>
                This asset will be marked as{" "}
                <BodyBold>
                  {
                    assetStatusOptions.find(
                      (o) => o.value === editAsset?.status
                    )?.label
                  }
                </BodyBold>{" "}
                and will be ignored in future scans
              </BodyRegular>
              <BodyRegular>(no alerts will be triggered for it) </BodyRegular>
            </Flex>
          }
          onApprove={() => {
            onUpdateAsset();
            setShowStatusChangeModal(false);
          }}
          onClose={() => setShowStatusChangeModal(false)}
          approveButtonLabel="Save anyway"
        />
      )}
    </>
  );
};
