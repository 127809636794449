import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyBold,
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { light } from "../../../shared/theme";
import { getRiskColor } from "../../../shared/findingsHelper";

export const AppendixB = () => {
  return (
    <Flex column w100 gap="8px" style={{ paddingTop: "48px" }}>
      <HeaderSecondary color={light.blue800}>
        Appendix B: Findings classification
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        The finding’s impact relates to the severity of the consequences the
        organization might suffer if the risk will materialize. The impact level
        can be one of the following options, and is determined by the specific
        attack scenario:{" "}
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <BodyBold color={light[getRiskColor(4)]}>Critical </BodyBold>- Critical
        level findings are those that may cause a significant business damage to
        the organization, such as:{" "}
        <ul>
          <li>Significant data leakage</li>
          <li>Denial of Service to essential systems</li>
          <li>
            Gaining control of the organization’s resources (For example:
            Servers, Routers, etc)
          </li>
        </ul>
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <BodyBold color={light[getRiskColor(3)]}>High </BodyBold>- High level
        findings are those that may cause damage to the organization, such as:
        <ul>
          <li>Data leakage</li>
          <li>Execution of unauthorized actions</li>
          <li>Insecure communication</li>
          <li>Denial of Service</li>
          <li>Bypassing security mechanisms</li>
          <li>Inflicting various business damage</li>
        </ul>
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <BodyBold color={light[getRiskColor(2)]}>Medium </BodyBold>- Medium
        level findings those that which may increase the probability of carrying
        out attacks, or perform a small amount of damage to the organization,
        such as:
        <ul>
          <li>Findings which makes it easier to perform other attacks</li>
          <li>
            Findings which may increase the amount of damage which an attacker
            can inflict, once he carries out a successful attack
          </li>
          <li>
            Findings which may inflict a low level of damage to the organization
          </li>
        </ul>
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <BodyBold color={light[getRiskColor(1)]}>Low </BodyBold>- Low level
        findings those that which may inflict marginal damage to the
        organization, or assist the attacker when performing an attack, such as:
        <ul>
          <li>
            Providing the attacker with valuable information to help plan the
            attack
          </li>
          <li>
            Findings which may inflict marginal damage to the organization
          </li>
          <li>
            Findings which may slightly help the attacker when carrying out an
            attack, or remaining undetected{" "}
          </li>
        </ul>
      </BodyRegular>
      <BodyRegular color={light.black800}>
        <BodyBold color={light[getRiskColor(0)]}>Informative </BodyBold>-
        Informative findings are those without any information security impact.
        However, they are still brought to the attention of the organization.
      </BodyRegular>
    </Flex>
  );
};
