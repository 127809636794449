import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../../components/elements/button/icon/IconButton";
import { CircularProgressBar } from "../../../../components/elements/progressBar/CircularProgressBar";
import { Column, Table } from "../../../../components/elements/table/Table";
import { TableSkeleton } from "../../../../components/elements/table/TableSkeleton";
import {
  BodyBold,
  BodyBoldHover,
  BodyMedium,
  BodyMediumHover,
  BodyRegular,
  BodyRegularHover,
} from "../../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../../hooks/queries/productsContext";
import { useApiProjects } from "../../../../hooks/queries/projectsContext";
import { getDate, objectToBase64 } from "../../../../shared/helper";
import { Product } from "../../../../types/Product";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useApiProductsRiskScores } from "../../../insights/findingsTrendsGraph/findingsTrendsContext";

export const ProductsTable = ({
  searchWord,
  isProductsEditable,
  setEditedProduct,
  size,
  selectedProduct,
}: {
  searchWord?: string;
  isProductsEditable?: boolean;
  setEditedProduct?: React.Dispatch<React.SetStateAction<Product | undefined>>;
  size?: "small" | "medium";
  selectedProduct?: number | "all";
}) => {
  const navigate = useNavigate();
  const { data: products, isFetching: isProductFetching } = useApiProducts({
    with_findings_counts: true,
  });

  const { data: projects, isFetching: isProjectFetching } = useApiProjects();
  const { data: productRiskScores, isFetching: isFetchingProdRiskScores } =
    useApiProductsRiskScores();

  const isFetching =
    isProductFetching || isProjectFetching || isFetchingProdRiskScores;

  const search = (prods: Product[]): Product[] =>
    prods.filter(
      (p) => p.name.toLowerCase().indexOf(`${searchWord}`.toLowerCase()) > -1
    );

  const singleSelectedProd = (): Product[] =>
    products?.filter((p) => p.id === selectedProduct) || [];

  const filteredProducts = () => {
    if (searchWord) {
      return search(products || []);
    } else if (selectedProduct && selectedProduct !== "all") {
      return singleSelectedProd();
    } else {
      return products || [];
    }
  };

  const theme = useContext(ThemeContext);
  const columns: Column[] = [
    {
      title: "Product Name",
      key: "name",
      sortable: true,
      cell: (row) => (
        <Link
          to={`/findings?filters=${objectToBase64({ products: [row.id] })}`}
          style={{ textDecoration: "none", color: "inherit" }}
          className="text-truncate w-100"
        >
          <BodyRegularHover
            onClick={() => navigate(`/findings?products=${row.id}`)}
            className="text-truncate w-100"
            title={row.name}
          >
            {row.name}
          </BodyRegularHover>
        </Link>
      ),
    },
    {
      title: "Open Findings",
      key: "openFindings",
      sortable: true,
      centerCell: true,
      cell: (row) => (
        <Link
          to={`/findings?filters=${objectToBase64({
            status: "presented,in_progress,re_test,fixed",
            products: [row.id],
          })}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <BodyBoldHover>{row.openFindings}</BodyBoldHover>
        </Link>
      ),
    },
    {
      title: "High & Critical",
      key: "highCritical",
      sortable: true,
      centerCell: true,
      cell: (row) => (
        <Link
          to={`/findings?filters=${objectToBase64({
            status: "presented,in_progress,re_test,fixed",
            overall_risk: "4,3",
            products: [row.id],
          })} `}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <BodyBoldHover>{row.highCritical}</BodyBoldHover>
        </Link>
      ),
    },
    {
      title: "SLA Breaches",
      key: "breachedSla",
      sortable: true,
      cell: (row) => {
        if (row.breachedSla === 0)
          return (
            <BodyMedium style={{ color: theme.black600 }}>
              No breaches
            </BodyMedium>
          );
        return (
          <Link
            to={`/findings?filters=${objectToBase64({
              breached_sla: true,
              products: [row.id],
            })} `}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <BodyMediumHover
              style={{
                color: row.breachedSla > 3 ? theme.redPrimary : theme.primary,
              }}
            >
              {row.breachedSla} Breaches
            </BodyMediumHover>
          </Link>
        );
      },
    },
    {
      title: "Next Activity",
      key: "nextActivity",
      sortable: true,
      centerCell: true,
      cell: (row) => (
        <BodyRegular>
          {row.nextActivity ? getDate(row.nextActivity) : "Not scheduled"}
        </BodyRegular>
      ),
    },
    {
      title: "Risk Score",
      key: "riskScore",
      sortable: true,
      centerCell: true,
      cell: (row) => <BodyBold className="ms-3">{row.riskScore}</BodyBold>,
    },
    {
      title: "Protection Coverage",
      key: "coverageScore",
      sortable: true,
      centerCell: true,
      cell: (row) => {
        const color = row.coverageScore > 50 ? theme.primary : theme.redPrimary;
        const background = row.coverageScore > 50 ? theme.blue100 : theme.red50;
        return (
          <CircularProgressBar
            color={color}
            background={background}
            value={row.coverageScore || 0}
          />
        );
      },
    },
    {
      title: "",
      key: "",
      maxWidth: "50px",
      isHidden: !isProductsEditable,
      cell: (row) => (
        <IconButton
          iconName="chevronRight"
          onClick={() => setEditedProduct && setEditedProduct(row.product)}
          dataTestId={`open-product-${row.id}`}
        />
      ),
    },
  ];

  const rows = filteredProducts()?.map((product) => ({
    id: `${product.id} `,
    name: product.name,
    openFindings: product.findings_counts?.open || 0,
    highCritical:
      (product.findings_counts?.open_criticals || 0) +
      (product.findings_counts?.open_highs || 0),
    breachedSla: product.findings_counts?.breached_sla || 0,
    nextActivity: projects
      ?.filter((p) => p.products.includes(product.id))
      ?.sort(
        (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
      )?.[0]?.start,
    riskScore: productRiskScores?.find((p) => p.product_id === product.id)
      ?.risk_score,
    coverageScore: product.coverage_score || 0,
    product,
  }));

  if (isFetching && !rows.length)
    return (
      <div className="h-100">
        <TableSkeleton />
      </div>
    );
  return (
    <Table
      columns={columns}
      rows={rows}
      minWidth={560}
      size={size || "medium"}
    />
  );
};
