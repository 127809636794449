import { Modal } from "../../components/elements/modal/Modal";
import { Flex } from "../../components/layouts/flex/Flex";
import { BodyBold } from "../../components/elements/typography/Typography";

export const ReportsLoader = ({
  setLoadingData,
  countdown,
}: {
  setLoadingData: (state: boolean) => void;
  countdown: number;
}) => {
  return (
    <Modal onClose={() => setLoadingData(false)} hideCloseIcon>
      <Flex align="center" gap="6px">
        <BodyBold>{`Generating PDF report in ${
          countdown > 0 ? countdown : "just a sec"
        }...`}</BodyBold>
      </Flex>
    </Modal>
  );
};
