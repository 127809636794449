import styled from "styled-components";
import { StyleLinkButton } from "../link/LinkButton.style";
type ButtonProps = {
  selected: boolean;
  size: "small" | "medium" | "large";
};

export const StyleMenuButton = styled(StyleLinkButton)<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  margin-top: 22px;
  height: 77px;
  width: 97px;
  font-weight: ${(props) => (props.selected ? 700 : 400)}};
  color: ${(props) =>
    props.selected ? props.theme.blue : props.theme.black600};
  &:hover:enabled {
    font-weight: 700;
    color: ${(props) => props.theme.blue} !important;

  }
  &:hover:enabled path {
    color: ${(props) => props.theme.blue};
    stroke: ${(props) => props.theme.blue};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    color:${(props) => props.theme.separation};
  }
`;
