import { ChangeEvent } from "react";
import { Flex } from "../../layouts/flex/Flex";
import { LabelRegular } from "../typography/Typography";
import { DatePicker } from "./DatePicker";

type Props = {
  start: string;
  end: string;
  onChangeStart: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeEnd: (e: ChangeEvent<HTMLInputElement>) => void;
  labelPosition?: "top" | "left";
  gap?: string;
};

export const RangeDatePicker = (props: Props) => {
  const {
    start,
    end,
    onChangeStart,
    onChangeEnd,
    labelPosition = "top",
    gap = "16px",
  } = props;
  return (
    <Flex gap={gap}>
      <Flex
        gap="8px"
        column={labelPosition === "top"}
        align={labelPosition === "top" ? "start" : "center"}
      >
        <LabelRegular>Start Date</LabelRegular>
        <DatePicker value={start || ""} onChange={onChangeStart} />
      </Flex>
      <Flex
        gap="8px"
        column={labelPosition === "top"}
        align={labelPosition === "top" ? "start" : "center"}
      >
        <LabelRegular>End Date</LabelRegular>
        <DatePicker value={end || ""} onChange={onChangeEnd} />
      </Flex>
    </Flex>
  );
};
