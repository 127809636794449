import { useState } from "react";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { PageLayout } from "../generator/PageLayout";
import { ReportsLoader } from "../ReportsLoader";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { useApiFindings } from "../../../hooks/queries/findingContext";
import { useSearchParams } from "react-router-dom";
import { STATUSES } from "../../../types/Finding";
import { useQueryClient } from "@tanstack/react-query";
import { useApiMe } from "../../../hooks/queries/meContext";
import { FirstPage } from "../generator/FirstPage";
import {
  DisclaimerPage,
  MultiTenantDisclaimerPage,
} from "../generator/DisclaimerPage";
import { AboutPage } from "../generator/AboutPage";
import { TableOfContents } from "../generator/TableOfContents";
import { AsmExecutiveSummary } from "./AsmExecutiveSummary";
import { AppendixB } from "../generator/AppendixB";
import { SummaryOfResults } from "../generator/SummaryOfResults";
import { ResultsAtGlance } from "../generator/ResultsAtGlance";
import { FindingsInDetails } from "../fullReport/FindingsInDetails";
import { FindingPage } from "../fullReport/FindingPage";
import { Filters } from "../../findings/Findings";
import "../reports.css";

const WAITING_TIME = 12; //seconds

export const AsmReports = () => {
  const { data: me } = useApiMe();

  const [searchParams] = useSearchParams();
  const after = searchParams.get("after");
  const before = searchParams.get("before");
  const productsIdsStr = searchParams.get("productsIds");
  const productsIds = productsIdsStr?.split(",").map((id) => parseInt(id));

  const { data: projects, isFetching: isFetchingProject } = useApiProjects({
    type: "asm",
  });
  // Each customer has only one ASM project
  const project = !!projects?.length ? projects[0] : undefined;

  const getFindingsQueryParams = (): Filters => {
    let filters: Filters = {
      page_size: 1000,
      project: project?.id,
      products: productsIds,
      status: STATUSES.filter((s) => s !== "dismissed").join(","), // Exclude dismissed findings
      all_data: true,
      is_automated: true,
      is_false_positive: false,
      is_pending: false,
      ordering: "-overall_risk",
    };
    if (after) filters = { ...filters, created_at_after: after };
    if (before) filters = { ...filters, created_at_before: before };
    return filters;
  };

  const isQueryEnabled = !!project && !!productsIds?.length;
  const { data: findings, isFetching: isFetchingFindings } = useApiFindings(
    getFindingsQueryParams(),
    isQueryEnabled
  );

  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);

  // Check if there are any background fetching requests
  const queryClient = useQueryClient();
  const isFetching = queryClient.isFetching();

  const isReadyToPrint = !!(!loadingData && !!project);
  const isPageLoaded = !!(
    !!project &&
    !!findings?.length &&
    !isFetching &&
    !isFetchingFindings
  );

  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  usePrint(isReadyToPrint, `Attestation-Letter-Report-${project?.name}`);

  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }
  const isMultiTenantReport =
    me?.customer.is_multi_tenant &&
    projects?.filter((p) => !p.is_self_managed).length === 0;

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <ReportsLoader
          setLoadingData={(state) => setLoadingData(state)}
          countdown={countdown}
        />
      )}
      <FirstPage
        projects={project ? [project] : []}
        reportType="Attack Surface management (ASM)"
      />
      {isMultiTenantReport ? <MultiTenantDisclaimerPage /> : <DisclaimerPage />}
      {!isMultiTenantReport && <AboutPage />}

      <TableOfContents
        findings={findings?.filter((f) => f.project === project?.id) || []}
        // Always hide "Appendix A", "Methodology", "Project Objectives" and "Team Member"
        isMultiTenantReport={true}
      />

      {!isMultiTenantReport && <AsmExecutiveSummary />}

      <SummaryOfResults findings={findings || []} />
      <ResultsAtGlance findings={findings || []} />
      <FindingsInDetails />
      {findings?.map((finding, index) => (
        <FindingPage
          finding={finding}
          key={`finding-page-${finding.id}`}
          index={index}
        />
      ))}
      <AppendixB />
    </PageLayout>
  );
};
