import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  Dropdown,
  Option,
} from "../../../components/elements/dropdowns/Dropdown";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import {
  BodyBold,
  BodyRegular,
} from "../../../components/elements/typography/Typography";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import {
  SCREEN_LAPTOP_WIDTH,
  environmentOptions,
} from "../../../shared/consts";
import {
  Asset,
  AssetEdit,
  assetStatusOptions,
  AssetStatus,
} from "../../../types/Asset";
import { priorityOptions } from "../AssetUtils";
import { Flex } from "../../../components/layouts/flex/Flex";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import { AssetStatusDropdown } from "../assetStatus/AssetStatusDropdown";

type Props = {
  selectedAssets: Asset[];
  totalAssets: number;
  isSelectAll: boolean;
  assetsBulkUpdateProps: AssetEdit;
  setAssetsBulkUpdateProps: React.Dispatch<React.SetStateAction<AssetEdit>>;
  setIsSelectAll: (state: boolean) => void;
  setSelectedAssets: (assets: Asset[]) => void;
};

const dropdownWidth = "210px";

export const AssetsBulkUpdate = (props: Props) => {
  const {
    selectedAssets,
    totalAssets,
    isSelectAll,
    assetsBulkUpdateProps,
    setAssetsBulkUpdateProps,
    setIsSelectAll,
    setSelectedAssets,
  } = props;
  const { data: products } = useApiProducts();
  const theme = useContext(ThemeContext);
  const screenWidth = useScreenWidth();
  const isSmallLaptop = screenWidth ? screenWidth < SCREEN_LAPTOP_WIDTH : false;

  const productsOptions: Option[] =
    products?.map((p) => ({
      value: p.id,
      label: p.name,
    })) || [];

  const asmOptions = [
    {
      value: "true",
      label: "ASM Enabled",
    },
    {
      value: "false",
      label: "ASM Disabled",
    },
  ];

  return (
    <Flex align="start" gap="24px">
      <SeparatorVertical style={{ height: "24px" }} />
      <Flex align="start">
        <Flex column={isSmallLaptop} align="center" justify="center">
          <Flex style={{ width: "270px" }}>
            <BodyRegular>Changes will be applied to</BodyRegular>
            <BodyBold className="ms-1">
              <div data-testid="assets-counter">
                {`${isSelectAll ? totalAssets : selectedAssets.length} Assets`}
              </div>
            </BodyBold>
          </Flex>

          <Flex style={{ width: "200px" }}>
            {isSelectAll ? (
              <TextButton
                label="Clear Selection"
                fontSize="14px"
                onClick={() => {
                  setIsSelectAll(false);
                  setSelectedAssets([]);
                }}
              />
            ) : (
              <TextButton
                fontSize="14px"
                onClick={() => setIsSelectAll(true)}
                label={`Select All ${totalAssets} Assets`}
              />
            )}
          </Flex>
        </Flex>

        <Flex align="start">
          <Flex align="start" gap="8px" flexWrap>
            <AssetStatusDropdown
              width={dropdownWidth}
              value={
                assetStatusOptions.find(
                  (p) => p.value === assetsBulkUpdateProps.status
                ) as Option | undefined
              }
              onChange={(option) =>
                setAssetsBulkUpdateProps((prev) => ({
                  ...prev,
                  status: (option?.value?.toString() || "live") as AssetStatus,
                }))
              }
            />
            <Dropdown
              size="medium"
              variant="border"
              dataTestId="bulk-product-dropdown"
              isMenuPositionFixed={true}
              width={dropdownWidth}
              options={productsOptions}
              placeholder="Multiple Products"
              value={productsOptions.find(
                (p) => p.value === assetsBulkUpdateProps.product
              )}
              onChange={(option) =>
                setAssetsBulkUpdateProps((prev) => ({
                  ...prev,
                  product: parseInt(option?.value.toString() || "0"),
                }))
              }
              backgroundColor={theme.bg2}
            />
            <Dropdown
              size="medium"
              variant="border"
              dataTestId="bulk-environment-dropdown"
              isMenuPositionFixed={true}
              width={dropdownWidth}
              options={environmentOptions}
              placeholder="Multiple Environments"
              value={environmentOptions.find(
                (p) => p.value === assetsBulkUpdateProps.environment
              )}
              onChange={(option) =>
                setAssetsBulkUpdateProps((prev) => ({
                  ...prev,
                  environment: option?.value.toString() || prev.environment,
                }))
              }
              backgroundColor={theme.bg2}
            />
            <Dropdown
              size="medium"
              variant="border"
              dataTestId="bulk-priority-dropdown"
              isMenuPositionFixed={true}
              width={dropdownWidth}
              options={priorityOptions}
              placeholder="Multiple Priorities"
              value={priorityOptions.find(
                (p) => p.value === assetsBulkUpdateProps.priority
              )}
              onChange={(option) =>
                setAssetsBulkUpdateProps((prev) => ({
                  ...prev,
                  priority: parseInt(option?.value.toString() || "1"),
                }))
              }
              backgroundColor={theme.bg2}
            />
            <Dropdown
              size="medium"
              variant="border"
              dataTestId="bulk-asm-enabled-dropdown"
              isMenuPositionFixed={true}
              width={dropdownWidth}
              options={asmOptions}
              placeholder="ASM Settings"
              value={
                assetsBulkUpdateProps?.is_asm_enabled === undefined
                  ? undefined
                  : asmOptions.find(
                      (p) =>
                        p.value ===
                        (assetsBulkUpdateProps.is_asm_enabled
                          ? "true"
                          : "false")
                    )
              }
              onChange={(option) =>
                setAssetsBulkUpdateProps((prev) => ({
                  ...prev,
                  is_asm_enabled: option?.value === "true",
                }))
              }
              backgroundColor={theme.bg2}
            />
            <SeparatorVertical className="ms-2" style={{ height: "24px" }} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
