import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { light } from "../../../shared/theme";

type Props = {};

export const FindingsInDetails = (props: Props) => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{ backgroundColor: "transparent", paddingTop: "48px" }}
    >
      <HeaderSecondary color={light.blue800}>
        Findings In Details
      </HeaderSecondary>
      <BodyRegular color={light.black800}>
        The following section of the report details the research findings, with
        respect to vulnerabilities that were identified and exploited, and
        vulnerabilities that could not be exploited, yet still pose a risk to
        the portal’s security posture.
      </BodyRegular>
    </Flex>
  );
};
