import { light } from "../../../shared/theme";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import CertificatesList from "../../../assets/images/reports/certs.png";

export const AboutPage = () => {
  return (
    <Flex
      column
      w100
      gap="8px"
      style={{ pageBreakAfter: "always", paddingTop: "48px" }}
    >
      <HeaderSecondary color={light.blue800}>About OP Innovate</HeaderSecondary>
      <BodyRegular color={light.black800}>
        OP Innovate was established in 2014 to defend global enterprises from
        the increasing challenges of organizational cybersecurity. Our
        experience in the field is extensive with unmatched expertise in cyber
        research, penetration testing, incident response, training and
        forensics. With headquarters in Israel, we rub shoulders with the
        best-of-breed in the field of cybersecurity, exposed to cutting-edge
        responses to today’s most critical cybersecurity concerns. This
        knowledge allows us and our customers to remain ahead of the curve.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        The OP Innovate team is composed of cybersecurity thought-leaders,
        skilled white hat hackers and analysts with decades of cumulative field
        experience with government and private sector organizations alike. We
        provide our customers with solutions based on the external and internal
        threat landscape, leveraging the most relevant and effective
        cybersecurity tools for the mission.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        Our highly-qualified personnel diverse backgrounds coalesce into our
        formidable offensive and defensive capabilities for a business-first
        approach. Each team member understands how to conduct technically
        challenging testing and analysis activities while ensuring that no
        damage befalls mission-critical systems.
      </BodyRegular>
      <BodyRegular color={light.black800}>
        The team members are all accredited professionals, certified on a
        variety of sought-after qualifications, bringing unrivaled cyber
        expertise to the table, combining a thorough depth of technological
        knowledge with a drive to deliver high quality Finding in all areas
        examined. Our team’s certifications include the following:
      </BodyRegular>
      <img className="mt-3" alt="certificates-list" src={CertificatesList} />
    </Flex>
  );
};
