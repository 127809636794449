import { Loading } from "../../../components/elements/loading/Loading";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { FindingDoughnutChart } from "../../findings/findingsCharts/FindingDoughnutChart";
import { Flex } from "../../../components/layouts/flex/Flex";

type Props = {
  relatedFindings: number[];
};

export const FindingChartTooltipContent = (props: Props) => {
  const { relatedFindings } = props;

  const { data: findingsCounts, isFetching } = useApiFindingsCounts({
    id: relatedFindings.slice(0, 100),
  });

  return !isFetching && findingsCounts ? (
    <Flex column gap="8px">
      {(findingsCounts?.open || 0) > 0 && (
        <FindingDoughnutChart findingsCounts={findingsCounts} includeInfo />
      )}
    </Flex>
  ) : (
    <Loading />
  );
};
