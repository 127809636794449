import { useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  BodyRegular,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import {
  JiraHtmlImageFixer,
  addClassToWaspHtmlImages,
} from "../../../shared/parser";
import { Finding } from "../../../types/Finding";
import { setRTETheme } from "../../../components/elements/richTextEditor/RichTextEditor";

type Props = {
  finding: Finding | undefined;
};

export const BottomBox = (props: Props) => {
  const { finding } = props;
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (!finding || !theme) return;
    setRTETheme(theme);
  }, [finding, theme]);

  return (
    <Box className="d-flex flex-column gap-24 box w-100">
      <div className="d-flex flex-column gap-16">
        <HeaderSubBold style={{ color: theme.black700 }}>
          Finding Description
        </HeaderSubBold>
        <BodyRegular
          className="text-wrap w-100"
          style={{
            overflowWrap: "anywhere",
          }}
          dangerouslySetInnerHTML={{
            __html: !!finding?.description_wasp
              ? addClassToWaspHtmlImages(finding?.description_wasp)
              : !!finding?.description_html
                ? JiraHtmlImageFixer(finding?.description_html, finding?.id)
                : finding?.description || "",
          }}
        />
      </div>
      <div className="d-flex flex-column gap-16">
        <HeaderSubBold style={{ color: theme.black700 }}>
          Attack Details
        </HeaderSubBold>
        <BodyRegular
          className="text-wrap w-100"
          style={{
            overflowWrap: "anywhere",
          }}
          dangerouslySetInnerHTML={{
            __html: !!finding?.attack_details_wasp
              ? addClassToWaspHtmlImages(finding?.attack_details_wasp)
              : !!finding?.attack_details_html
                ? JiraHtmlImageFixer(finding?.attack_details_html, finding?.id)
                : finding?.attack_details || "",
          }}
        />
      </div>
      <div className="d-flex flex-column gap-16">
        <HeaderSubBold style={{ color: theme.black700 }}>
          Mitigation
        </HeaderSubBold>
        <BodyRegular
          className="text-wrap w-100"
          style={{
            overflowWrap: "anywhere",
          }}
          dangerouslySetInnerHTML={{
            __html: !!finding?.mitigation_wasp
              ? addClassToWaspHtmlImages(finding?.mitigation_wasp)
              : !!finding?.mitigation_html
                ? JiraHtmlImageFixer(finding?.mitigation_html, finding?.id)
                : finding?.mitigation || "",
          }}
        />
      </div>
    </Box>
  );
};
