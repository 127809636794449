import { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Badge } from "../../../components/elements/badge/Badge";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { Box } from "../../../components/elements/box/Box";
import { Section } from "../../../components/elements/section/Section";
import { Tooltip } from "../../../components/elements/tooltip/Tooltip";
import {
  BodyBold,
  BodyRegular,
  LabelMedium,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { getDateTime } from "../../../shared/helper";
import { Asset, AssetDomainProperties } from "../../../types/Asset";
import { Flex } from "../../../components/layouts/flex/Flex";
import { BASE_API_URL } from "../../../hooks/queries/utils";

type Props = {
  asset: Asset | undefined;
};

export const WebDetailsBox = (props: Props) => {
  const { asset } = props;
  const theme = useContext(ThemeContext);
  const badgeStyle = {
    backgroundColor: theme.blue100,
    color: theme.blue700,
  };

  const [isImageError, setIsImageError] = useState<boolean>(false);

  const assetProperties = (asset?.properties as AssetDomainProperties) || {};

  if (asset?.type !== "domain" && asset?.type !== "ip") {
    return null;
  }

  return (
    <Flex column gap="16px">
      <Box>
        <Flex column gap="24px">
          <Flex align="center" justify="around" className="mt-2">
            <Section title="Webpage Title">
              <Flex style={{ maxHeight: "60px", overflowY: "auto" }}>
                <BodyRegular>
                  {assetProperties?.webpage_title || "N/A"}
                </BodyRegular>
              </Flex>
            </Section>

            <Section title="Webserver">
              <BodyRegular>{assetProperties?.webserver || "N/A"}</BodyRegular>
            </Section>
            <Section title="Status Code" noSeparator>
              <BodyRegular>{assetProperties?.status_code || "N/A"}</BodyRegular>
            </Section>
          </Flex>
          <Flex align="center" justify="around" className="mt-2">
            <Section title="Cname records">
              <Flex
                flexWrap
                gap="6px"
                align="center"
                style={{ maxHeight: "60px", overflowY: "auto" }}
              >
                {assetProperties?.cnames?.map((cn) => (
                  <Badge
                    className="d-flex"
                    key={`cn-${cn}`}
                    style={{
                      maxWidth: "250px",
                      color: theme.blue700,
                      backgroundColor: theme.blue100,
                    }}
                  >
                    <LabelMedium className="text-truncate" title={cn}>
                      {cn}
                    </LabelMedium>
                  </Badge>
                )) || <BodyRegular>N/A</BodyRegular>}
              </Flex>
            </Section>
            <Section title="Open Ports">
              <Flex
                flexWrap
                gap="6px"
                align="center"
                style={{ maxHeight: "60px", overflowY: "auto" }}
              >
                {asset?.ports_data?.map((portData) => (
                  <Tooltip
                    placement="right"
                    key={`portData-${portData.port_number}`}
                    content={
                      <Flex column gap="8px">
                        <Flex align="center" gap="4px">
                          <BodyRegular>Is Open:</BodyRegular>
                          <BodyBold>{portData.is_open ? "Yes" : "No"}</BodyBold>
                        </Flex>
                        {!portData.is_open && (
                          <Flex align="center" gap="4px">
                            <BodyRegular>Closed at:</BodyRegular>
                            <BodyBold>
                              {getDateTime(new Date(portData.closed_at || 0)) ||
                                "N/A"}
                            </BodyBold>
                          </Flex>
                        )}
                      </Flex>
                    }
                  >
                    <Badge style={badgeStyle}>{portData.port_number}</Badge>
                  </Tooltip>
                )) || <BodyRegular>N/A</BodyRegular>}
              </Flex>
            </Section>
            <Section
              title={asset.type === "domain" ? "Resolved IP's" : "Reverse DNS"}
              noSeparator
            >
              <Flex
                gap="6px"
                align="center"
                flexWrap
                style={{ maxHeight: "60px", overflowY: "auto" }}
              >
                {asset.type === "domain" ? (
                  <BadgesLine
                    badges={assetProperties?.ip || []}
                    variant="primary"
                    noWrap={false}
                    gap={4}
                  />
                ) : (
                  <BodyRegular>
                    {assetProperties?.reverse_dns || "N/A"}
                  </BodyRegular>
                )}
              </Flex>
            </Section>
          </Flex>

          <Flex align="center" justify="around" className="mt-2">
            <Section title="WAF">
              <Flex align="center" gap="8px">
                <BodyRegular>
                  {assetProperties?.waf?.length
                    ? assetProperties?.waf[0]
                    : "None"}
                </BodyRegular>
              </Flex>
            </Section>

            <Section title="Registration date">
              <BodyRegular>
                {asset?.registered_at
                  ? getDateTime(asset.registered_at)
                  : "N/A"}
              </BodyRegular>
            </Section>

            <Section title="Last seen" noSeparator>
              <BodyRegular>
                {asset?.last_seen ? getDateTime(asset.last_seen) : "N/A"}
              </BodyRegular>
            </Section>
          </Flex>

          {asset.type === "ip" && (
            <>
              <Flex align="center" justify="around" className="mt-2">
                <Section title="Region">
                  <BodyRegular>
                    {assetProperties?.region_name || "N/A"}
                  </BodyRegular>
                </Section>
                <Section title="Country">
                  <BodyRegular>{assetProperties?.country || "N/A"}</BodyRegular>
                </Section>
                <Section title="City" noSeparator>
                  <BodyRegular>{assetProperties?.city || "N/A"}</BodyRegular>
                </Section>
              </Flex>

              <Flex align="center" justify="around" className="mt-2">
                <Section title="Network Provider">
                  <BodyRegular>{assetProperties?.isp || "N/A"}</BodyRegular>
                </Section>
                <Section title="ASN Description">
                  <BodyRegular>
                    {assetProperties?.asn_description || "N/A"}
                  </BodyRegular>
                </Section>
                <Section title="ASN" noSeparator>
                  <BodyRegular>{assetProperties?.asn || "N/A"}</BodyRegular>
                </Section>
              </Flex>
            </>
          )}

          {!isImageError && (
            <Flex column gap="8px">
              <LabelRegular>Webpage Screenshot</LabelRegular>
              <img
                src={`${BASE_API_URL}/assets/${asset?.id}/screenshot`}
                alt="asset screenshot"
                width={"100%"}
                loading="lazy"
                onError={(e) => setIsImageError(true)}
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
