import { useState } from "react";
import { FirstPage } from "../generator/FirstPage";
import { AboutPage } from "../generator/AboutPage";
import { PageLayout } from "../generator/PageLayout";
import { WelcomeBanner } from "../../../components/composed/banner/WelcomeBanner";
import { useCountDown, usePrint, useWaitLoading } from "../generator/hooks";
import { AttestationExecutiveSummary } from "./AttestationExecutiveSummary";
import { useReportData } from "../useReportData";
import { ReportsLoader } from "../ReportsLoader";
import { ProjectFirstPage } from "../generator/ProjectFirstPage";
import "../reports.css";

const WAITING_TIME = 3; //seconds
export const AttestationLetterReport = () => {
  const {
    projects,
    isFetchingProject,
    findings,
    isMultiTenantReport,
    isFetchingFindings,
    isPageLoaded,
    isFetching,
    projectsNames,
  } = useReportData();

  const [loadingData, setLoadingData] = useState(true);
  const [countdown, setCountdown] = useState<number>(WAITING_TIME);

  const isReadyToPrint = !!(!loadingData && !!projects?.length);

  useWaitLoading(WAITING_TIME, () => setLoadingData(false), isPageLoaded);

  usePrint(isReadyToPrint, `Attestation-Letter-Report-${projectsNames}`);

  useCountDown(setCountdown);

  if (isFetchingProject || isFetchingFindings || isFetching) {
    return <WelcomeBanner />;
  }

  return (
    <PageLayout isMultiTenantReport={isMultiTenantReport}>
      {loadingData && (
        <ReportsLoader
          setLoadingData={(state) => setLoadingData(state)}
          countdown={countdown}
        />
      )}
      <FirstPage projects={projects} reportType="Attestation Letter" />
      {!isMultiTenantReport && <AboutPage />}
      {projects?.map((project, idx) => {
        const projectFindings = findings?.filter(
          (f) => f.project === project.id
        );
        return (
          <>
            {idx > 0 && (
              <div style={{ pageBreakBefore: "always", marginTop: "48px" }} />
            )}
            {projects.length > 1 && (
              <ProjectFirstPage
                project={project}
                reportType="Attestation Letter"
              />
            )}
            {!!project && (
              <AttestationExecutiveSummary
                project={project}
                findings={projectFindings || []}
              />
            )}
          </>
        );
      })}
    </PageLayout>
  );
};
