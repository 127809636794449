import { calculateProductScore } from "../../../shared/helper";
import { AssetsCounts } from "../../../types/Asset";
import { DefaultFindingsCounts, FindingsCounts } from "../../../types/Finding";
import { Product } from "../../../types/Product";

export const calculateProductsExposure = (
  product: Product,
  assetsCounts: AssetsCounts,
  customerRiskScore: number
): number[] => {
  const risk_score = calculateProductScore(
    product.findings_counts || DefaultFindingsCounts,
    customerRiskScore
  );

  const assets_without_waf =
    product.assets_counts?.assets_without_waf &&
    Math.round(
      (product.assets_counts?.assets_without_waf / assetsCounts.total) * 100
    );

  const totalFindings = product.findings_counts?.total || 0;

  const highs_and_criticals =
    product.findings_counts?.open_criticals &&
    product.findings_counts?.open_highs &&
    Math.round(
      ((product.findings_counts?.open_criticals +
        product.findings_counts?.open_highs) /
        totalFindings) *
        100
    );

  const breached_sla =
    product.findings_counts?.breached_sla &&
    Math.round((product.findings_counts.breached_sla / totalFindings) * 100);

  const untested =
    product.assets_counts?.untested_assets &&
    Math.round(
      (product.assets_counts.untested_assets / assetsCounts.total) * 100
    );

  return [
    risk_score,
    assets_without_waf || 0,
    highs_and_criticals || 0,
    breached_sla || 0,
    untested || 0,
  ];
};

export const calculateCustomerExposure = (
  customerFindingsCounts: FindingsCounts,
  products: Product[],
  assetsCounts: AssetsCounts
): number[] => {
  const assets_without_waf_count = products
    .map((p) => p.assets_counts?.assets_without_waf || 0)
    .reduce((acc, crnt) => acc + crnt);
  const assets_without_waf =
    assets_without_waf_count &&
    Math.round((assets_without_waf_count / assetsCounts.total) * 100);

  console.log(assets_without_waf_count, assetsCounts.total, assets_without_waf);

  const highs_and_criticals =
    customerFindingsCounts?.open_highs &&
    customerFindingsCounts?.open_criticals &&
    Math.round(
      ((customerFindingsCounts?.open_highs +
        customerFindingsCounts?.open_criticals) /
        customerFindingsCounts.total) *
        100
    );

  const breached_sla = Math.round(
    (customerFindingsCounts?.breached_sla / customerFindingsCounts.total) * 100
  );

  const untested_count = products
    .map((p) => p.assets_counts?.untested_assets)
    .reduce((acc, crnt) => acc && crnt && acc + crnt);
  const untested =
    untested_count && Math.round((untested_count / assetsCounts.total) * 100);

  return [
    assets_without_waf || 0,
    highs_and_criticals || 0,
    breached_sla || 0,
    untested || 0,
  ];
};
