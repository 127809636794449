import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { PrimaryBadge } from "../../../components/elements/badge/PrimaryBadge";
import { light } from "../../../shared/theme";

type Props = {
  findings: Finding[];
};

export const TeamMembers = (props: Props) => {
  const assignees = props.findings
    .map((finding) => finding.op_jira_assignee?.display_name)
    .filter((assignee) => assignee !== undefined) as string[];

  const uniqueAssignees = [...new Set(assignees)];

  return (
    <Flex
      column
      w100
      gap="8px"
      className="mt-3"
      style={{ pageBreakAfter: "always" }}
    >
      <HeaderSecondary color={light.blue800}>Team Members</HeaderSecondary>
      <BodyRegular color={light.black800}></BodyRegular>
      <Flex gap="8px">
        {uniqueAssignees.map((assignee) => (
          <PrimaryBadge
            backgroundColor={light.blue100}
            color={light.primary}
            content={assignee}
          />
        ))}
      </Flex>
    </Flex>
  );
};
