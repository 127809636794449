export const light = {
  name: "light",

  // white
  white100: "rgba(255, 255, 255, 1)",
  white90: "rgba(255, 255, 255, 0.9)",
  white80: "rgba(255, 255, 255, 0.8)", // not on palette
  white70: "rgba(255, 255, 255, 0.7)",
  white30: "rgba(255, 255, 255, 0.3)",
  white10: "rgba(255, 255, 255, 0.1)",
  white5: "rgba(255, 255, 255, 0.05)",

  // black
  black900: "#1A1A1A",
  black800: "#333333",
  black700: "#4D4D4D",
  black650: "#8A8A8A",
  black600: "#B3B3B3",
  black500: "#E6E6E6",
  black400: "#F2F2F2",
  black300: "#FAFAFA",

  // blue
  primary: "rgba(50, 133, 251, 1)", //"#3285FB"
  blue50: "#F7FAFE",
  blue100: "#EAF3FF",
  blue300: "#C1DAFE",
  blue500: "#98C2FD",
  blue600: "#3285FB",
  blue700: "rgba(36, 110, 211, 1)", // "#246ED3"
  blue800: "#00437A",

  lightBluePrimary: "rgba(118, 201, 237, 1)", // lightBlue600
  lightBluePrimaryOpacity20: "rgba(118, 201, 237, 0.2)", // lightBlue600 20%
  lightBlue50: "rgba(118, 201, 237, 0.1)",
  lightBlue600: "#76C9ED",
  lightBlue800: "#105D7E",

  grayBluePrimary: "rgba(117, 144, 181, 1)", // grayBlue600
  grayBlue800: "#32455D",
  grayBlue600: "#7590B5",
  grayBlue50: "#E3E9F0",

  // yellow
  yellowPrimary: "rgba(255, 191, 0, 1)", // yellow600
  yellowPrimaryOpacity20: "rgba(255, 191, 0, 0.2)", // yellow600 20%
  yellow50: "#FFF9E6",
  yellow300: "#FFDF80",
  yellow600: "#FFBF00",
  yellow800: "#8F6B00",

  // orange
  orangePrimary: "#FF8A00", // orange600
  orangePrimaryOpacity30: "rgba(255, 138, 0, 0.3)", //  orange600 30%
  orange50: "#FFF3E6",
  orange600: "#FF8A00",
  orange800: "#8F4C00",

  // red
  redPrimary: "rgba(239, 71, 111, 1)", // red600
  redPrimaryOpacity20: "rgba(239, 71, 111, 0.2)", // red600 20%
  redPrimaryOpacity50: "rgba(239, 71, 111, 0.5)", // red600 50%
  red50: "#FDEDF1",
  red600: "#EF476F",
  red800: "#830B27",

  // brown
  brownPrimary: "#830B27", // rgb(131, 11, 39)

  // green
  greenPrimary: "rgba(6, 214, 160, 1)", // green600
  green800: "#048B67",
  green600: "#06D6A0",
  green50: "#EBFEF9",

  // backgrounds
  navbar: "#3285FB",
  bg1: "#FAFAFA",
  bg2: "#FFFFFF",
  bg3: "#FFFFFF",
  backdrop: "rgba(88, 88, 88, 0.3)", // black 690 30%
  chartsTooltip: "rgba(255, 255, 255, 0.8)",

  // typography
  separation: "#F2F2F2",
  textWhite: "#FFFFFF",
  textSub: "#B3B3B3",
  textSecondary: "#4D4D4D",
  textBody: "#333333",
  textHeader: "#1A1A1A",

  // icons
  iconFull: "#1A1A1A",
  iconWhite: "#FFFFFF",
  iconSub: "#B3B3B3",
  iconSecondary: "#4D4D4D",
  iconPrimary: "#3285FB",

  // NON CONSISTENT COLORS
  topNavbarIconColor: "#fff", // white100
  toggleOn: "#3285FB", // blue 600
  toggleOff: "#98C2FD", // blue 500
  // not on palette
  secondary: "#00437A",
  dark: "#246ED3",
  blue: "#3285FB",
  blue10: "#EAF3FF",
  blue60: "#2E79E1",
  shadowBlue: "rgba(49, 133, 252, 0.1)", // bluePrimary 10%
  shadowBlueLight: "rgba(49, 133, 252, 0.05)", // lightBluePrimary 5%
  text: "#212529",
  textSubHeader: "rgba(0, 0, 0, 0.7)",
  background: "#F5F5F5",
  gray: "#F5F5F5",
  gray500: "#E6E6E6",
  gray600: "#B3B3B3",
  grayShadow: "rgba(123, 123, 123, 0.2)",
  lightCardShadow: "rgba(123, 123, 123, 0.1)",
  buttonDisabled: "#CACACA",
  button700: "#949494",
  buttonGray: "#949494",

  // graph risk colors
  graphRiskCritical: "#780000",
  graphRiskHigh: "#DC0000",
  graphRiskMedium: "#FD8C00",
  graphRiskLow: "#FDC500",
  graphRiskNoRisk: "#B3B3B3",

  // graph risk light
  graphRiskCriticalLight: "#cca5a5",
  graphRiskHighLight: "rgba(239, 71, 111, 0.1)",
  graphRiskMediumLight: "rgba(255, 169, 2, 0.15)",
  graphRiskLowLight: "#ffedb5",
  graphRiskNoRiskLight: "#e8e8e8",
};

export const dark = {
  name: "dark",

  // white
  white100: "rgba(255, 255, 255, 1)",
  white90: "rgba(255, 255, 255, 0.9)",
  white80: "rgba(255, 255, 255, 0.8)", // not on palette
  white70: "rgba(255, 255, 255, 0.7)",
  white30: "rgba(255, 255, 255, 0.3)",
  white10: "rgba(255, 255, 255, 0.1)",
  white5: "rgba(255, 255, 255, 0.05)",

  // black
  black900: "#E6E6E6",
  black800: "#D9D9D9",
  black700: "#B3B3B3",
  black650: "#8A8A8A",
  black600: "#808080",
  black500: "#343434",
  black450: "#262626",
  black400: "#1D1D1D",
  black300: "#111111",

  // blue
  primary: "rgba(30, 121, 250, 1)", // blue600",
  blue50: "#0F2340",
  blue100: "#0D2C57",
  blue300: "#0C356E",
  blue500: "#0A469D",
  blue550: "#094FB4",
  blue600: "#1E79FA",
  blue700: "#82B3FA",
  blue800: "#CDE1FD",

  lightBluePrimary: "rgba(57, 179, 207, 1)", // lightBlue600
  lightBluePrimaryOpacity20: "rgba(57, 179, 207, 0.2)", // lightBlue600 20%
  lightBlue50: "#0C2A31",
  lightBlue600: "#39B3CF",
  lightBlue800: "#B5E3ED",

  grayBluePrimary: "rgba(145, 166, 196, 1)", // grayBlue600
  grayBlue50: "#30415A",
  grayBlue600: "#91A6C4",
  grayBlue800: "#F5F7FA",

  // yellow
  yellowPrimary: "rgba(255, 197, 26, 1)", // yellow600
  yellowPrimaryOpacity20: "rgba(255, 197, 26, 0.2)", // yellow600 20%
  yellow50: "#4C3900",
  yellow300: "#805F00",
  yellow600: "#FFC51A",
  yellow800: "#FFECB2",

  // orange
  orangePrimary: "#FF8A00",
  orange50: "#4C2A00",
  orange600: "#FA8900",
  orange800: "#FFDCB2",

  // red
  redPrimary: "rgba(209, 51, 117, 1)", // red600
  redPrimaryOpacity20: "rgba(209, 51, 117, 0.2)", // red600 20%
  redPrimaryOpacity50: "rgba(209, 51, 117, 0.5)", // red600 50%
  red50: "#571430",
  red600: "#D13375",
  red800: "#F7D9E6",

  // brown
  brownPrimary: "rgba(141, 15, 39, 1)",

  // green
  greenPrimary: "rgba(73, 212, 157, 1)",
  green800: "#C5F1E0",
  green600: "#49D49D",
  green50: "#10422E",

  // backgrounds
  navbar: "#1D1D1D", // black700
  bg1: "#111111",
  bg2: "#1D1D1D",
  bg3: "#262626",
  backdrop: "rgba(66, 66, 66, 0.3)",
  chartsTooltip: "rgba(66, 66, 66, 0.3)",

  // typography
  separation: "#343434",
  textWhite: "#FFFFFF",
  textSub: "#808080",
  textSecondary: "#D9D9D9",
  textBody: "#E6E6E6",
  textHeader: "#FFFFFF",

  // icons
  iconFull: "#E6E6E6",
  iconWhite: "#FFFFFF",
  iconSub: "#FFFFFF", // 30%
  iconSecondary: "#FFFFFF", // 70%
  iconPrimary: "#1E79FA",

  // NON CONSISTENT COLORS
  topNavbarIconColor: "#FFC51A", // yellow600 (yellowPrimary)
  toggleOn: "rgba(255, 255, 255, 0.8)", // white 80
  toggleOff: "rgba(255, 255, 255, 0.3)", // white 30

  // not on palette
  secondary: "#00437A",
  dark: "#343434",
  blue: "#1A5BD9",
  blue10: "rgba(62, 127, 255, 0.1)",
  blue60: "#2E79E1",
  shadowBlue: "rgba(30, 121, 250, 0.2)", // blue600 (Primary) 20%
  shadowBlueLight: "rgb(57, 179, 207,0.05)", // lightBluePrimary 5%
  text: "#212529",
  textSubHeader: "rgba(255, 255, 255, 0.5)",
  background: "#111111",
  gray: "#F5F5F5",
  gray500: "#343434",
  gray600: "#242424",
  grayShadow: "rgba(123, 123, 123, 0.2)",
  lightCardShadow: "rgba(123, 123, 123, 0.1)",
  buttonDisabled: "#343434",
  button700: "#949494",
  buttonGray: "#949494",

  // graph risk colors
  graphRiskCritical: "#780000",
  graphRiskHigh: "#DC0000",
  graphRiskMedium: "#FD8C00",
  graphRiskLow: "#FDC500",
  graphRiskNoRisk: "#B3B3B3",

  // graph risk light
  graphRiskCriticalLight: "#cca5a5",
  graphRiskHighLight: "rgba(239, 71, 111, 0.1)",
  graphRiskMediumLight: "rgba(255, 169, 2, 0.15)",
  graphRiskLowLight: "#ffedb5",
  graphRiskNoRiskLight: "#e8e8e8",
};
