import React, { useState } from "react";
import {
  HeaderMain,
  LabelBold,
  LabelRegular,
} from "../../elements/typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { InputText } from "../../elements/input/textInput/InputText";
import { IconButton } from "../../elements/button/icon/IconButton";
import { Box } from "../../elements/box/Box";
import { CredDetail, Leak, useApiAnalyzeDomain } from "./context/leaksContext";
import { Column, Table } from "../../elements/table/Table";
import { downloadCSV } from "./utils";
import useToastContext from "../../../hooks/toastHook";
import { Illustration } from "../../elements/illustrations/Illustration";
import { TableSkeleton } from "../../elements/table/TableSkeleton";
import { Loading } from "../../elements/loading/Loading";

export const LeaksAnalysis = () => {
  const addToast = useToastContext();
  const [domain, setDomain] = useState<string>("");
  const [leaks, setLeaks] = useState<Leak[]>([]);
  const { mutate: analyzeDomain, isLoading } = useApiAnalyzeDomain();

  const getDetailedCredsFromLeaks = (leaks: Leak[]): CredDetail[] => {
    let creds: CredDetail[] = [];
    for (let i = 0; i < leaks.length; i++) {
      for (let j = 0; j < leaks[i].credentials.length; j++) {
        for (let k = 0; k < leaks[i].credentials[j].creds.length; k++) {
          creds.push(leaks[i].credentials[j].creds[k]);
        }
      }
    }
    return creds;
  };

  const columns: Column[] = [
    {
      title: "Username",
      key: "username",
      minWidth: "50%",
      maxWidth: "50%",
      cell: (row) => <LabelRegular>{row.username}</LabelRegular>,
    },
    {
      title: "Password",
      key: "password",
      centerCell: true,
      cell: (row) => <LabelRegular>{row.password}</LabelRegular>,
    },
  ];

  const rows: CredDetail[] = getDetailedCredsFromLeaks(leaks);

  return (
    <>
      <Flex column gap="16px" style={{ height: "100vh" }}>
        <Flex justify="between">
          <HeaderMain>Leaks Analysis</HeaderMain>
        </Flex>
        <Flex w100 justify="center" style={{ marginTop: "32px" }}>
          <Flex style={{ width: "70%" }} gap="16px">
            <IconButton
              iconName={"download"}
              label={"Download CSV"}
              onClick={() => downloadCSV(getDetailedCredsFromLeaks(leaks))}
            />
            <InputText
              placeholder="Enter domain to analyze..."
              width={"100%"}
              onChange={(e) => setDomain(e.target.value)}
            />
            <IconButton
              iconName={"search"}
              label="Scan for leaks"
              onClick={() =>
                analyzeDomain({
                  domain,
                  onSuccessCallback(answer) {
                    setLeaks(answer.data);
                    if (answer.data.length === 0) {
                      addToast({ message: "No leaks found.", type: "info" });
                    }
                  },
                  onErrorCallback(error) {
                    console.log(error);
                    addToast({ message: error.message, type: "error" });
                  },
                })
              }
            />
          </Flex>
        </Flex>
        {isLoading && leaks.length === 0 && <Loading />}
        <Flex w100 justify="center" style={{ marginTop: "32px" }}>
          <Flex w100 h100>
            {leaks.length === 0 ? (
              <Flex
                w100
                justify="center"
                style={{ height: "calc(100vh - 300px)" }}
              >
                <Flex
                  column
                  gap="16px"
                  style={{ width: "250px" }}
                  justify="center"
                >
                  <Illustration name="empty" />
                  <LabelBold>
                    {"One man's leak is another man's key."}
                  </LabelBold>
                </Flex>
              </Flex>
            ) : (
              <Box style={{ width: "100%" }}>
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table columns={columns} rows={rows} />
                )}
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
