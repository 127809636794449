import { Radar } from "react-chartjs-2";
import { Icon } from "../../../components/elements/icon/Icon";
import {
  BodyRegular,
  HeaderSecondary,
} from "../../../components/elements/typography/Typography";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { useApiFindingsCounts } from "../../../hooks/queries/findingContext";
import { Switch } from "../../../components/elements/switch/Switch";
import { Flex } from "../../../components/layouts/flex/Flex";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { NoDataAvailable } from "../NoDataAvailable";
import { LegendItem } from "../../../components/elements/legend/LegendItem";
import { legendContainerStyle } from "../Insights";
import {
  calculateCustomerExposure,
  calculateProductsExposure,
} from "./productsExposureCalc";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  LineControllerChartOptions,
} from "chart.js";
import { getTooltip } from "../../../components/composed/charts/externalTooltip";
import { useApiAssetsCount } from "../../../hooks/queries/assetsContext";
import { useApiRiskScoreHistory } from "../../../hooks/queries/riskScoreHistoryContext";
import { Mixpanel } from "../../../shared/mixpanel";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

type RadarOptions = _DeepPartialObject<
  CoreChartOptions<"radar"> &
    ElementChartOptions<"radar"> &
    PluginChartOptions<"radar"> &
    DatasetChartOptions<"radar"> &
    ScaleChartOptions<"radar"> &
    LineControllerChartOptions
>;

const chartsFont = {
  family: "poppins",
  size: 12,
  lineHeight: "21px",
  // weight: "700",
};
ChartJS.defaults.font = chartsFont;

export const ProductsExposure = () => {
  const radarChartRef = useRef(null);
  const theme = useContext(ThemeContext);

  const colors = [
    theme.blue700,
    theme.yellowPrimary,
    theme.orangePrimary,
    theme.redPrimary,
    theme.greenPrimary,
    theme.lightBluePrimary,
    theme.primary,
  ];

  const { data: customerFindingsCounts, isFetching: isFetchingCounts } =
    useApiFindingsCounts();

  const { data: riskScoreHistoryItems } = useApiRiskScoreHistory({
    page_size: 1,
  });

  const { data: assetsCounts, isFetching: isFetchingAssetsCounts } =
    useApiAssetsCount();

  const { data: products, isFetching: isFetchingProducts } = useApiProducts({
    with_assets_counts: true,
    with_findings_counts: true,
  });

  const [viewAllProducts, setViewAllProducts] = useState<boolean>(false);

  const [legend, setLegend] = useState<boolean[]>([]);
  useEffect(() => {
    products?.length && setLegend(products?.map(() => true));
  }, [products]);

  const legendClickHandler = (e: any, label: string, idx: number) => {
    const newLegend = [...legend];
    newLegend[idx] = !newLegend[idx];
    setLegend(newLegend);
    const chart = radarChartRef.current;
    //@ts-ignore
    chart.setDatasetVisibility(idx, !chart.isDatasetVisible(idx));
  };

  const radarOptions: RadarOptions = {
    responsive: false,
    layout: { padding: 0, autoPadding: false },
    plugins: {
      tooltip: {
        enabled: false,
        mode: "point",
        external: (context: any) =>
          customerFindingsCounts &&
          getTooltip(context, theme, "radar", customerFindingsCounts?.total),
      },
      legend: { display: false },
    },
    scales: {
      r: {
        pointLabels: { color: theme.textSecondary },
        ticks: { display: false },
        angleLines: { display: false },
        grid: { color: theme.separation },
      },
    },
    font: chartsFont,
  };

  const data = {
    labels: [
      "Risk Score",
      "Public Assets Without WAF",
      "High/Critical Vulnerabilities",
      "SLA Breached",
      "Untested Assets",
    ],
    datasets: viewAllProducts
      ? [
          {
            label: "All products",
            data:
              customerFindingsCounts &&
              !!products?.length &&
              assetsCounts &&
              calculateCustomerExposure(
                customerFindingsCounts,
                products,
                assetsCounts
              ),
            borderColor: theme.primary,
            backgroundColor: theme.primary.replace("1)", "0.1)"),
            borderWidth: 1,
          },
        ]
      : products?.map((product, idx) => ({
          label: product.name,
          data:
            customerFindingsCounts &&
            assetsCounts &&
            calculateProductsExposure(
              product,
              assetsCounts,
              riskScoreHistoryItems?.[0]?.risk_score || 0
            ),
          backgroundColor: colors.map((c) => c.replace("1)", "0.1)")),
          borderColor: colors[idx],
          borderWidth: 1,
        })) || [],
  };

  return (
    <Flex column gap="12px" h100 w100>
      <Flex align="center" gap="8px">
        <Icon name="products" size={24} color={theme.primary} />
        <HeaderSecondary>Products Exposure</HeaderSecondary>
        <Flex align="center" gap="8px" className="ms-auto">
          <BodyRegular>Organizational View</BodyRegular>
          <Switch
            checked={viewAllProducts}
            onChange={() => {
              Mixpanel.track("Organizational View clicked", {
                checked: viewAllProducts,
              });
              setViewAllProducts((prev) => !prev);
            }}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex
          column
          gap="8px"
          style={{
            position: "relative",
            ...legendContainerStyle,
          }}
        >
          {products?.map((p, idx) => (
            <LegendItem
              label={p.name}
              color={colors[idx]}
              isOn={legend[idx]}
              onClick={(e) => {
                Mixpanel.track("Products Exposure - selected product", {
                  selected: p.name,
                });
                legendClickHandler(e, p.name, idx);
              }}
              key={idx}
            />
          ))}
          {viewAllProducts && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                background: theme.bg2,
                opacity: "0.8",
              }}
            ></div>
          )}
        </Flex>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <Radar
            ref={radarChartRef}
            height={313}
            width={600}
            data={data}
            options={radarOptions}
          />
          {!isFetchingCounts &&
            !isFetchingProducts &&
            !isFetchingAssetsCounts &&
            (!Object.keys(customerFindingsCounts || {}).length ||
              !products?.length) && <NoDataAvailable />}
        </div>
      </Flex>
    </Flex>
  );
};
